const initState = {
    accommodations: [],
    currentProfileAccommodations: null,
    currentAccommodation: null,
    runningAccommodations: null,
    pendingAccommodations: null,
    alerts: 0
}


const accommodationsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'DELETE_EVENT':
            return {...state, accommodations: state.accommodations.filter( a => a.visit_id !== action.id)}
        case 'GET_ALL_ACCOMMODATIONS_ALERTS_NUMBER':
            return {...state, alerts: action.data}
        case 'DENY_ACCOMMODATION':
            return {...state, pendingAccommodations: state.pendingAccommodations.filter(e => e.id !== action.id)}
        case 'ACCEPT_ACCOMMODATION':
            const accepted = state.pendingAccommodations.find(e => e.id = action.id)
            return {
                ...state,
                pendingAccommodations: state.pendingAccommodations.filter(e => e.id !== action.id),
                runningAccommodations: [accepted, ...state.runningAccommodations]
            }
        case 'CLOSE_ACCOMMODATION':
            return {...state, runningAccommodations: state.runningAccommodations.filter(e => e.id !== action.id)}
        case 'GET_RUNNING_ACCOMMODATIONS':
            if (action.data) {
                return {...state, runningAccommodations: action.data}
            }
            return state
        case 'GET_PENDING_ACCOMMODATIONS':
            if (action.data) {
                return {...state, pendingAccommodations: action.data}
            }
            return state
        case 'CLEAN_CURRENT_ACCOMMODATION':
            return {...state, currentAccommodation: null}
        case 'GET_ACCOMMODATION_BY_VISIT_ID':
            if (action.data) {
                return {...state, currentAccommodation: action.data}
            }
            return state
        case 'GET_ALL_ACCOMMODATIONS':
            if(action.data){
                return { ...state, accommodations: action.data}
            }
            return state
        case 'GET_ACCOMMODATIONS_PER_DAY':
            if(action.data){
                return { ...state, accommodations: action.data}
            }
            return state
        case 'GET_ACCOMMODATIONS_PER_PROFILE':
            if(action.data){
                return { ...state, currentProfileAccommodations: action.data}
            }
            return state
        case 'SAVE_NEW_ACCOMMODATION':
            return state
        case 'UPDATE_ACCOMMODATION':
            return {...state, runningAccommodations: state.runningAccommodations.map(e => {
                    if(action.formData.exceeding_cause && action.formData.id === e.id) return {...e, exceeding_cause: action.formData.exceeding_cause }
                    else return e;
                })}
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export default accommodationsReducer
