import React from 'react'
import moment from "moment"
import styled from "styled-components"


const ResponsibleAware = styled.div`
    color: ${({aware}) => aware ? 'green' : 'red'};
`

const getFrenchDate = date => {
    const days = {
        Monday: 'Lundi',
        Tuesday: 'Mardi',
        Wednesday: 'Mercredi',
        Thursday: 'Jeudi',
        Friday: 'Vendredi',
        Saturday: 'Samedi',
        Sunday: 'Dimanche'
    }

    return `${days[moment(date).format('dddd')]} ${moment(date).format('DD[/]MM[/]YYYY')}`
}

const ProfilesAccomomodations = ({accommodations}) => {
    return (
        <>
            <div className="row">
                <div className="col s3">Date début</div>
                <div className="col s3">Référent</div>
                <div className='col s2'>Responsable prévenu</div>
                <div className="col s2">Numéro de nuit</div>
                <div className="col s1">Statut de l'hébergement</div>

            </div>
            {
                accommodations.map(
                    (accommodation, index) => {
                        return (
                            <div key={index} className="card-panel row">
                                <div className="col s3">{getFrenchDate(accommodation.date_time)}</div>
                                <div className="col s3">{accommodation.type}</div>
                                <ResponsibleAware  className='col s2' aware={accommodation.responsible_is_aware}>{accommodation.responsible_is_aware ? 'oui' : 'non'}</ResponsibleAware>
                                <div className="col s2">{moment().diff(moment(accommodation.date_time), 'days')}</div>
                                <div className="col s2">{
                                    accommodation.request_status === 3
                                        ? 'refusé'
                                        : accommodation.closed
                                        ? 'clôturé'
                                        : accommodation.request_status === 2
                                        ? 'en cours' : 'en attente'
                                }</div>
                            </div>
                        )
                    }
                )
            }
        </>
    )
}

export default ProfilesAccomomodations