import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import MultiStep from "../utilities/MultiStep"
import FirstStep from "./form_steps/first_step/FirstStep"
import SecondStep from "./form_steps/second_step/SecondStep"
import ThirdStep from "./form_steps/third_Step/ThirdStep"
import {
    getArrivalsLookup,
    getCountriesLookup,
    getIntermediatesLookup,
    getLegalResponsiblesLookup,
    getLivingPlacesLookup,
    getParentLivingStatusesLookup,
    getResidencesLookup,
    getSpecificStatusesLookup,
    getEducationTypesLookup,
    getLeavingSchoolReasonsLookup,
    getStudiesLevelLookup,
    getSchoolChoiceReasonsLookup,
} from "../../store/actions/extra_data.actions"
import { resetNewProfile, getProfileRawData } from "../../store/actions/profiles.actions";
import Loading from "../alerts/Loading"
import FourthStep from "./form_steps/fourth_step/FourthStep";
import FifthStep from "./form_steps/fifth_step/FifthStep";
import SixthStep from "./form_steps/sixth_step/SixthStep"
import SeventhStep from "./form_steps/seventh_step/SeventhStep"
import styled from 'styled-components'
import {useParams} from "react-router-dom";
import LoadingScreen from "../utilities/LoadingScreen";

const TitleAndSaveButton = styled.div`
    display: flex;
    justify-content: space-between;
`

const ButtonContainer = styled.div`
    width: 280px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 40px;
`

const EditProfile = (
    {
        auth,
        countries,
        getCountriesLookup,
        residences,
        getResidencesLookup,
        arrivals,
        getArrivalsLookup,
        intermediates,
        getIntermediatesLookup,
        livingPlaces,
        getLivingPlacesLookup,
        specificStatuses,
        getSpecificStatusesLookup,
        legalResponsibles,
        getLegalResponsiblesLookup,
        parentLivingStatuses,
        getParentLivingStatusesLookup,
        educationTypes,
        getEducationTypesLookup,
        schoolChoiceReasons,
        getSchoolChoiceReasonsLookup,
        leavingSchoolReasons,
        getLeavingSchoolReasonsLookup,
        studiesLevels,
        getStudiesLevelLookup,
        newProfile,
        resetNewProfile,
        getProfileRawData,
        ...props
    }
) => {
    const { id } = useParams()

    useEffect(() => {
        resetNewProfile()
        getProfileRawData(id)
        if(!auth.loggedIn) props.history.push('/')
        if(countries.length === 0) getCountriesLookup()
        if(residences.length === 0) getResidencesLookup()
        if(arrivals.length === 0) getArrivalsLookup()
        if(intermediates.length === 0) getIntermediatesLookup()
        if(livingPlaces.length === 0) getLivingPlacesLookup()
        if(specificStatuses.length === 0) getSpecificStatusesLookup()
        if(legalResponsibles.length === 0) getLegalResponsiblesLookup()
        if(parentLivingStatuses.length === 0) getParentLivingStatusesLookup()
        if(educationTypes.length === 0) getEducationTypesLookup()
        if(schoolChoiceReasons.length === 0) getSchoolChoiceReasonsLookup()
        if(leavingSchoolReasons.length === 0) getLeavingSchoolReasonsLookup()
        if(studiesLevels.length === 0) getStudiesLevelLookup()
    }, [])

    const save = () => props.history.push('/profiles/new/confirmation/update')

    const reset = () => {
        resetNewProfile()
    }

    const elements = [
        {
            element: <FirstStep />,
            name: 'Informations de base',
            icon: 'person'
        },
        {
            element: <SecondStep />,
            name: 'Nationalité',
            icon: 'flag'
        },
        {
            element: <ThirdStep />,
            name: 'Statut',
            icon: 'collections_bookmark'
        },
        {
            element: <FourthStep />,
            name: 'Schéma familial: 1/2',
            icon: 'people'
        },
        {
            element: <FifthStep />,
            name: 'Schéma familial: 2/2',
            icon: 'people'
        },
        {
            element: <SixthStep />,
            name: 'Scolarité',
            icon: 'school'
        },
        {
            element: <SeventhStep />,
            name: 'Services',
            icon: 'store'
        }
    ]
    return(
        <>
            {
                (   newProfile && newProfile.id &&
                    countries.length > 0 &&
                    residences.length > 0 &&
                    arrivals.length > 0 &&
                    intermediates.length > 0 &&
                    livingPlaces.length > 0 &&
                    specificStatuses.length > 0 &&
                    <div className="container">
                        <TitleAndSaveButton>
                            <h2>{newProfile.first_name} {newProfile.last_name}</h2>
                            <ButtonContainer>
                                <button onClick={save} className="btn green ">Enregistrer</button>
                            </ButtonContainer>
                        </TitleAndSaveButton>
                        <MultiStep elements={elements}/>
                    </div>
                )
                || <LoadingScreen/>
            }
        </>
    )
}



const mapStateToProps = state => {
    return{
        auth: state.auth,
        countries: state.extraData.countries,
        residences: state.extraData.residences,
        arrivals: state.extraData.arrivals,
        intermediates: state.extraData.intermediates,
        livingPlaces: state.extraData.livingPlaces,
        specificStatuses: state.extraData.specificStatuses,
        legalResponsibles: state.extraData.legalResponsibles,
        parentLivingStatuses: state.extraData.parentLivingStatuses,
        schoolChoiceReasons: state.extraData.schoolChoiceReasons,
        studiesLevels: state.extraData.studiesLevels,
        leavingSchoolReasons: state.extraData.leavingSchoolReasons,
        educationTypes: state.extraData.educationTypes,
        newProfile: state.profiles.newProfile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetNewProfile: () => dispatch( resetNewProfile() ),
        getCountriesLookup: () => dispatch( getCountriesLookup() ),
        getResidencesLookup: () => dispatch( getResidencesLookup() ),
        getArrivalsLookup: () => dispatch( getArrivalsLookup() ),
        getIntermediatesLookup: () => dispatch( getIntermediatesLookup() ),
        getLivingPlacesLookup: () => dispatch( getLivingPlacesLookup() ),
        getSpecificStatusesLookup: () => dispatch( getSpecificStatusesLookup() ),
        getLegalResponsiblesLookup: () => dispatch( getLegalResponsiblesLookup() ),
        getParentLivingStatusesLookup: () => dispatch( getParentLivingStatusesLookup() ),
        getEducationTypesLookup: () => dispatch( getEducationTypesLookup() ),
        getSchoolChoiceReasonsLookup: () => dispatch( getSchoolChoiceReasonsLookup() ),
        getLeavingSchoolReasonsLookup: () => dispatch( getLeavingSchoolReasonsLookup() ),
        getStudiesLevelLookup: () => dispatch( getStudiesLevelLookup() ),
        getProfileRawData: id => dispatch( getProfileRawData(id) )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)