import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateNewProfile } from "../../../../store/actions/profiles.actions"
import Nationality from "./Nationality"

const SecondStep = (
    {
        newProfile,
        updateNewProfile,
    }
) => {

    const [formData, setFormData] = useState({
        nationality: newProfile.nationality ? newProfile.nationality : '',
        is_belgian: newProfile.is_belgian ? newProfile.is_belgian : '',
        legal: newProfile.legal ? newProfile.legal : '',
        legal_status_obtaining:  newProfile.legal_status_obtaining ? newProfile.legal_status_obtaining : '',
        asylum_seeker: newProfile.asylum_seeker ? newProfile.asylum_seeker : '',
        arrival_date: newProfile.arrival_date ? newProfile.arrival_date : '',
        specific_status: newProfile.specific_status ? newProfile.specific_status : '0',
        mena_reported: newProfile.mena_reported ? newProfile.mena_reported : '',
        mena_has_tutor: newProfile.mena_has_tutor ? newProfile.mena_has_tutor : '',
        mena_has_lawyer: newProfile.mena_has_lawyer ? newProfile.mena_has_lawyer : '',
        mena_has_other_country_id: newProfile.mena_has_other_country_id ? newProfile.mena_has_other_country_id : '',
    })

    const [nationalityDisplayed, displayNationality] = useState(formData.is_belgian === '0')

    useEffect( () => {
        if(formData.nationality) displayNationality(formData.is_belgian === '0')
        return () => {
            updateNewProfile(formData)
        }
    }, [formData])


    const handleChange = (e, id = null) => {
        const property = id ? id : e.target.id
        if (property === 'spj_social' && e.target.value === '1') {
            setFormData({ ...formData, 'tribunal' : '1', 'spj_social': '1' })
        } else
            setFormData({ ...formData, [property]: e.target.value })
    }


    const handleIsBelgian = e => {
        displayNationality(e.target.value === '0')
        handleChange(e)
    }

    return (
        <>
            <h4>Nationalité</h4>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Le jeune est de nationalité belge ?</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="is_belgian" id="is_belgian" type="radio" onChange={handleIsBelgian} value='1' checked={ formData.is_belgian === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="is_belgian" id="is_belgian" type="radio" onChange={handleIsBelgian} value='0' checked={ formData.is_belgian === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                </div>
            </div>
            {
                nationalityDisplayed && <Nationality
                    formData={formData}
                    handleChange={handleChange}
                />
            }
        </>
    )
}

const mapStateToProps = state => {
    return{
        newProfile: state.profiles.newProfile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewProfile: credentials => dispatch( updateNewProfile(credentials) ),
    }
}



export default  connect(mapStateToProps, mapDispatchToProps)(SecondStep)