import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'


const Background = styled.div`
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    position: fixed;
    top: 64px;
    width: 100%;
    display: flex;
    justify-content: center;
`

const Container = styled.div`
    position: relative;
    width: 480px;
    height: ${({height}) => height ? '608px' : '100px'};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const ListContainer = styled.div`
    height: 540px;
    overflow: auto;
`

const ClosingIcon = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
`

const ProfileName = styled.div`
    display: flex;
    justify-content: space-between;
    background: #424242;
    cursor: pointer;
    color: white;
    &:hover{
        background: #ec407a;
    }
`

const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center; 
`

const CenterText = styled.p`
    font-size: 1.8rem;
    text-align: center;
`

const Info = styled.p`
    margin: 0;
    padding: 0;
`

const ProfileChooser = ({profiles, close}) => {
    return (
        <Background>
            <CenterContainer>
                <Container height={profiles.length} className="card-panel">
                    <ClosingIcon onClick={close}><i className="material-icons">close</i></ClosingIcon>
                    {
                        (profiles.length && profiles.map &&
                        <ListContainer>
                            {
                                ( profiles.map((profile, index) => {
                                    return (
                                        <Link key={index} onClick={close} to={`/profiles/details/${profile.id}`} >
                                            <ProfileName  className="card-panel" >
                                                <Info>{profile.first_name} {profile.last_name}</Info>
                                                <Info>Numéro de dossier: {profile.id}</Info>
                                            </ProfileName>
                                        </Link>
                                    )
                                }))
                            }
                        </ListContainer>)
                        ||
                        <CenterText>Aucun résultat</CenterText>
                    }
                </Container>
            </CenterContainer>
        </Background>
    )
}

export default ProfileChooser
