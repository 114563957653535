import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateNewProfile } from "../../../../store/actions/profiles.actions"
import styled from "styled-components"
import Lookup from "../../../forms_components/Lookup"

const StyledHr = styled.hr`
    margin: 20px 0;
`

const FifthStep = (
    {
        newProfile,
        updateNewProfile,
        parentLivingStatuses
    }
) => {
    const [formData, setFormData] = useState({
        deceased_mother: newProfile.deceased_mother ? newProfile.deceased_mother : '',
        mothers_income: newProfile.mothers_income ? newProfile.mothers_income : '',
        mother_living_status: newProfile.mother_living_status ? newProfile.mother_living_status : '',
        mother_living_abroad: newProfile.mother_living_abroad ? newProfile.mother_living_abroad : '',
        mother_contact:  newProfile.mother_contact ? newProfile.mother_contact : '',
        deceased_father: newProfile.deceased_father ? newProfile.deceased_father : '',
        fathers_income: newProfile.fathers_income ? newProfile.fathers_income : '',
        father_living_status: newProfile.father_living_status ? newProfile.father_living_status : '',
        father_living_abroad: newProfile.father_living_abroad ? newProfile.father_living_abroad : '',
        father_contact: newProfile.father_contact ? newProfile.father_contact : '',
        siblings_are_resources: newProfile.siblings_are_resources ? newProfile.siblings_are_resources : '',
        siblings_contact: newProfile.siblings_contact ? newProfile.siblings_contact : '',
        aunt_uncle_are_resources: newProfile.aunt_uncle_are_resources ? newProfile.aunt_uncle_are_resources : '',
        aunt_uncle_contact: newProfile.aunt_uncle_contact ? newProfile.aunt_uncle_contact : '',
        grand_parents_are_resources: newProfile.grand_parents_are_resources ? newProfile.grand_parents_are_resources : '',
        grand_parents_contact: newProfile.grand_parents_contact ? newProfile.grand_parents_contact : '',
        host_family_is_resource: newProfile.host_family_is_resource ? newProfile.host_family_is_resource : '',
        host_family_contact: newProfile.host_family_contact ? newProfile.host_family_contact : ''
    })

    const [lookupValue, setLookupValue] = useState({
        mother_living_status: formData.mother_living_status,
        father_living_status: formData.father_living_status,
    })

    const [parentsLivingTogether, setParentLivingTogether] = useState(false)

    const updateMotherLivingStatus = e => {
        setLookupValue( {...lookupValue, mother_living_status: e.target.value})
        handleChange(e, 'mother_living_status')
    }

    const updateFatherLivingStatus = e => {
        setLookupValue( {...lookupValue, father_living_status: e.target.value})
        handleChange(e, 'father_living_status')
    }

    useEffect( () => {
        setParentLivingTogether(newProfile.legal_responsible === 3)
        return () => {
            updateNewProfile(formData)
        }
    }, [formData])

    const handleChange = (e, id = null) => {
        const property = id ? id : e.target.id
        setFormData({ ...formData, [property]: e.target.value })
    }

    return (
        <>
            <h4>Schéma familial (partie 2/2)</h4>
            <div>
                <h5>Mère</h5>
                {
                    (
                        !parentsLivingTogether  &&
                        <div className="row card-panel">
                            <div className="col s7 input-field">
                                <label className='active grey-text text-darken-4'>Mère décédée ?</label>
                                <div className="col s3 input-field">
                                    <label>
                                        <input name="deceased_mother" id="deceased_mother" type="radio" onChange={handleChange} value='1' checked={ formData.deceased_mother === '1' }/>
                                        <span>Oui</span>
                                    </label>
                                </div>
                                <div className="col s3 input-field">
                                    <label>
                                        <input name="deceased_mother" id="deceased_mother" type="radio" onChange={handleChange} value='0' checked={ formData.deceased_mother === '0' }/>
                                        <span>Non</span>
                                    </label>
                                </div>
                                <div className="col s3 input-field">
                                    <label>
                                        <input name="deceased_mother" id="deceased_mother" type="radio" onChange={handleChange} value='' checked={ formData.deceased_mother === '' }/>
                                        <span>Non renseigné</span>
                                    </label>
                                </div>
                                <div className="col s3 input-field">
                                    <label>
                                        <input name="deceased_mother" id="deceased_mother" type="radio" onChange={handleChange} value='X' checked={ formData.deceased_mother === 'X' }/>
                                        <span>Mère inconnue</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    )
                    || <></>
                }

                <div className="row card-panel">
                    <div className="col s7 input-field">
                        <label className='active grey-text text-darken-4'>Revenu: </label>
                        <div className="col s4 input-field">
                            <label>
                                <input name="mothers_income" id="mothers_income" type="radio" onChange={handleChange} value='1' checked={ formData.mothers_income === '1' }/>
                                <span>Travail</span>
                            </label>
                        </div>
                        <div className="col s4 input-field">
                            <label>
                                <input name="mothers_income" id="mothers_income" type="radio" onChange={handleChange} value='0' checked={ formData.mothers_income === '0' }/>
                                <span>Aucun revenu du travail</span>
                            </label>
                        </div>
                        <div className="col s4 input-field">
                            <label>
                                <input name="mothers_income" id="mothers_income" type="radio" onChange={handleChange}value='' checked={ formData.mothers_income === '' }/>
                                <span>Non renseigné</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row card-panel">
                    {
                        (
                            !parentsLivingTogether &&
                            <div className="col s6 input-field">
                                <Lookup
                                    data={parentLivingStatuses}
                                    dataName="type"
                                    dataValue="id"
                                    label="Vit: "
                                    updateFunction={updateMotherLivingStatus}
                                    value={lookupValue.mother_living_status}
                                />
                            </div>
                        )
                        || <></>
                    }
                    <div className="col s6 input-field">
                        <label className={`${formData.mother_contact ? "active" : ""} grey-text text-darken-4`} htmlFor="mother_contact">Contact</label>
                        <input value={formData.mother_contact} type="text" id="mother_contact" onChange={handleChange} />
                    </div>
                </div>
            </div>
            <StyledHr/>
            <div>
                <h5>Père</h5>
                {
                    (
                        !parentsLivingTogether
                        &&
                        <div className="row card-panel">
                            <div className="col s7 input-field">
                                <label className='active grey-text text-darken-4'>Père décédée ?</label>
                                <div className="col s3 input-field">
                                    <label>
                                        <input name="deceased_father" id="deceased_father" type="radio" onChange={handleChange} value='1' checked={ formData.deceased_father === '1' }/>
                                        <span>Oui</span>
                                    </label>
                                </div>
                                <div className="col s3 input-field">
                                    <label>
                                        <input name="deceased_father" id="deceased_father" type="radio" onChange={handleChange} value='0' checked={ formData.deceased_father === '0' }/>
                                        <span>Non</span>
                                    </label>
                                </div>
                                <div className="col s3 input-field">
                                    <label>
                                        <input name="deceased_father" id="deceased_father" type="radio" onChange={handleChange} value='' checked={ formData.deceased_father === '' }/>
                                        <span>Non renseigné</span>
                                    </label>
                                </div>
                                <div className="col s3 input-field">
                                    <label>
                                        <input name="deceased_father" id="deceased_father" type="radio" onChange={handleChange} value='X' checked={ formData.deceased_father === 'X' }/>
                                        <span>Père inconnu</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    )
                    || <></>
                }
                <div className="row card-panel">
                    <div className="col s7 input-field">
                        <label className='active grey-text text-darken-4'>Revenu: </label>
                        <div className="col s4 input-field">
                            <label>
                                <input name="fathers_income" id="fathers_income" type="radio" onChange={handleChange} value='1' checked={ formData.fathers_income === '1' }/>
                                <span>Travail</span>
                            </label>
                        </div>
                        <div className="col s4 input-field">
                            <label>
                                <input name="fathers_income" id="fathers_income" type="radio" onChange={handleChange} value='0' checked={ formData.fathers_income === '0' }/>
                                <span>Aucun revenu du travail</span>
                            </label>
                        </div>
                        <div className="col s4 input-field">
                            <label>
                                <input name="fathers_income" id="fathers_income" type="radio" onChange={handleChange}value='' checked={ formData.fathers_income === '' }/>
                                <span>Non renseigné</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row card-panel">
                    {
                        (
                            !parentsLivingTogether &&
                            <div className="col s6 input-field">
                                <Lookup
                                    data={parentLivingStatuses}
                                    dataName="type"
                                    dataValue="id"
                                    label="Vit: "
                                    updateFunction={updateFatherLivingStatus}
                                    value={lookupValue.father_living_status}
                                />
                            </div>
                        )
                        || <></>
                    }

                    <div className="col s6 input-field">
                        <label className={`${formData.father_contact ? "active" : ""} grey-text text-darken-4`} htmlFor="father_contact">Contact</label>
                        <input value={formData.father_contact} type="text" id="father_contact" onChange={handleChange} />
                    </div>
                </div>
            </div>
            <StyledHr/>
            <div>
                <h5>Fraterie</h5>
                <div className="row card-panel">
                    <div className="col s6 input-field">
                        <label className='active grey-text text-darken-4'>La fratrie est-elle une ressource pour le jeune ?</label>
                        <div className="col s3 input-field">
                            <label>
                                <input name="siblings_are_resources" id="siblings_are_resources" type="radio" onChange={handleChange} value='1' checked={ formData.siblings_are_resources === '1' }/>
                                <span>Oui</span>
                            </label>
                        </div>
                        <div className="col s3 input-field">
                            <label>
                                <input name="siblings_are_resources" id="siblings_are_resources" type="radio" onChange={handleChange} value='0' checked={ formData.siblings_are_resources === '0' }/>
                                <span>Non</span>
                            </label>
                        </div>
                        <div className="col s3 input-field">
                            <label>
                                <input name="siblings_are_resources" id="siblings_are_resources" type="radio" onChange={handleChange} value='' checked={ formData.siblings_are_resources === '' }/>
                                <span>Non renseigné</span>
                            </label>
                        </div>
                    </div>
                    <div className="col s6 input-field">
                        <label className={`${formData.siblings_contact ? "active" : ""} grey-text text-darken-4`} htmlFor="siblings_contact">Contact</label>
                        <input value={formData.siblings_contact} type="text" id="siblings_contact" onChange={handleChange} />
                    </div>
                </div>
            </div>
            <StyledHr/>
            <div>
                <h5>Oncle(s) et tante(s)</h5>
                <div className="row card-panel">
                    <div className="col s6 input-field">
                        <label className='active grey-text text-darken-4'>Oncle et/ou tante est une ressource pour le jeune ?
                        </label>
                        <div className="col s3 input-field">
                            <label>
                                <input name="aunt_uncle_are_resources" id="aunt_uncle_are_resources" type="radio" onChange={handleChange} value='1' checked={ formData.aunt_uncle_are_resources === '1' }/>
                                <span>Oui</span>
                            </label>
                        </div>
                        <div className="col s3 input-field">
                            <label>
                                <input name="aunt_uncle_are_resources" id="aunt_uncle_are_resources" type="radio" onChange={handleChange} value='0' checked={ formData.aunt_uncle_are_resources === '0' }/>
                                <span>Non</span>
                            </label>
                        </div>
                        <div className="col s3 input-field">
                            <label>
                                <input name="aunt_uncle_are_resources" id="aunt_uncle_are_resources" type="radio" onChange={handleChange} value='' checked={ formData.aunt_uncle_are_resources === '' }/>
                                <span>Non renseigné</span>
                            </label>
                        </div>
                    </div>
                    <div className="col s6 input-field">
                        <label className={`${formData.aunt_uncle_contact ? "active" : ""} grey-text text-darken-4`} htmlFor="aunt_uncle_contact">Contact</label>
                        <input value={formData.aunt_uncle_contact} type="text" id="aunt_uncle_contact" onChange={handleChange} />
                    </div>
                </div>
            </div>
            <StyledHr/>
            <div>
                <h5>Grands parents</h5>
                <div className="row card-panel">
                    <div className="col s6 input-field">
                        <label className='active grey-text text-darken-4'>Les grands parents sont une ressource pour le jeune ?
                        </label>
                        <div className="col s3 input-field">
                            <label>
                                <input name="grand_parents_are_resources" id="grand_parents_are_resources" type="radio" onChange={handleChange} value='1' checked={ formData.grand_parents_are_resources === '1' }/>
                                <span>Oui</span>
                            </label>
                        </div>
                        <div className="col s3 input-field">
                            <label>
                                <input name="grand_parents_are_resources" id="grand_parents_are_resources" type="radio" onChange={handleChange} value='0' checked={ formData.grand_parents_are_resources === '0' }/>
                                <span>Non</span>
                            </label>
                        </div>
                        <div className="col s3 input-field">
                            <label>
                                <input name="grand_parents_are_resources" id="grand_parents_are_resources" type="radio" onChange={handleChange} value='' checked={ formData.grand_parents_are_resources === '' }/>
                                <span>Non renseigné</span>
                            </label>
                        </div>
                    </div>
                    <div className="col s6 input-field">
                        <label className={`${formData.grand_parents_contact ? "active" : ""} grey-text text-darken-4`} htmlFor="grand_parents_contact">Contact</label>
                        <input value={formData.grand_parents_contact} type="text" id="grand_parents_contact" onChange={handleChange} />
                    </div>
                </div>
            </div>
            <StyledHr/>
            <div>
                <h5>Famille d'accueil</h5>
                <div className="row card-panel">
                    <div className="col s6 input-field">
                        <label className='active grey-text text-darken-4'>La famille d'accueil est une ressource pour le jeune ?
                        </label>
                        <div className="col s3 input-field">
                            <label>
                                <input name="host_family_is_resource" id="host_family_is_resource" type="radio" onChange={handleChange} value='1' checked={ formData.host_family_is_resource === '1' }/>
                                <span>Oui</span>
                            </label>
                        </div>
                        <div className="col s3 input-field">
                            <label>
                                <input name="host_family_is_resource" id="host_family_is_resource" type="radio" onChange={handleChange} value='0' checked={ formData.host_family_is_resource === '0' }/>
                                <span>Non</span>
                            </label>
                        </div>
                        <div className="col s3 input-field">
                            <label>
                                <input name="host_family_is_resource" id="host_family_is_resource" type="radio" onChange={handleChange} value='' checked={ formData.host_family_is_resource === '' }/>
                                <span>Non renseigné</span>
                            </label>
                        </div>
                    </div>
                    <div className="col s6 input-field">
                        <label className={`${formData.host_family_contact ? "active" : ""} grey-text text-darken-4`} htmlFor="host_family_contact">Contact</label>
                        <input value={formData.host_family_contact} type="text" id="host_family_contact" onChange={handleChange} />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return{
        newProfile: state.profiles.newProfile,
        parentLivingStatuses: state.extraData.parentLivingStatuses,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewProfile: credentials => dispatch( updateNewProfile(credentials) ),
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(FifthStep)
