import moment from "moment"

const initState = {
    profiles: null,
    currentProfile: null,
    newProfile: {created_at: moment().format('YYYY[-]MM[-]DD')},
    profilesByFilter: [],
    searchedProfiles: [],
    newProfileModified: false,
    newProfileId: null,
    dynamicSheet: null
}

const profilesReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SAVE_NEW_PROFILE':
            return {
                ...state, newProfileId: action.data
            }
        case 'ADD_EVENT':
            return  {...state, profilesByFilter: []}
        case 'GET_ALL_PROFILES':
            if(action.data){
                return { ...state, profiles: action.data}
            }
            return state
        case 'GET_ALL_PROFILES_ERROR':
            console.log('Getting profiles has failed', action.data)
            return state
        case 'GET_PROFILE_RAW_DATA':
            return {...state, newProfile: action.data}
        case 'GET_PROFILE':
            return {...state, currentProfile: action.data[0]}
        case 'GET_OLD_PROFILES':
            return {...state, profiles: action.data}
        case 'ARCHIVE_PROFILE':
            return {...state, profiles: state.profiles.filter(p => p.id !== action.id)}
        case 'GET_PROFILE_ERROR':
            return state
        case 'ADD_REFERENT':
            return {
                ...state,
                currentProfile: {
                    ...state.currentProfile,
                    [`referent_${action.formData.slot}`]: action.formData.referent_id,
                    [`referent${action.formData.slot}`]: action.formData.pseudo
                }
            }
        case 'REMOVE_REFERENT':
            return {
                ...state,
                currentProfile: {
                    ...state.currentProfile,
                    [`referent_${action.formData.slot}`]: null,
                    [`referent${action.formData.slot}`]: null
                }
            }
        case 'LOGOUT': return {
            profiles: null,
            currentProfile: null,
            newProfile: {},
            profilesByFilter: [],
            searchedProfiles: [],
            newProfileModified: false,
            newProfileId: null,
            dynamicSheet: null
        }
        case 'CURRENT_PROFILE_CLEANING': return {...state, currentProfile: null}
        case 'UPDATE_NEW_PROFILE':
            return {
            ...state,
            newProfile: Object.assign(state.newProfile, action.formData)
        }
        case 'GET_PROFILES_BY_FILTER':
            if(action.data){
                return { ...state, profilesByFilter: action.data}
            }
            return state
        case 'ADVANCED_SEARCH':
            if(action.data){
                return { ...state, searchedProfiles: action.data}
            }
            return state
        case 'GET_DYNAMIC_SHEET':
            if(action.data) return { ...state, dynamicSheet: action.data }
            return state
        case 'RESET_SEARCHED_PROFILES':
            return {...state, searchedProfiles: []}
        case 'GET_PROFILES_BY_FILTER_ERROR':
            console.log('Getting profiles by filter has failed', action.data)
            return {...state, profilesByFilter: []}
        case 'RESET_NEW_PROFILE':
            return {...state, newProfile: {}}
        default:
            return state
    }
}

export default profilesReducer
