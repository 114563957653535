import React from 'react'
import styled from 'styled-components'
import Loading from "../alerts/Loading"


const Background = styled.div`
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-item: center;
`
const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center; 
`

const LoadingScreen = () => {
    return (
        <Background>
            <LoadingContainer>
                <Loading/>
            </LoadingContainer>
        </Background>
    )
}

export default LoadingScreen