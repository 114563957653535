export const LIVING_DETAIL_ALONE_OR_FLAT_SHARE = [
    {
        id: '0',
        type: 'Non renseigné'
    },
    {
        id: '1',
        type: 'Avec suivi de service'
    },
    {
        id: '2',
        type: 'Sans suivi'
    }
]

export const LIVING_DETAIL_NETWORK = [
    {
        id: '0',
        type: 'Non renseigné'
    },
    {
        id: '1',
        type: 'Adultes'
    },
    {
        id: '2',
        type: 'Mineurs ou pairs'
    }
]

export const INTERMEDIATE_DETAIL_FAMILY = [
    {
        id: '0',
        type: 'Non renseigné'
    },
    {
        id: '1',
        type: 'Parents'
    },
    {
        id: '2',
        type: 'Famille élargie'
    }
]

export const INTERMEDIATE_DETAIL_NETWORK = [
    {
        id: '0',
        type: 'Non renseigné'
    },
    {
        id: '1',
        type: 'Adultes'
    },
    {
        id: '2',
        type: 'Mineurs ou pairs'
    }
]


export const ACCOMMODATION_DENIED_REASONS = [
    {
        id: '0',
        type: 'Problème comportement/consigne'
    },
    {
        id: '1',
        type: 'Manque de place'
    },
    {
        id: '2',
        type: 'Autre solution trouvée'
    },
    {
        id: '3',
        type: 'Refus de l’autorité'
    },
    {
        id: '4',
        type: 'Ne voulait pas/jamais venu'
    },
    {
        id: '5',
        type: 'Dépannage/pas de travail'
    },
    {
        id: '6',
        type: 'Situation hors cadre'
    },
    {
        id: '7',
        type: 'Barrage de la langue'
    },
    {
        id: '8',
        type: 'autre'
    }
]

export const STOP_SCHOOL_SINCE = [
    {
        id: '0',
        type: '< 1 mois'
    },
    {
        id: '1',
        type: '< 6 mois'
    },
    {
        id: '2',
        type: '< 2 ans'
    },
    {
        id: '3',
        type: '>= 2 ans'
    },
    {
        id: '4',
        type: 'Jamais  scolarisé'
    },
    {
        id: '5',
        type: 'PDR',
    }
]
