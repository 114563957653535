import { uniq, uniqWith } from 'ramda'
import moment from "moment"


const initState = {
    newVisitsId: 0,
    events: [],
    pastEvents: [],
    currentProfileEvents: []
}

const eventReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_EVENT':
            const dt = new Date();
            const date_time =  `${
            dt.getFullYear().toString().padStart(4, '0')}-${
            (dt.getMonth()+1).toString().padStart(2, '0')}-${
            dt.getDate().toString().padStart(2, '0')} ${
            dt.getHours().toString().padStart(2, '0')}:${
            dt.getMinutes().toString().padStart(2, '0')}:${
            dt.getSeconds().toString().padStart(2, '0')}`

            return { ...state, events: [...state.events, {
                id: (dt.getTime()).toString(16),
                young_id: action.profile.id,
                first_name: action.profile.first_name,
                last_name: action.profile.last_name,
                date_time: date_time,
                vtm: '',
                accommodation_request: false,
                visit_reason: 0,
                consigne: action.profile.consigne,
                access_forbidden: action.profile.access_forbidden
            }]}

        case 'GET_EVENTS_PER_DAY' :
            if(new Date().toISOString().slice(0,10) === action.data.day) {
                state = {...state, events: uniqWith(
                        (a, b) =>
                            a.first_name === b.first_name
                        && a.last_name === b.last_name
                        && a.date_time === b.date_time
                    )
                    (state.events.concat(action.data.events).reverse()).reverse() }
            } else {
                console.log('here')
                state = {...state, pastEvents: action.data.events}
            }
            return state
        case 'GET_EVENTS_PER_PROFILE' :
            return {...state, currentProfileEvents: action.data}
        case 'SAVE_NEW_EVENT':
            state =  {
                ...state,
                newVisitsId: action.returnData.newId,
                events: state.events.map(e => e.id === action.returnData.id  ? {...action.returnData, id: action.returnData.newId ,saved: true} : e)
            }
            return state
        case 'UPDATE_EVENT':
            const isToday = action.returnData.date_time.split(' ')[0] === moment().format('YYYY[-]MM[-]DD')
            console.log(isToday)
            if(isToday)
                return {
                    ...state,
                    events: state.events.map(e => e.id === action.returnData.id  ? {...action.returnData, id: action.returnData.newId ,saved: true} : e)
                }
            else
                return {
                    ...state,
                    pastEvents: state.pastEvents.map(e => e.id === action.returnData.id  ? {...action.returnData, id: action.returnData.newId ,saved: true} : e)
                }
        case 'DELETE_EVENT':
            return {...state, events: state.events.filter( e => e.id !== action.id)}
        case 'LOGOUT': return initState
        case 'SHOW_EVENTS':
            console.log(state)
            return state;
        default:
            return state
    }
}

export default eventReducer
