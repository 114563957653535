import React, {useState, useEffect} from "react"
import { Collapsible, CollapsibleItem, Icon } from 'react-materialize'
import { connect } from 'react-redux'
import {
    getDynamicSheet,
    updateDynamicSheet
} from '../../store/actions/profiles.actions'
import Loading from "../alerts/Loading";


const DynamicSheet = (
    {
        profile,
        dynamicSheet,
        getDynamicSheet,
        updateDynamicSheet
    }
) => {

    const [updatedFields, setUpdatedFields] = useState({})
    const [loading, setLoading] = useState(false)

    const save = async () => {
        console.log("called")
        setLoading(true)
        updatedFields.id = dynamicSheet.id
        await updateDynamicSheet(updatedFields)
        await getDynamicSheet(profile.id)
        setLoading(false)
    }


    useEffect(() => {
        getDynamicSheet(profile.id)
    }, [])



    const handleChange = e => {
        setUpdatedFields({...updatedFields, [e.target.id]: e.target.checked})
    }

    return (
        <>
            {
                (
                    !loading &&
                    dynamicSheet &&
                    dynamicSheet.data.map((section, i) => {
                        return (
                            <div key={i}>
                                <h5>{section.sectionName}</h5>
                                <Collapsible accordion={false}>
                                {
                                    section.sectionData.map((subSection, j) => {
                                        return (
                                            <CollapsibleItem
                                                className="white"
                                                key={j}
                                                expanded={false}
                                                header={subSection.subSectionName}
                                                node="div"
                                            >
                                                <div className="row">
                                                    {
                                                        subSection.subSectionData.map((item, k) => {
                                                            return(
                                                                <div key={k} className="col s6">
                                                                    <label className='black-text'>
                                                                        <input type="checkbox"  id={item.itemField} onChange={handleChange} defaultChecked={item.itemData === 1} />
                                                                        <span>{item.itemName}</span>
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </CollapsibleItem>
                                        )
                                    })
                                }
                                </Collapsible>
                                <hr />
                            </div>
                        )
                    })
                )
                || <Loading/>
            }
            {dynamicSheet && <button className='btn' onClick={save}>Sauvegarder</button>}
        </>
    )
}

const mapStateToProps = state => {
    return {
        dynamicSheet: state.profiles.dynamicSheet
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDynamicSheet: id => dispatch( getDynamicSheet(id) ),
        updateDynamicSheet: formData => dispatch( updateDynamicSheet(formData) )
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(DynamicSheet)