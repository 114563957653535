import React from 'react'
import { NavLink } from 'react-router-dom'


const SignedOutLinks = () => {
    return (
        <ul className="right">
            {<li><NavLink to='/login'>Connexion</NavLink></li>}
        </ul>
    )
}

export default SignedOutLinks