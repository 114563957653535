import axios from "axios"
import {
    GET_EVENTS_PER_DAY,
    SAVE_NEW_EVENT,
    UPDATE_EVENT,
    GET_EVENTS_PER_PROFILE,
    DELETE_VISIT
} from "../../constants/urls.constants"

export const addEvent = profile => {
    return async dispatch => {
        dispatch( { type: 'ADD_EVENT',  profile} )
    }
}

export const deleteEvent = id => {
    return async dispatch => {
        dispatch( { type: 'DELETE_EVENT',  id} )
    }
}

export const deleteVisit = id => {
    return async dispatch => {
        const response = await axios.get(
            `${DELETE_VISIT}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}}
            )
            .catch(error => console.log(error))
        if(response.data.success)
            dispatch({type: 'DELETE_EVENT', id})
    }
}

export const saveVisitFromAccommodation = formData => {
    return async dispatch => {
        const returnData = {...formData}
        delete formData.id
        delete formData.consigne
        delete formData.access_forbidden
        const response = await axios.post(
            `${SAVE_NEW_EVENT}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'SAVE_NEW_EVENT_ERROR', error }))

        if(response.data) {

            returnData.newId = response.data.id
            dispatch({ type: 'SAVE_NEW_EVENT',  returnData} )
        }
    }
}

export const saveNewVisit = formData => {
    return async dispatch => {
        const returnData = {...formData}
        delete formData.id
        delete formData.consigne
        delete formData.access_forbidden
        const response = await axios.post(
            `${SAVE_NEW_EVENT}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'SAVE_NEW_EVENT_ERROR', error }))

        if(response.data) {
            returnData.newId = response.data.id
            dispatch({ type: 'SAVE_NEW_EVENT',  returnData} )
        }
    }
}

export const updateVisit = formData => {
    return async dispatch => {
        const returnData = {...formData}
        delete formData.saved
        delete formData.newId
        delete formData.consigne
        delete formData.access_forbidden
        const response = await axios.put(
            `${UPDATE_EVENT}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({type: 'UPDATE_EVENT_ERROR', error}))

        if (response.data) dispatch({type: 'UPDATE_EVENT', returnData})
    }
}

export const getEventsPerDay = (day) => {
    return async function mapDispatchToProps(dispatch) {
        const response = await axios.get(`${GET_EVENTS_PER_DAY}/${day}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_EVENTS_PER_DAY_ERROR', error }))

        const events = response.data.map(event => {
            event.saved = true
            return event
        })
        const data = {day, events: events}
        dispatch({type: 'GET_EVENTS_PER_DAY', data})
    }
}

export const getEventsPerProfile = (id) => {
    return async function mapDispatchToProps(dispatch) {
        const response = await axios.get(`${GET_EVENTS_PER_PROFILE}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_EVENTS_PER_PROFILE_ERROR', error }))

        const data = response.data
        dispatch({type: 'GET_EVENTS_PER_PROFILE', data})
    }
}