import React, { useState } from 'react'
import Lookup from "../forms_components/Lookup"
import styled from 'styled-components'
import {deleteEvent, saveNewVisit, updateVisit, deleteVisit} from "../../store/actions/events.actions";
import { connect } from 'react-redux'
import { NavLink } from "react-router-dom";
import ribbon from '../../assets/saved_ribbon.png'
import{ TimePicker } from 'react-materialize'
import Loading from "../alerts/Loading";
import moment from "moment";

const Center = styled.div`
    display: flex;
    flex-direction: column;
`

const EntryContainer = styled.div`
    min-height: 100px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
`

const LookupContainer = styled.div`
    border: ${({needed}) => needed  ? 'solid crimson 1px' : 'none'};

`
const ButtonContainer = styled.div `
    display: flex;
    justify-content: flex-end;

`

const Icon = styled.i`
    margin: 0 4px;
    cursor: pointer;
    &:hover {
        color: #ec407a;
    }
`

const VTMContainer = styled.div`
    width: 100px;

`

const Name = styled.p`
    width: 100px;
    position: relative;
    left: 40px;
    font-size: 1.5rem;
`

const DH = styled.div`

`

const SavedRibbon = styled.img`
    width: 100px;
    position: absolute;
    top: 0;
    left: 0;
`

const P = styled.p`
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
`

const TimePickerContainer = styled.div`
    width: 60px;
    overflow: hidden;
`

const MyTimePicker = styled(TimePicker)`
    border: none !important;
    font-size: 1.2rem !important;
    position: relative;
    top: 4px;
`

const HourLabel = styled.label`
    position: relative;
    top: 40px;
`

const Span = styled.div`
    width: 36px;
`

const Warning = styled.div`
    display: flex;
    color: ${({color}) => color}
    position: absolute;
    bottom: 4px;
    right: 4px;
`

const Entry = ({baseEntry, deleteEvent, saveVisit, updateVisit, newAccommodation, deleteVisit, visitObjects, ...props}) => {
    const eventIsToday = baseEntry.date_time.split(' ')[0] === moment().format('YYYY[-]MM[-]DD')
    const [entry, setEntry] = useState(baseEntry)
    const [needed, setNeeded] = useState(false)
    const [time, setTime] = useState(baseEntry.date_time.split(' ')[1].substr(0, 5))
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [visitTime, setVisitTime] = useState('')


    const handleChange = (e, id = null) => {
        const property = id ? id : e.target.id.split('-')[0].split('__')[0]

        setEntry({
            ...entry,
            [property]: e.target.value
        })
    }

    const handleTimeChange = (timePicker) => {
        setTime(timePicker.time)
    }

    const updateVisitReason = e => {
        const value = e.target.value
        if(e.target.value !== 0) setNeeded(false)
        setEntry({
            ...entry,
            visit_reason: value
        })
        if(e.target.value === 2) newAccommodation(entry.young_id, entry);
    }

    const saveEntry = async () => {
        entry.date_time = `${entry.date_time.split(' ')[0]} ${time}:00`
        if(!entry.visit_reason) {
            alert('Choissisez un motif pour la visite')
            setNeeded(true)
        } else {
            setLoading(true)
            await saveVisit(entry)
            setLoading(false)
        }
    }

    const updateEntry = async () => {
        entry.date_time = `${entry.date_time.split(' ')[0]} ${time}:00`
        if(!entry.visit_reason) {
            alert('Choissisez un motif pour la visite')
            setNeeded(true)
        } else {
            setLoading(true)
            await updateVisit(entry)
            setEditMode(false)
            setLoading(false)
        }
    }

    const deleteEntry = async () => {
        setLoading(true)
        await deleteVisit(baseEntry.id)
        setLoading(false)
    }

    return (
        (loading && <Loading/>)
        ||
        <EntryContainer className='card-panel'>
            {
                (
                    entry.access_forbidden &&
                    <Warning color='red'>Entrée intedite &nbsp;<i className='material-icons'>remove_circle</i></Warning>
                )
                ||
                (
                    entry.consigne &&
                    <Warning color='orange'>Consigne &nbsp;<i className='material-icons'>warning</i></Warning>
                )
            }
            {
                entry.saved &&
                <SavedRibbon src={ribbon} alt='saved ribbon' />
            }
            <Name>{entry.first_name} {entry.last_name}</Name>
            {
                (baseEntry.young_id && <P>n°:{entry.young_id}</P>) || <></>
            }

            {
                (
                    (!entry.saved || editMode) &&
                    <LookupContainer needed={needed} className="col s3">
                        <Lookup
                            data={baseEntry.young_id ? visitObjects : visitObjects.filter(o => o.id !== '2')}
                            dataName="type"
                            dataValue="id"
                            label="Motif"
                            updateFunction={updateVisitReason}
                            value={entry.visit_reason}
                        />
                    </LookupContainer>
                )
                ||
                <Center>
                    <label>Raison de la visite</label>
                    <P>{ entry.visit_reason && visitObjects.find(e => e.id === entry.visit_reason )?.type }</P>
                </Center>

            }

            {
                (
                    (!entry.saved || editMode) &&
                    <TimePickerContainer>
                        <HourLabel>Heure</HourLabel>
                        <MyTimePicker
                            id={`time_${entry.id}`}
                            value={time}
                            onChange={() => {}}
                            options={{
                                autoClose: false,
                                container: null,
                                defaultTime : 'now',
                                duration: 350,
                                fromNow: 0,
                                i18n: {
                                    cancel: 'Cancel',
                                    clear: 'Clear',
                                    done: 'Ok'
                                },
                                onCloseEnd: function() { handleTimeChange(this) },
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                onSelect: null,
                                showClearBtn: false,
                                twelveHour: false,
                                vibrate: true
                            }}
                        />
                    </TimePickerContainer>
                )
                ||
                <Center>
                    <label>Heure</label>
                    <P>{entry.date_time.split(' ')[1].substr(0, 5)}</P>
                </Center>
            }

            <DH>
                {
                    (
                        entry.young_id && editMode &&
                        <Icon className="green-text small material-icons" onClick={() => newAccommodation(entry.young_id, entry)}>home</Icon>
                    )
                    ||
                    <><Span></Span></>
                }
            </DH>
            {
                (
                    (!entry.saved || editMode) &&
                    <VTMContainer>
                        <div>

                                <input name={`vtm__${entry.id}`} id={`vtm__${entry.id}`} type="radio" onChange={handleChange} value='V' checked={entry.vtm === 'V'}/>
                                <span className="vtm-label">V</span>

                        </div>
                        <div>

                                <input name={`vtm__${entry.id}`} id={`vtm__${entry.id}`} type="radio" onChange={handleChange} value='T' checked={entry.vtm === 'T'}/>
                                <span><span  className="vtm-label">T</span></span>

                        </div>
                        <div>

                                <input name={`vtm__${entry.id}`}  id={`vtm__${entry.id}`} type="radio" onChange={handleChange} value='M' checked={entry.vtm === 'M'}/>
                                <span  className="vtm-label">M</span>

                        </div>
                    </VTMContainer>
                )
                ||
                (
                    entry.vtm &&
                    <Center>
                        <label>VTM</label>
                        <P>{entry.vtm}</P>
                    </Center>
                )
            }
            <ButtonContainer>
                {
                    (
                        baseEntry.young_id &&
                        <NavLink  to={`/profiles/details/${entry.young_id}`}>
                            <Icon className="small material-icons">account_box</Icon>
                        </NavLink>
                    )
                    || <></>
                }

                {
                    (
                        entry.saved && !editMode && eventIsToday &&
                        <Icon className="green-text small material-icons" onClick={() => setEditMode(true)}>lock</Icon>
                    )
                    ||
                    (
                        editMode && eventIsToday &&
                        <>
                            <Icon className="orange-text small material-icons" onClick={updateEntry}>lock_open</Icon>
                            {
                                (
                                    eventIsToday &&
                                    <Icon className="red-text small material-icons" onClick={deleteEntry}>close</Icon>
                                )
                                || <></>
                            }

                        </>
                    )
                    ||
                    (
                        eventIsToday &&
                        <>
                            <Icon className="green-text small material-icons" onClick={saveEntry}>save</Icon>
                            <Icon className="red-text small material-icons" onClick={() => deleteEvent(entry.id)}>close</Icon>
                        </>
                    )
                    ||<></>

                }

            </ButtonContainer>

        </EntryContainer>
    )
}

const mapStateToProps = state => {
    return {
        visitObjects: state.extraData.visitObjects
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteEvent: eventId => dispatch( deleteEvent(eventId) ),
        deleteVisit: eventId => dispatch( deleteVisit(eventId) ),
        saveVisit: formData => dispatch( saveNewVisit(formData) ),
        updateVisit: formData => dispatch( updateVisit(formData) )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Entry)
