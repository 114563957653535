import React, {useState, useEffect}  from 'react'
import { connect } from 'react-redux'
import {updatePassword, logout, updateUser} from "../../store/actions/auth.actions";
import {cleanNewUserError} from "../../store/actions/errors.actions";
import Loading from "../alerts/Loading";
import Modal from "../utilities/Modal";

const UserPage = (
    {
        user,
        loggedIn,
        updatePassword,
        error,
        cleanNewUserError,
        passwordUpdated,
        logout,
        updateUser,
        ...props
    }
) => {
    useEffect(() => {
        if(!loggedIn) props.history.push('/')
    }, [])

    const [pwdLoading, setPwdLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [credentials, setCredentials] = useState({
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
        id: user.id
    })
    const [formData, setFormData] = useState({
        pseudo: user.pseudo,
        email: user.email,
        id: user.id
    })

    const handleChangeCredentials = e => {
        setCredentials({
            ...credentials,
            [e.target.id]: e.target.value
        })
    }

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const updateInfo = async() => {
        setLoading(true)
        await updateUser(formData)
        setLoading(false)
    }

    const updateNewPassword = async () => {
        cleanNewUserError()
        setPwdLoading(true)
        await updatePassword(credentials)
        setPwdLoading(false)
    }


    const passwordUpdatedMessage = {
        title: 'Mot de passe modifié avec succés',
        text: "Vous allez être déconnecté, reconnectez vous avec votre nouveau mot de passe."
    }

    const passwordUpdatedChoices = [
        {
            text: 'Ok',
            f: () => {
                logout()
                props.history.push('/')
            }
        }
    ]

    return (
        <>
            {
                (passwordUpdated && <Modal message={passwordUpdatedMessage} choices={passwordUpdatedChoices} />)
                ||
                <div className="container">
                    <h2>Mon compte</h2>
                    <hr/>
                    <div className="card-panel">
                        <h5>Changer mon mot de passe:</h5>
                        {
                            (pwdLoading && <Loading/>)
                            ||
                            <>
                                <div className="input-field">
                                    <label htmlFor="oldPassword">Mot de passe actuel</label>
                                    <input value={credentials.oldPassword} type="password" id="oldPassword" onChange={handleChangeCredentials} />
                                </div>

                                <div className="input-field">
                                    <label htmlFor="password">Nouveau mot de passe</label>
                                    <input value={credentials.password} type="password" id="password" onChange={handleChangeCredentials} />
                                </div>

                                <div className="input-field">
                                    <label htmlFor="passwordConfirmation">Confirmer le nouveau mot de passe</label>
                                    <input value={credentials.passwordConfirmation} type="password" id="passwordConfirmation" onChange={handleChangeCredentials} />
                                    {
                                        credentials.password.length > 4
                                        && credentials.passwordConfirmation.length > 4
                                        && credentials.password !== credentials.passwordConfirmation
                                        &&
                                        <span className='red-text'>Les champs mot de passe et confirmation ne correspondent pas</span>
                                    }
                                </div>

                                {
                                    error && <div className="card-panel orange darken-2 white-text">{error}</div>
                                }
                                <button
                                    onClick={updateNewPassword}
                                    className="green btn"
                                    disabled={
                                        credentials.oldPassword.length < 4
                                        || credentials.password.length < 4
                                        || credentials.passwordConfirmation.length < 4
                                        || credentials.password !== credentials.passwordConfirmation
                                    }
                                >
                                    Changer mon mot de passe
                                </button>
                            </>
                        }
                    </div>
                    <div className="card-panel">
                        <h5>Changer mes informations</h5>
                        {
                            (loading && <Loading/>)
                            ||
                            <>
                                <div className="input-field">
                                    <label className='active' htmlFor="pseudo">Pseudo</label>
                                    <input value={formData.pseudo} type="text" id="pseudo" onChange={handleChange} />
                                </div>

                                <div className="input-field">
                                    <label className='active' htmlFor="email">Adresse email</label>
                                    <input value={formData.email} type="email"  className="validate" id="email" onChange={handleChange} />
                                    <span className="helper-text" data-error="L'email n'est pas au bon format"></span>
                                </div>
                                <button
                                    onClick={updateInfo}
                                    className="green btn"
                                    disabled={
                                        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
                                        || formData.pseudo.length < 3
                                    }
                                >
                                    Changer mes informations
                                </button>
                            </>
                        }
                    </div>
                </div>
            }

        </>
    )
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        loggedIn: state.auth.loggedIn,
        error: state.errors.newUserError,
        passwordUpdated: state.alerts.passwordUpdated
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch( logout() ),
        updatePassword: credentials => dispatch(updatePassword(credentials) ),
        cleanNewUserError: () => dispatch(cleanNewUserError() ),
        updateUser: formData => dispatch(updateUser(formData) ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
