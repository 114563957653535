const initState = {
    loginError: null,
    loginErrorMessage: '',
    signUpError: null,
    newUserError: null
}

const loginErrorMessages = {
    500: 'Erreur serveur',
    401: 'Mauvais login et/ou mot de passe',
    403: 'Utilisateur désactivé',
    999: 'Backend server is down'
}

const errorReducer= (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_ERROR':
            const message = loginErrorMessages[action.errorStatus]
            return {...state, loginError: true, loginErrorMessage: message || action.errorStatus}
        case 'LOGIN_ERROR_CLEANING':
            return { ...state, loginError: false, loginErrorMessage: ''}
        case 'ADD_NEW_USER_ERROR':
            return {...state, newUserError: action.error}
        case 'NEW_USER_ERROR_CLEANING':
            return {...state, newUserError: false}
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export default errorReducer
