import React, {useEffect, useState} from 'react'
import Lookup from "../../../forms_components/Lookup"
import {
    LIVING_DETAIL_ALONE_OR_FLAT_SHARE,
    LIVING_DETAIL_NETWORK,
    INTERMEDIATE_DETAIL_FAMILY,
    INTERMEDIATE_DETAIL_NETWORK
} from "../../../../constants/lookups.constants"
import { connect } from 'react-redux'
import {updateNewProfile} from "../../../../store/actions/profiles.actions";

const ThirdStep = ({updateNewProfile, newProfile, residences, livingPlaces, intermediates}) => {

    const [formData, setFormData] = useState({
        saj_cbj: newProfile.saj_cbj ? newProfile.saj_cbj : '0',
        tribunal: newProfile.tribunal ? newProfile.tribunal : '0',
        judge_name: newProfile.judge_name ? newProfile.judge_name : '',
        saj_delegate_name: newProfile.saj_delegate_name ? newProfile.saj_delegate_name : '',
        apj_delegate_name: newProfile.apj_delegate_name ? newProfile.apj_delegate_name : '',
        cpas_delegate_name: newProfile.cpas_delegate_name ? newProfile.cpas_delegate_name : '',
        spj_social: newProfile.spj_social ? newProfile.spj_social : '0',
        cpas: newProfile.cpas ? newProfile.cpas : '0',
        living_in: newProfile.living_in ? newProfile.living_in : '0',
        living_in_detail: newProfile.living_in_detail ? newProfile.living_in_detail : 0,
        intermediate: newProfile.intermediate ? newProfile.intermediate : '0',
        intermediate_detail: newProfile.intermediate_detail ? newProfile.intermediate_detail : 0,
        residence: newProfile.residence ? newProfile.residence : '0',
        intermediate_contact: newProfile.intermediate_contact ? newProfile.intermediate_contact : '0',
        with_accommodation_request: newProfile.with_accommodation_request ? newProfile.with_accommodation_request : '0',

    })

    useEffect( () => {
        return () => {
            updateNewProfile(formData)
        }
    }, [formData])


    const handleChange = (e, id = null) => {
        const property = id ? id : e.target.id
        if(property === 'tribunal') displayJusdgeName(e.target.value === '1')
        if (property === 'cpas')  displayCapsContact(e.target.value === '1')
        if(property === 'spj_social') displaySpjContact(e.target.value === '1')
        if (property === 'saj_cbj') displaySajContact(e.target.value === '1')

        setFormData({ ...formData, [property]: e.target.value })

    }



    const [lookupValue, setLookupValue] = useState({
        residence: formData.residence,
        intermediate: formData.intermediate,
        intermediate_detail: formData.intermediate_detail,
        living_in: formData.living_in,
        living_in_detail: formData.living_in_detail,
    })

    const intermediateLookupsValues = value => {
        return value === 2
            ? INTERMEDIATE_DETAIL_NETWORK
            : value === 3
            ? INTERMEDIATE_DETAIL_FAMILY
            : []
    }

    const livingInLookupsValues = value => {
        return value === 4
            ? LIVING_DETAIL_NETWORK
            : value === 6 || value === 7
            ? LIVING_DETAIL_ALONE_OR_FLAT_SHARE
            : []
    }

    const [
        livingPlaceDetailIsDisplayed,
        displayLivingPlaceDetail
    ] = useState(formData.living_in_detail !== 0)
    const [
        livingPlaceLookupValues,
        setLivingPlaceLookupValues
    ] = useState(livingInLookupsValues(formData.living_in))

    const [
        intermediateDetailIsDisplayed,
        displayIntermediateDetail
    ] = useState(formData.intermediate_detail !== 0)
    const [
        intermediateDetailLookupValues,
        setIntermediateDetailLookupValues
    ] = useState(intermediateLookupsValues(formData.intermediate))

    const[judgeNameIsDisplay, displayJusdgeName] = useState(formData.judge_name === '1')
    const[sajContact, displaySajContact] = useState(formData.judge_name === '1')
    const[spjContact, displaySpjContact] = useState(formData.judge_name === '1')
    const[capsContact, displayCapsContact] = useState(formData.judge_name === '1')

    const updateResidence = e => {
        setLookupValue( {...lookupValue, residence: e.target.value})
        handleChange(e, 'residence')
    }

    const updateIntermediate = e => {
        const value = e.target.value
        setLookupValue( {...lookupValue, intermediate: value})
        handleChange(e, 'intermediate')
        displayIntermediateDetail([2, 3].indexOf(value) > -1)
        setIntermediateDetailLookupValues(intermediateLookupsValues(value))
    }

    const updateLivingPlace = e => {
        const value = e.target.value
        setLookupValue( {...lookupValue, living_in: value})
        handleChange(e, 'living_in')
        displayLivingPlaceDetail([4, 6, 7].indexOf(value) > -1)
        setLivingPlaceLookupValues(livingInLookupsValues(value))
    }

    const updateLivingPlaceDetail = e => {
        setLookupValue( {...lookupValue, living_in_detail: e.target.value})
        handleChange(e, 'living_in_detail')
    }


    const updateIntermediateDetail = e => {
        setLookupValue( {...lookupValue, intermediate_detail: e.target.value})
        handleChange(e, 'intermediate_detail')
    }

    return(
        <>
            <h4>Statut</h4>
            <div className="card-panel">
                <Lookup
                    data={residences}
                    dataName="type"
                    dataValue="id"
                    label="Domicile"
                    updateFunction={updateResidence}
                    value={lookupValue.residence}
                />
            </div>
            <div className="card-panel row">
                <div className="col s4">
                    <Lookup
                        data={livingPlaces}
                        dataName="type"
                        dataValue="id"
                        label="Lieu d'habitation"
                        updateFunction={updateLivingPlace}
                        value={lookupValue.living_in}
                    />
                </div>
                {
                    livingPlaceDetailIsDisplayed &&
                    <div className="col s4">
                        <Lookup
                            data={livingPlaceLookupValues}
                            dataName="type"
                            dataValue="id"
                            label="Suivi"
                            updateFunction={updateLivingPlaceDetail}
                            value={lookupValue.living_in_detail}
                        />
                    </div>
                }
            </div>
            <div className="card-panel row">
                <div className="col s4">
                    <Lookup
                        data={intermediates}
                        dataName="type"
                        dataValue="id"
                        label="Intermédiaire"
                        updateFunction={updateIntermediate}
                        value={lookupValue.intermediate}
                    />
                </div>
                {
                    intermediateDetailIsDisplayed &&
                    <div className="col s4">
                        <Lookup
                            data={intermediateDetailLookupValues}
                            dataName="type"
                            dataValue="id"
                            label="Détail intermédiaire"
                            updateFunction={updateIntermediateDetail}
                            value={lookupValue.intermediate_detail}
                        />
                    </div>
                }
                <div className="col s4 input-field">
                    <label className='active grey-text text-darken-4'>Contact préalable avec l'intermédiare</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="intermediate_contact" id="intermediate_contact" type="radio" onChange={handleChange} value='1' checked={ formData.intermediate_contact === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="intermediate_contact" id="intermediate_contact" type="radio" onChange={handleChange} value='0' checked={ formData.intermediate_contact === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                </div>
            </div>

            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Dossier ouvert au tribunal</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="tribunal" id="tribunal" type="radio" onChange={handleChange} value='1' checked={ formData.tribunal === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="tribunal" id="tribunal" type="radio" onChange={handleChange} value='0' checked={ formData.tribunal === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                </div>
                {
                    judgeNameIsDisplay &&
                    <div className="col s6 input-field">
                        <label className={`${formData.judge_name ? "active" : ""} grey-text text-darken-4`} htmlFor="judge_name">Nom du juge</label>
                        <input value={formData.judge_name} type="text" id="judge_name"  onChange={handleChange} />
                    </div>
                }
            </div>

            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Dossier ouvert au SAJ-CBJ</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="saj_cbj" id="saj_cbj" type="radio" onChange={handleChange} value='1' checked={ formData.saj_cbj === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="saj_cbj" id="saj_cbj" type="radio" onChange={handleChange} value='0' checked={ formData.saj_cbj === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                </div>
                {
                    sajContact &&
                    <div className="col s6 input-field">
                        <label className={`${formData.saj_delegate_name ? "active" : ""} grey-text text-darken-4`} htmlFor="judge_name">Nom/coordonnée du délégué</label>
                        <input value={formData.saj_delegate_name} type="text" id="saj_delegate_name"  onChange={handleChange} />
                    </div>
                }
            </div>



            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Dossier ouvert au SPJ Social</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="spj_social" id="spj_social" type="radio" onChange={handleChange} value='1' checked={ formData.spj_social === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="spj_social" id="spj_social" type="radio" onChange={handleChange} value='0' checked={ formData.spj_social === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                </div>
                {
                    spjContact &&
                    <div className="col s6 input-field">
                        <label className={`${formData.spj_delegate_name ? "active" : ""} grey-text text-darken-4`} htmlFor="judge_name">Nom/coordonnée du délégué</label>
                        <input value={formData.spj_delegate_name} type="text" id="spj_delegate_name"  onChange={handleChange} />
                    </div>
                }



            </div>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Dossier ouvert au cpas</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="cpas" id="cpas" type="radio" onChange={handleChange} value='1' checked={ formData.cpas === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="cpas" id="cpas" type="radio" onChange={handleChange} value='0' checked={ formData.cpas === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                </div>
                {
                    capsContact &&
                    <div className="col s6 input-field">
                        <label className={`${formData.cpas_delegate_name ? "active" : ""} grey-text text-darken-4`} htmlFor="judge_name">Nom/coordonnée du délégué</label>
                        <input value={formData.cpas_delegate_name} type="text" id="cpas_delegate_name"  onChange={handleChange} />
                    </div>
                }
            </div>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>La demande est-elle accompagnée d’une demande d’hébergement?</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="with_accommodation_request" id="with_accommodation_request" type="radio" onChange={handleChange} value='1' checked={ formData.with_accommodation_request === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="with_accommodation_request" id="with_accommodation_request" type="radio" onChange={handleChange} value='0' checked={ formData.with_accommodation_request === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return{
        newProfile: state.profiles.newProfile,
        legalResponsibles: state.extraData.legalResponsibles,
        residences: state.extraData.residences,
        livingPlaces: state.extraData.livingPlaces,
        intermediates: state.extraData.intermediates
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewProfile: credentials => dispatch( updateNewProfile(credentials) ),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep)