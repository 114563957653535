import React, {useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import { logout } from "../../store/actions/auth.actions"
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dropdown, Icon } from 'react-materialize'
import styled from 'styled-components'
import {getOldProfiles} from "../../store/actions/profiles.actions";
import {getAccommodationsAlertsNumber} from "../../store/actions/accommodations.actions";
import UserLinks from "./links/UserLinks";
import {SettingsLinks} from "./links/SettingsLinks";

const StyledNavLink = styled(NavLink) `
    border-right: 1px solid #999;
`

const SearchContainer = styled.div `
    height: 100%;
    border-right: 1px solid #999;
    width: 52px;
    text-align: center;
    cursor: pointer;
`

const StyledLink = styled.a `
    border-right: 1px solid #999;
`
const SignedInLinks = (
    {
        initial,
        logout,
        toggleSearch,
        admin,
        profiles,
        alerts,
        getOldProfiles,
        getAccommodationsAlertsNumber,
        ...props
    }
) => {
    const signOut = () => {
        logout()
        props.history.push('/')
    }

    useEffect(() => {
        getOldProfiles()
        getAccommodationsAlertsNumber()
    }, [])

    return (
        <ul>
            <li className='large-width'><StyledNavLink to='/longuet'><Icon left>format_align_justify</Icon>Longuet</StyledNavLink></li>
            <li className='large-width'>
                <Dropdown
                    options={{
                        alignment: 'left',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: false,
                        container: null,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250
                    }}
                    trigger={<StyledLink href="#!"><Icon left>people</Icon>Profils<Icon right>arrow_drop_down</Icon>
                        {
                            profiles && profiles.length && <span className="new badge" data-badge-caption="">{profiles.length}</span>
                        }
                    </StyledLink>}
                >
                    <NavLink className="pink-text text-lighten-1" to='/profiles/search'><i className="material-icons">search</i>Recherche avancée</NavLink>
                    <NavLink className="pink-text text-lighten-1" to='/profiles/new'><i className="material-icons">person_add</i>Nouvelle fiche</NavLink>
                    <NavLink className="pink-text text-lighten-1" to='/consignes'><i className="material-icons">rules</i>Consignes</NavLink>
                    <NavLink className="pink-text text-lighten-1" to='/profiles'><i className="material-icons">view_list</i>Archivage
                        {
                            profiles && profiles.length && <span className="new badge" data-badge-caption="">{profiles.length}</span>
                        }
                    </NavLink>
                </Dropdown>
            </li>
            <li><StyledNavLink to='/accommodations'><Icon left>home</Icon>Hébergements
                {
                    (alerts && <span className="new badge" data-badge-caption="">{alerts}</span>)
                    || <></>
                }
            </StyledNavLink></li>
            { ( admin && <SettingsLinks /> ) ||<></> }
            <li><SearchContainer onClick={() => toggleSearch(true)}><Icon>search</Icon></SearchContainer></li>
            <UserLinks/>
       </ul>
    )
}

const mapStateToProps = (state) => {
    return{
        initial: state.auth.user.pseudo.substr(0, 2).toUpperCase(),
        admin: state.auth.user.admin,
        profiles: state.profiles.profiles,
        alerts: state.accommodations.alerts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        getOldProfiles: () => dispatch(getOldProfiles()),
        getAccommodationsAlertsNumber: () => dispatch(getAccommodationsAlertsNumber())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignedInLinks))
