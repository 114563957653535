import React, {useState} from 'react'
import styled from "styled-components";
import { Icon } from 'react-materialize'

const SearchField = styled.input`
    color: white !important;
    border-bottom: 2px solid white !important;   
    width: 100% !important;
`

const IconContainer = styled.span`
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
`

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    height: 64px;
`

const StyledForm = styled.div`
    width: 90%;
`

const QuickSearch = ({close, search}) => {

    const performSearch = e => {
        search(e.target.value)
    }

    return (
        <Flex>
            <StyledForm action="">
                <SearchField onChange={performSearch} autoFocus className='search-field'/>
            </StyledForm>
            <IconContainer onClick={close}>
                <Icon small>close</Icon>
            </IconContainer>
        </Flex>
    )
}

export default QuickSearch