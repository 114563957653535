import React from 'react'
import styled from "styled-components";

const PanelInfosContainer = styled.div`
    display: flex;
`

const Container = styled.div`
    width: 49%;
    margin: 0;
    padding: 0 16px;
`

const PinkTitle = styled.h5`
    color: #EC407A !important;
    padding-left: 16px;
`

const PanelInfos = ({title, leftInfos, rightInfos, links}) => {
    return(
        <div>
            <PinkTitle>{title}</PinkTitle>
            <PanelInfosContainer>
                <Container>
                    {
                        leftInfos &&
                        leftInfos.map(info => !info.hidden ? <p key={info.label}>{info.label}: <span className="info-value">{info.value}</span></p> : <></>)

                    }
                </Container>
                <Container>
                    {
                        rightInfos &&
                        rightInfos.map(info => !info.hidden ? <p key={info.label}>{info.label}: <span className="info-value">{info.value}</span></p> : <></>)

                    }
                </Container>
            </PanelInfosContainer>
        </div>
    )
}

export default PanelInfos
