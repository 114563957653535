import React from 'react'
import './App.css'
import NavBar from './components/navigation/NavBar'
import Login from './components/auth/Login'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Profiles from "./components/profiles/Profiles";
import ProfileDetails from "./components/profiles/ProfileDetails"
import NewProfile from "./components/profiles/NewProfile"
import styled from 'styled-components'
import Longuet from "./components/longuet/Longuet"
import Confirmation from './components/profiles/form_steps/Confirmation'
import Accommodation from "./components/accomodations/Accommodations"
import SearchProfile from "./components/profiles/SearchProfile"
import Consignes from "./components/consignes/Consignes"
import EditProfile from "./components/profiles/EditProfile";
import UsersAdministration from "./components/users/UsersAdministration";
import UserPage from "./components/users/UserPage";
import Stats from "./components/stats/Stats";

const MainContainer = styled.div`
  margin: 80px 0;
`

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <NavBar />
        <MainContainer>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route path='/longuet/:date?' component={Longuet} />
          <Route path='/accommodations/:date?' component={Accommodation} />
          <Route exact path='/profiles' component={Profiles} />
          <Route exact path='/profiles/search' component={SearchProfile} />
          <Route path='/profiles/details/:id' component={ProfileDetails} />
          <Route path='/profiles/edit/:id' component={EditProfile} />
          <Route exact path='/profiles/new' component={NewProfile} />
          <Route exact path='/consignes' component={Consignes} />
          <Route exact path='/users' component={UsersAdministration} />
          <Route exact path='/stats' component={Stats} />
          <Route exact path='/account' component={UserPage} />
          <Route exact path='/profiles/new/confirmation/:update?' component={Confirmation}/>
        </Switch>
        </MainContainer>
      </div>
    </BrowserRouter>
  );
}

export default App;
