import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getProfilesByFilter, getProfiles } from "../../store/actions/profiles.actions"
import styled from 'styled-components'
import { addEvent } from "../../store/actions/events.actions"
import { Icon } from 'react-materialize'


const Background = styled.div`
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
`

const Container = styled.div`
    position: relative;
    width: 480px;
    height: 600px;
    margin-top: 120px;
`

const ListContainer = styled.div`
    height: 440px;
    overflow: auto;
`

const ClosingIcon = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
`

const NewProfile = styled.div`
    position: absolute;
    top: 5px;
    right: 50px;
    cursor: pointer;
    &:hover{
        color: #ec407a;
    }
`


const ProfileName = styled.div`
    margin: 8px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &:hover{
    color: white;
        background: #ec407a;
    }
`

const ChooseProfile = ({profiles, getProfilesByFilter, close, addEvent, history}) => {

    const handleChange = (e) => {
        getProfilesByFilter(e.target.value)
    }

    const selectProfile = id => {
        const profile = profiles.find(e => e.id === id)
        if(profile) addEvent(profile)
        close()
    }

    const redirectToNewProfile = () => {
        close()
        history.push('/profiles/new')
    }

    return (
        <Background>
            <Container className="card-panel">
                <NewProfile onClick={redirectToNewProfile}><i className="material-icons">person_add</i></NewProfile>
                <ClosingIcon onClick={close}><i className="material-icons">close</i></ClosingIcon>
                <form autoComplete="off" >
                <div className="col s2 input-field">
                    <label className="grey-text text-darken-4" htmlFor="filter">Nom</label>
                    <input  type="text" id="filter" onChange={handleChange} />
                </div>
                </form>
                <ListContainer>
                    {
                        profiles && profiles.length > 0 &&
                        profiles.map((profile, index) => {
                            return (
                                <ProfileName  key={index} className="card-panel"  id={profile.id} onClick={() => selectProfile(profile.id)}>
                                    <p>{profile.first_name} {profile.last_name} {profile.birth_date}</p>
                                    <p>
                                        {
                                            profile.consigne
                                            &&
                                            (
                                                (
                                                    profile.access_forbidden &&
                                                    <Icon className='red-text'>remove_circle</Icon>
                                                )
                                                ||
                                                <Icon className='orange-text'>warning</Icon>
                                            )

                                        }
                                    </p>
                                </ProfileName>
                            )
                        })
                    }
                </ListContainer>
            </Container>
        </Background>
    )
}

const mapStateToProps = state => {
    return {
        profiles: state.profiles.profilesByFilter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProfilesByFilter: filter => dispatch( getProfilesByFilter(filter) ),
        addEvent: profile => dispatch(addEvent(profile))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseProfile)