import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateNewProfile } from "../../../../store/actions/profiles.actions"
import Lookup from "../../../forms_components/Lookup"


const FourthStep = (
    {
        newProfile,
        updateNewProfile,
        legalResponsibles,
    }
) => {

    const [formData, setFormData] = useState({
        parents_live_together: newProfile.parents_live_together ? newProfile.parents_live_together : '',
        legal_responsible: newProfile.legal_responsible ? newProfile.legal_responsible : '0',
        has_lived_in_host_family: newProfile.has_lived_in_host_family ? newProfile.has_lived_in_host_family : '0',
        adoptive_father: newProfile.adoptive_father ? newProfile.adoptive_father : '2',
        adoptive_mother: newProfile.adoptive_mother ? newProfile.adoptive_mother : '2',
        has_ran_away: newProfile.has_ran_away ? newProfile.has_ran_away : '2',
        ran_away_two_times: newProfile.ran_away_two_times ? newProfile.ran_away_two_times : '0',
        has_been_excluded_from_family: newProfile.has_been_excluded_from_family ? newProfile.has_been_excluded_from_family : '2',
        excluded_from_family_two_times: newProfile.excluded_from_family_two_times ? newProfile.excluded_from_family_two_times : 0,
    })

    const [lookupValue, setLookupValue] = useState({
        legal_responsible: formData.legal_responsible,
    })

    const [ranAwayIsDisplayed, displayHasRanAway] = useState(formData.has_ran_away === '1')
    const [hasBeenExcludedDisplayed, displayHasBeenAway] = useState(formData.has_ran_away === '1')

    const handleRanAwayChange = e => {
        handleChange(e)
        displayHasRanAway(e.target.value === '1')
    }

    const handleHasBeenExcludedChange = e => {
        handleChange(e)
        displayHasBeenAway(e.target.value === '1')
    }

    const updateLegalResponsible = e => {
        setLookupValue( {...lookupValue, legal_responsible: e.target.value})
        handleChange(e, 'legal_responsible')
    }

    useEffect( () => {
        return () => {
            updateNewProfile(formData)
        }
    }, [formData])

    const handleChange = (e, id = null) => {
        const property = id ? id : e.target.id
        setFormData({ ...formData, [property]: e.target.value })
    }

    return (
        <>
            <h4>Schéma familial (partie 1/2)</h4>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Les parents légaux vivent-ils ensembles ?</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="parents_live_together" id="parents_live_together" type="radio" onChange={handleChange} value='1' checked={ formData.parents_live_together === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="parents_live_together" id="parents_live_together" type="radio" onChange={handleChange} value='0' checked={ formData.parents_live_together === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="parents_live_together" id="parents_live_together" type="radio" onChange={handleChange} value='' checked={ formData.parents_live_together === '' }/>
                            <span>Non renseigné</span>
                        </label>
                    </div>
                </div>
                <div className="col s6">
                    <Lookup
                        data={legalResponsibles}
                        dataName="type"
                        dataValue="id"
                        label="Responsable légal"
                        updateFunction={updateLegalResponsible}
                        value={lookupValue.legal_responsible}
                    />
                </div>
            </div>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Mère adoptive ?</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="adoptive_mother" id="adoptive_mother" type="radio" onChange={handleChange} value='1' checked={ formData.adoptive_mother === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="adoptive_mother" id="adoptive_mother" type="radio" onChange={handleChange} value='0' checked={ formData.adoptive_mother === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="adoptive_mother" id="adoptive_mother" type="radio" onChange={handleChange} value='2' checked={ formData.adoptive_mother === '2' }/>
                            <span>Pdr</span>
                        </label>
                    </div>
                </div>
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Père adoptif ?</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="adoptive_father" id="adoptive_father" type="radio" onChange={handleChange} value='1' checked={ formData.adoptive_father === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="adoptive_father" id="adoptive_father" type="radio" onChange={handleChange} value='0' checked={ formData.adoptive_father === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="adoptive_father" id="adoptive_father" type="radio" onChange={handleChange} value='2' checked={ formData.adoptive_father === '2' }/>
                            <span>Pdr</span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Le jeune a-t-il déjà fugué de la famille ?</label>
                    <div className="col s6 input-field">
                        <label>
                            <input name="has_ran_away" id="has_ran_away" type="radio" onChange={handleRanAwayChange} value='1' checked={ formData.has_ran_away === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s6 input-field">
                        <label>
                            <input name="has_ran_away" id="has_ran_away" type="radio" onChange={handleRanAwayChange} value='0' checked={ formData.has_ran_away === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                    <div className="col s6 input-field">
                        <label>
                            <input name="has_ran_away" id="has_ran_away" type="radio" onChange={handleRanAwayChange} value='2' checked={ formData.has_ran_away === '2' }/>
                            <span>Pdr</span>
                        </label>
                    </div>
                </div>
                {ranAwayIsDisplayed &&
                    <div className="col s6 input-field">
                        <label className='active grey-text text-darken-4'>Plus de 2 fois ?</label>
                        <div className="col s6 input-field">
                            <label>
                                <input name="ran_away_two_times" id="ran_away_two_times" type="radio" onChange={handleChange} value='1' checked={ formData.ran_away_two_times === '1' }/>
                                <span>Oui</span>
                            </label>
                        </div>
                        <div className="col s6 input-field">
                            <label>
                                <input name="ran_away_two_times" id="ran_away_two_times" type="radio" onChange={handleChange} value='0' checked={ formData.ran_away_two_times === '0' }/>
                                <span>Non</span>
                            </label>
                        </div>
                    </div>
                }
            </div>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Le jeune a-t-il déjà vécu en famille d'accueil ?</label>
                    <div className="col s6 input-field">
                        <label>
                            <input name="has_lived_in_host_family" id="has_lived_in_host_family" type="radio" onChange={handleChange} value='1' checked={ formData.has_lived_in_host_family === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s6 input-field">
                        <label>
                            <input name="has_lived_in_host_family" id="has_lived_in_host_family" type="radio" onChange={handleChange} value='0' checked={ formData.has_lived_in_host_family === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Le jeune a-t-il déjà été exclu de la famille ?</label>
                    <div className="col s6 input-field">
                        <label>
                            <input name="has_been_excluded_from_family" id="has_been_excluded_from_family" type="radio" onChange={handleHasBeenExcludedChange} value='1' checked={ formData.has_been_excluded_from_family === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s6 input-field">
                        <label>
                            <input name="has_been_excluded_from_family" id="has_been_excluded_from_family" type="radio" onChange={handleHasBeenExcludedChange} value='0' checked={ formData.has_been_excluded_from_family === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                    <div className="col s6 input-field">
                        <label>
                            <input name="has_been_excluded_from_family" id="has_been_excluded_from_family" type="radio" onChange={handleHasBeenExcludedChange} value='2' checked={ formData.has_been_excluded_from_family === '2' }/>
                            <span>Non</span>
                        </label>
                    </div>
                </div>
                {hasBeenExcludedDisplayed &&
                    <div className="col s6 input-field">
                        <label className='active grey-text text-darken-4'>Plus de 2 fois ?</label>
                        <div className="col s6 input-field">
                            <label>
                                <input name="excluded_from_family_two_times" id="excluded_from_family_two_times" type="radio" onChange={handleChange} value='1' checked={ formData.excluded_from_family_two_times === '1' }/>
                                <span>Oui</span>
                            </label>
                        </div>
                        <div className="col s6 input-field">
                            <label>
                                <input name="excluded_from_family_two_times" id="excluded_from_family_two_times" type="radio" onChange={handleChange} value='0' checked={ formData.excluded_from_family_two_times === '0' }/>
                                <span>Non</span>
                            </label>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return{
        newProfile: state.profiles.newProfile,
        legalResponsibles: state.extraData.legalResponsibles
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewProfile: credentials => dispatch( updateNewProfile(credentials) ),
    }
}



export default  connect(mapStateToProps, mapDispatchToProps)(FourthStep)
