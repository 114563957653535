import authReducer from "./auth.reducer"
import errorReducer from "./errors.reducer"
import alertReducer from "./alert.reducer"
import profilesReducer from "./profiles.reducer"
import formsReducer from "./forms.reducer"
import extraDataReducer from "./extra_data.reducer"
import eventReducer from "./events.reducer"
import accommodationsReducer from "./accommodations.reducer"
import consignesReducer from './consignes.reducer'
import { combineReducers } from 'redux'



const rootReducer = combineReducers({
    auth : authReducer,
    errors: errorReducer,
    alerts: alertReducer,
    profiles: profilesReducer,
    forms: formsReducer,
    extraData: extraDataReducer,
    events: eventReducer,
    accommodations: accommodationsReducer,
    consignes: consignesReducer
})

export default rootReducer
