import moment from "moment";

const initState = {
    countries: [],
    status: [],
    residences: [],
    arrivals: [],
    intermediates: [],
    livingPlaces: [],
    specificStatuses: [],
    legalResponsibles: [],
    parentLivingStatuses: [],
    educationTypes: [],
    schoolChoiceReasons: [],
    leavingSchoolReasons: [],
    studiesLevels: [],
    users: [],
    exceedingCauses: [],
    visitObjects: [],
    stats: null
}


const getEasterDay = year => {
    const Y =  parseInt(year);
    var C = Math.floor(Y/100);
    var N = Y - 19*Math.floor(Y/19);
    var K = Math.floor((C - 17)/25);
    var I = C - Math.floor(C/4) - Math.floor((C - K)/3) + 19*N + 15;
    I = I - 30*Math.floor((I/30));
    I = I - Math.floor(I/28)*(1 - Math.floor(I/28)*Math.floor(29/(I + 1))*Math.floor((21 - N)/11));
    var J = Y + Math.floor(Y/4) + I + 2 - C + Math.floor(C/4);
    J = J - 7*Math.floor(J/7);
    var L = I - J;
    var M = 3 + Math.floor((L + 40)/44);
    return L + 28 - 31*Math.floor(M/4);
}

const duringOfficeHours = d => {
    const date = moment(d)

    const hour = date.format('HH[-]MM')
    console.log(hour)
    if(hour < '09' && hour > '16') return false

    const day = date.day()
    if(day === 0 || day === 6 ) return false

    const easter = getEasterDay(date.format('YYYY'))

    const publicHolidays = [
        '01-01',
        '01-05',
        '21-06',
        '15-08',
        '01-11',
        '25-12',
        `${easter}-04`,
        `${easter + 1}-04`,
    ]

    return publicHolidays.indexOf(date.format('DD[-]MM')) < 0;
}


const extraDataReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_VISIT_OBJECTS':
            if(action.data) {
                return {...state, visitObjects: action.data}
            }
            return state
        case 'GET_STATS':
            if(action.data) {
                const visits = action.data.visits;
                const stats = {
                    "Total fiches": action.data.totalProfiles,
                    "Fiches créées cette année": action.data.thisYearProfiles,
                    "Visites durant les heures de bureaux": visits.filter(e => duringOfficeHours(e.date_time) && e.vtm === "V").length,
                    "Visites en dehors des heures de bureaux": visits.filter(e => !duringOfficeHours(e.date_time) && e.vtm === "V").length,
                    "Appels durant des heures de bureaux": visits.filter(e => !duringOfficeHours(e.date_time) && e.vtm === "V").length,
                    "Appels en dehors des heures de bureaux": visits.filter(e => !duringOfficeHours(e.date_time) && e.vtm === "V").length,
                    "Emails durant des heures de bureaux": visits.filter(e => !duringOfficeHours(e.date_time) && e.vtm === "V").length,
                    "Emails en dehors des heures de bureaux": visits.filter(e => !duringOfficeHours(e.date_time) && e.vtm === "V").length,
                }

                return {...state, stats: stats}
            }
            return state
        case 'GET_USERS':
            if(action.data){
                return { ...state, users: action.data}
            }
            return state
        case 'GET_EXCEEDING_CAUSES':
            if(action.data) {
                return {...state, exceedingCauses: action.data}
            }
            return state
        case 'GET_COUNTRIES':
            if(action.data){
                return { ...state, countries: action.data}
            }
            return state
        case 'GET_COUNTRIES_ERROR':
            console.log('Getting profiles has failed', action.data)
            return state
        case 'GET_RESIDENCES':
            if(action.data){
                return { ...state, residences: action.data}
            }
            return state
        case 'GET_RESIDENCES_ERROR':
            console.log('Getting residences has failed', action.data)
            return state
        case 'GET_ARRIVALS':
            if(action.data){
                return { ...state, arrivals: action.data}
            }
            return state
        case 'GET_ARRIVALS_ERROR':
            console.log('Getting residences has failed', action.data)
            return state
        case 'GET_INTERMEDIATES':
            if(action.data){
                return { ...state, intermediates: action.data}
            }
            return state
        case 'GET_INTERMEDIATES_ERROR':
            console.log('Getting intermediates has failed', action.data)
            return state
        case 'GET_LIVING_PLACES':
            if(action.data){
                return { ...state, livingPlaces: action.data}
            }
            return state
        case 'GET_LIVING_PLACES_ERROR':
            console.log('Getting living places has failed', action.data)
            return state
        case 'GET_SPECIFIC_STATUSES':
            if(action.data){
                return { ...state, specificStatuses: action.data}
            }
            return state
        case 'GET_SPECIFIC_STATUSES_ERROR':
            console.log('Getting specific statutes has failed', action.data)
            return state
        case 'GET_LEGAL_RESPONSIBLES':
            if(action.data){
                return { ...state, legalResponsibles: action.data}
            }
            return state
        case 'GET_LEGAL_RESPONSIBLES_ERROR':
            console.log('Getting legal responsibles statutes has failed', action.data)
            return state
        case 'GET_PARENT_LIVING_STATUS':
            if(action.data){
                return { ...state, parentLivingStatuses: action.data}
            }
            return state
        case 'GET_PARENT_LIVING_STATUS_ERROR':
            console.log('Getting parent living statutes has failed', action.data)
            return state
        case 'GET_EDUCATION_TYPES':
            if(action.data){
                return { ...state, educationTypes: action.data}
            }
            return state
        case 'GET_EDUCATION_TYPES_ERROR':
            console.log('Getting education types has failed', action.data)
            return state
        case 'GET_SCHOOL_CHOICE_REASONS':
            if(action.data){
                return { ...state, schoolChoiceReasons: action.data}
            }
            return state
        case 'GET_SCHOOL_CHOICE_REASONS_ERROR':
            console.log('Getting school choice reasons has failed', action.data)
            return state
        case 'GET_LEAVING_SCHOOL_REASONS':
            if(action.data){
                return { ...state, leavingSchoolReasons: action.data}
            }
            return state
        case 'GET_LEAVING_SCHOOL_REASONS_ERROR':
            console.log('Getting leaving school reasons has failed', action.data)
            return state
        case 'GET_STUDIES_LEVEL':
            if(action.data){
                return { ...state, studiesLevels: action.data}
            }
            return state
        case 'GET_STUDIES_LEVEL_ERROR':
            console.log('Getting studies level has failed', action.data)
            return state
        /*
        case 'UPDATE_USER':
            return {...state, users: state.users.map(
                u => u.id = action.formData.id ? {...u, admin: action.formData.admin, confirmed: action.formData.confirmed} : u)
            }
            */

        case 'ADD_NEW_USER':
            return {...state, users: state.users.concat([action.data])}
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export default extraDataReducer


