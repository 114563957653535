import React, { useEffect } from 'react'
import {Dropdown, Icon} from "react-materialize";
import {NavLink} from "react-router-dom";
import {logout} from "../../../store/actions/auth.actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import styled from "styled-components";
import {Button, Menu, MenuItem} from "@material-ui/core";


const StyledLink = styled.a `
    border-right: 1px solid #999;
`

const UserLinks = (
    {
        initial,
        logout,
        ...props
    }
) => {
    const signOut = () => {
        logout()
        props.history.push('/')
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <li className='large-width'>
            <a  onClick={handleClick} className="avatar bnt btn-floating pink lighten-1" href="#!">{initial}</a>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <StyledLink className="pink-text text-lighten-1" to='/' onClick={signOut}><Icon left>exit_to_app</Icon>Déconnexion</StyledLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <NavLink className="pink-text text-lighten-1" to='/account' ><Icon left>person</Icon>Mon compte</NavLink>
                </MenuItem>
            </Menu>
        </li>
    )
}

const mapStateToProps = (state) => {
    return{
        initial: state.auth.user.pseudo.substr(0, 2).toUpperCase(),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserLinks))