//const BASE_URL = 'http://127.0.0.1:8000/api/'
const BASE_URL = 'http://sosjeunes24h.be/sos/api/public/api/'

export const LOGIN_URL = `${BASE_URL}login`
export const SIGNUP_URL = `${BASE_URL}register`
export const ACTIVATE_USER_URL = `${BASE_URL}/users/activate`
export const DEACTIVATE_USER_URL = `${BASE_URL}/users/deactivate`
export const GET_PROFILES_URL = `${BASE_URL}profiles`
export const GET_OLD_PROFILES_URL = `${BASE_URL}profiles/old`
export const ARCHIVE_PROFILE_URL = `${BASE_URL}profiles/archive`
export const GET_PROFILE_RAW_DATA = `${BASE_URL}profiles/row_data`
export const ADD_REFERENT = `${BASE_URL}profiles/add_referent`
export const REMOVE_REFERENT = `${BASE_URL}profiles/remove_referent`
export const GET_COUNTRIES_URL = `${BASE_URL}countries`
export const GET_RESIDENCES_URL = `${BASE_URL}residences`
export const GET_ARRIVALS_URL = `${BASE_URL}arrivals`
export const GET_INTERMEDIATES_URL = `${BASE_URL}intermediates`
export const GET_LIVING_PLACES_URL = `${BASE_URL}living_places`
export const GET_SPECIFIC_STATUSES_URL = `${BASE_URL}specific_statuses`
export const GET_LEGAL_RESPONSIBLES_URL = `${BASE_URL}legal_reponsibles`
export const GET_PARENT_LIVING_STATUS_URL = `${BASE_URL}parent_living_statuses`
export const GET_EDUCATION_TYPES_URL = `${BASE_URL}education_types`
export const GET_EXCEEDING_CAUSES_URL = `${BASE_URL}exceeding_causes`
export const GET_SCHOOL_CHOICE_REASONS_URL = `${BASE_URL}school_choice_reasons`
export const GET_LEAVING_SCHOOL_REASONS_URL = `${BASE_URL}leaving_school_reasons`
export const GET_STUDIES_LEVEL_URL = `${BASE_URL}studies_levels`
export const GET_PROFILES_BY_FILTER = `${BASE_URL}profiles/filter`
export const SAVE_NEW_PROFILE = `${BASE_URL}profiles/new`
export const UPDATE_PROFILE = `${BASE_URL}profiles/update`
export const SAVE_NEW_EVENT = `${BASE_URL}visits/new`
export const GET_EVENTS_PER_DAY = `${BASE_URL}visits/day`
export const UPDATE_EVENT = `${BASE_URL}visits/update`
export const DELETE_VISIT = `${BASE_URL}visits/delete`
export const GET_ALL_ACCOMMODATIONS = `${BASE_URL}accommodations`
export const GET_ALL_ACCOMMODATIONS_ALERTS_NUMBER = `${BASE_URL}accommodations/alerts`
export const GET_ACCOMMODATIONS_PER_DAY = `${BASE_URL}accommodations/day`
export const GET_ACCOMMODATIONS_PER_PROFILE = `${BASE_URL}accommodations/profile`
export const GET_EVENTS_PER_PROFILE = `${BASE_URL}visits/profile`
export const ADVANCED_SEARCH = `${BASE_URL}profiles/search`
export const GET_CONSIGNES = `${BASE_URL}consignes`
export const GET_USERS = `${BASE_URL}users`
export const SAVE_NEW_ACCOMMODATION = `${BASE_URL}accommodations/new`
export const GET_ACCOMMODATION_BY_VISIT_ID = `${BASE_URL}accommodations/visit`
export const UPDATE_ACCOMMODATION = `${BASE_URL}accommodations/update`
export const GET_RUNNING_ACCOMMODATIONS = `${BASE_URL}accommodations/running`
export const GET_PENDING_ACCOMMODATIONS = `${BASE_URL}accommodations/pending`
export const ACCEPT_ACCOMMODATION = `${BASE_URL}accommodations/accept`
export const CLOSE_ACCOMMODATION = `${BASE_URL}accommodations/close`
export const DENY_ACCOMMODATION = `${BASE_URL}accommodations/deny`
export const GET_CONSIGNE_BY_YOUNG_ID = `${BASE_URL}consignes/profile`
export const GET_CONSIGNE_BY_ID = `${BASE_URL}consignes/id`
export const DELETE_CONSIGNE = `${BASE_URL}consignes/delete`
export const SAVE_NEW_CONSIGNE= `${BASE_URL}consignes/new`
export const UPDATE_CONSIGNE = `${BASE_URL}consignes/update`
export const GET_DYNAMIC_SHEET = `${BASE_URL}profiles/dynamic_sheet`
export const UPDATE_DYNAMIC_SHEET = `${BASE_URL}profiles/update_dynamic_sheet`
export const UPDATE_USER = `${BASE_URL}users`
export const ADD_NEW_USER = `${BASE_URL}users`
export const UPDATE_PASSWORD = `${BASE_URL}password/update`
export const GET_VISIT_OBJECTS = `${BASE_URL}visit_objects`
export const GET_STATS = `${BASE_URL}stats`














