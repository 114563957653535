import React , {useState, useEffect}  from 'react'
import styled from 'styled-components'
import Lookup from "../../../forms_components/Lookup"
import { connect } from 'react-redux'


const NationalityFormContainer = styled.div`
    margin: 20px 0;
`

const StyledHr = styled.hr`
    margin: 20px 0;
`

const Nationality = ({handleChange, countries, formData, arrivals, specificStatuses}) => {
    const [lookupValue, setLookupValue] = useState({
        nationality: formData.nationality,
        arrivalDate: formData.arrival_date,
        specific_status: formData.specific_status,
    })

    const [asylumSeekerDisplayed, displayAsylumSeeker] = useState(formData.legal_status_obtaining)
    const [menaDetailsAreDisplayed, displayMenaDetails] = useState(formData.specific_status === 2)

    const updateNationality = e => {
        setLookupValue( {...lookupValue, nationality: e.target.value})
        handleChange(e, 'nationality')
    }

    const updateArrival = e => {
        setLookupValue( {...lookupValue, arrivalDate: e.target.value})
        handleChange(e, 'arrival_date')
    }

    const updateSpecificStatus = e => {
        setLookupValue( {...lookupValue, specific_status: e.target.value})
        handleChange(e, 'specific_status')
        displayMenaDetails(e.target.value === 2)
    }

    const handleChangeObtention = e => {
        displayAsylumSeeker(e.target.value === '1')
        handleChange(e)
    }

    return (
        <NationalityFormContainer>
            <div className="card-panel">
                <Lookup
                    data={countries}
                    dataName="name"
                    dataValue="code"
                    label="Choisissez la nationalité"
                    updateFunction={updateNationality}
                    value={lookupValue.nationality}
                />
                <Lookup
                    data={arrivals}
                    dataName="type"
                    dataValue="id"
                    label="Arrivé sur le territoire depuis"
                    updateFunction={updateArrival}
                    value={lookupValue.arrivalDate}
                />
            </div>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Légalité sur le territoire: </label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="legal" id="legal" type="radio" onChange={handleChange} value='1' checked={ formData.legal === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="legal" id="legal" type="radio" onChange={handleChange} value='0' checked={ formData.legal === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="legal" id="legal" type="radio" onChange={handleChange} value='' checked={ formData.legal === '' }/>
                            <span>Non renseigné</span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Est-il dans une procédure pour l’obtention du statut légal ?</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="legal_status_obtaining" id="legal_status_obtaining" type="radio" onChange={handleChangeObtention} value='1' checked={ formData.legal_status_obtaining === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="legal_status_obtaining" id="legal_status_obtaining" type="radio" onChange={handleChangeObtention} value='0' checked={ formData.legal_status_obtaining === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="legal_status_obtaining" id="legal_status_obtaining" type="radio" onChange={handleChangeObtention} value='' checked={ formData.legal_status_obtaining === '' }/>
                            <span>Non renseigné</span>
                        </label>
                    </div>
                </div>
                {   asylumSeekerDisplayed &&
                    <div className="col s6 input-field">
                        <label className='active grey-text text-darken-4'>Est-il demandeur d'asile ?</label>
                        <div className="col s4 input-field">
                            <label>
                                <input name="asylum_seeker" id="asylum_seeker" type="radio" onChange={handleChange} value='1' checked={ formData.asylum_seeker === '1' }/>
                                <span>Oui</span>
                            </label>
                        </div>
                        <div className="col s4 input-field">
                            <label>
                                <input name="asylum_seeker" id="asylum_seeker" type="radio" onChange={handleChange} value='0' checked={ formData.asylum_seeker === '0' }/>
                                <span>Non</span>
                            </label>
                        </div>
                        <div className="col s4 input-field">
                            <label>
                                <input name="asylum_seeker" id="asylum_seeker" type="radio" onChange={handleChange} value='' checked={ formData.asylum_seeker === '' }/>
                                <span>Non renseigné</span>
                            </label>
                        </div>
                    </div>
                }
            </div>
            <div className="card-panel">
                <Lookup
                    data={specificStatuses}
                    dataName="type"
                    dataValue="id"
                    label="Statut spécifique"
                    updateFunction={updateSpecificStatus}
                    value={lookupValue.specific_status}
                />
                {
                    menaDetailsAreDisplayed &&
                    <div className="row">
                        <StyledHr />
                        <div className="col s6 input-field">
                            <label className='active grey-text text-darken-4'>Est-il signalé ?</label>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_reported" id="mena_reported" type="radio" onChange={handleChange} value='1' checked={ formData.mena_reported === '1' }/>
                                    <span>Oui</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_reported" id="mena_reported" type="radio" onChange={handleChange} value='0' checked={ formData.mena_reported === '0' }/>
                                    <span>Non</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_reported" id="mena_reported" type="radio" onChange={handleChange} value='' checked={ formData.mena_reported === '' }/>
                                    <span>Non renseigné</span>
                                </label>
                            </div>
                        </div>
                        <div className="col s6 input-field">
                            <label className='active grey-text text-darken-4'>A-t-il un tuteur ?</label>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_has_tutor" id="mena_has_tutor" type="radio" onChange={handleChange} value='1' checked={ formData.mena_has_tutor === '1' }/>
                                    <span>Oui</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_has_tutor" id="mena_has_tutor" type="radio" onChange={handleChange} value='0' checked={ formData.mena_has_tutor === '0' }/>
                                    <span>Non</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_has_tutor" id="mena_has_tutor" type="radio" onChange={handleChange} value='' checked={ formData.mena_has_tutor === '' }/>
                                    <span>Non renseigné</span>
                                </label>
                            </div>
                        </div>
                        <StyledHr className="col s12"/>
                        <div className="col s6 input-field">
                            <label className='active grey-text text-darken-4'>A-t-il un avocat ?</label>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_has_lawyer" id="mena_has_lawyer" type="radio" onChange={handleChange} value='1' checked={ formData.mena_has_lawyer === '1' }/>
                                    <span>Oui</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_has_lawyer" id="mena_has_lawyer" type="radio" onChange={handleChange} value='0' checked={ formData.mena_has_lawyer === '0' }/>
                                    <span>Non</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_has_lawyer" id="mena_has_lawyer" type="radio" onChange={handleChange} value='' checked={ formData.mena_has_lawyer === '' }/>
                                    <span>Non renseigné</span>
                                </label>
                            </div>
                        </div>
                        <div className="col s6 input-field">
                            <label className='active grey-text text-darken-4'>Est-t-il identifié dans un autre pays ?</label>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_has_other_country_id" id="mena_has_other_country_id" type="radio" onChange={handleChange} value='1' checked={ formData.mena_has_other_country_id === '1' }/>
                                    <span>Oui</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_has_other_country_id" id="mena_has_other_country_id" type="radio" onChange={handleChange} value='0' checked={ formData.mena_has_other_country_id === '0' }/>
                                    <span>Non</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="mena_has_other_country_id" id="mena_has_other_country_id" type="radio" onChange={handleChange} value='' checked={ formData.mena_has_other_country_id === '' }/>
                                    <span>Non renseigné</span>
                                </label>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </NationalityFormContainer>
    )
}

const mapStateToProps = state => {
    return {
        countries: state.extraData.countries,
        arrivals: state.extraData.arrivals,
        specificStatuses: state.extraData.specificStatuses
    }
}



export default connect(mapStateToProps)(Nationality)