import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateNewProfile } from "../../../../store/actions/profiles.actions"
import { setNextStepAvailable } from "../../../../store/actions/forms.action"


const FirstStep = ({newProfile, updateNewProfile, setNextStepAvailable}) => {
    const [formData, setFormData] = useState({
        first_name: newProfile.first_name ? newProfile.first_name : '',
        last_name: newProfile.last_name ? newProfile.last_name : '',
        birth_date: newProfile.birth_date ? newProfile.birth_date : '',
        gender: newProfile.gender ? newProfile.gender : '',
    })


    useEffect( () => {
        return () => {
            updateNewProfile(formData)
        }
    }, [formData])


    const handleChange = async e => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }


    return (
        <>
            <h4>Informations de base: </h4>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className={`${formData.last_name ? "active" : ""} grey-text text-darken-4`} htmlFor="last_name">Nom</label>
                    <input value={formData.last_name} type="text" id="last_name"  onChange={handleChange} />
                </div>
                <div className="col s6 input-field">
                    <label className={`${formData.first_name ? "active" : ""} grey-text text-darken-4`} htmlFor="first_name">Prénom</label>
                    <input value={formData.first_name} type="text" id="first_name" onChange={handleChange} />
                </div>
            </div>
            <div className="row card-panel">
                <div className="col s3 input-field">
                    <label className='active grey-text text-darken-4' htmlFor="birth_date">Date de naissance</label>
                    <input value={formData.birth_date} type="date" id="birth_date" onChange={handleChange} />
                </div>
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Sexe</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="gender" id="gender" type="radio" onChange={handleChange} value='M' checked={formData.gender === 'M'}/>
                            <span>Homme</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="gender" id="gender" type="radio" onChange={handleChange} value='F' checked={formData.gender === 'F'}/>
                            <span>Femme</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="gender"  id="gender" type="radio" onChange={handleChange} value='X' checked={formData.gender === 'X'}/>
                            <span>Autre</span>
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return{
        newProfile: state.profiles.newProfile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewProfile: credentials => dispatch( updateNewProfile(credentials) ),
        setNextStepAvailable: available => dispatch(setNextStepAvailable(available))
    }
}



export default  connect(mapStateToProps, mapDispatchToProps)(FirstStep)
