import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import {
    getPendingAccommodations,
    getRunningAccommodations,
    acceptAccommodation,
    denyAccommodation,
    closeAccommodation
} from "../../store/actions/accommodations.actions"
import Loading from "../alerts/Loading";
import Accommodation from "./Accommodation";
import styled from "styled-components"
import TabChooser from "../utilities/TabChooser";
import Modal from "../utilities/Modal";
import LoadingScreen from "../utilities/LoadingScreen";
import CloseAccommodationModal from "../utilities/CloseAccommodationModal";
import { getExceedingCauses } from "../../store/actions/extra_data.actions";
import { getAccommodationsAlertsNumber } from "../../store/actions/accommodations.actions";
import {
    ACCOMMODATION_DENIED_REASONS
} from '../../constants/lookups.constants'


const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`
const Header = styled.div `
    display: flex;
    justify-content: space-between 
`

const Hr = styled.hr`
    margin: 16px 0;
`
const Label = styled.div`
    width: 14%;
    text-align: center;
`

const Accommodations = (
    {
        loggedIn,
        getPendingAccommodations,
        getRunningAccommodations,
        runningAccommodations,
        pendingAccommodations,
        acceptAccommodation,
        denyAccommodation,
        closeAccommodation,
        getExceedingCauses,
        exceedingCauses,
        getAccommodationsAlertsNumber,
        ...props
    }
) => {
    const [accomodationId, setAccommodationId] = useState(0)
    const [loading, setLoading] = useState(false)
    const [displayedScreen, setDisplayedScreen] = useState(0)
    const [modal, displayModal] = useState(false)
    const [deniedReason, displayDeniedReason] = useState(false)

    useEffect(() => {
        if(!loggedIn) props.history.push('/')
        getPendingAccommodations()
        getRunningAccommodations()
        getExceedingCauses()
    }, [])


    const deniedReasonsModalChoices = []

    ACCOMMODATION_DENIED_REASONS.map( r =>
        deniedReasonsModalChoices.push({
            text: r.type,
            f: async () => {
                setLoading(true)
                displayModal(false)
                displayDeniedReason(false)
                await denyAccommodation(accomodationId, r.id)
                await getPendingAccommodations()
                await getRunningAccommodations()
                setLoading(false)
            }
        })
    )

    deniedReasonsModalChoices.push(    {
        warning: true,
        text: 'Annuler',
        f: () => {
            displayModal(false)
            displayDeniedReason(false)
        }
    })


    const deniedReasonsModalMessage = {
        title: 'Refuser l\'hébergement',
        text: 'Choississez la raison du refus'
    }

    const pendingAccommodationModalMessage = {
        title: 'Statut de la demande en attente',
        text: 'Modifier ?'
    }

    const pendingAccommodationModalchoices = [
        {
            text: 'Acceptée',
            f: async () => {
                setLoading(true)
                displayModal(false)
                await acceptAccommodation(accomodationId)
                await getPendingAccommodations()
                await getRunningAccommodations()
                setLoading(false)
            }
        },
        {
            text: 'Refusée',
            f: () => {
                displayDeniedReason(true)
                displayModal(false)
            }
        },
        {
            warning: true,
            text: 'Annuler',
            f: () => displayModal(false)
        }
    ]

    const closingAccommodation = async destination => {
        setLoading(true)
        displayModal(false)
        await closeAccommodation(accomodationId, destination)
        await getPendingAccommodations()
        await getRunningAccommodations()
        await getAccommodationsAlertsNumber()
        setLoading(false)
    }

    const displayActionModal = id => {
        setAccommodationId(id)
        displayModal(true)
    }

    return(
        <>
            {loading && <LoadingScreen/>}
            {
                deniedReason &&
                <Modal message={deniedReasonsModalMessage} choices={deniedReasonsModalChoices}/>
            }
            {
                !deniedReason && modal && displayedScreen === 0 &&
                <Modal message={pendingAccommodationModalMessage} choices={pendingAccommodationModalchoices}/>

            }
            {
                modal && displayedScreen === 1 &&
                <CloseAccommodationModal closeAccommodation={closingAccommodation} closeModal={() => displayModal(false)}/>
            }

            <div className="container">
                {
                    (
                        runningAccommodations && pendingAccommodations &&
                        <>
                            <HeaderContainer>
                                <div>
                                    <h2>Hébergements</h2>
                                    <h6>{displayedScreen === 0 ? 'En attente' : 'En cours'}</h6>
                                </div>
                                <div>
                                    <TabChooser width={500} tabs={['En attente','En cours',]} chooseFunction={setDisplayedScreen}/>
                                </div>
                            </HeaderContainer>
                            <Hr />
                            <Header>
                                <Label>Numéro de dossier</Label>
                                <Label>Prénom & nom</Label>
                                <Label>{displayedScreen === 1 ? 'Numéro de nuit' : ''}</Label>
                                <Label>Référent</Label>
                                <Label>Responsable prévenu</Label>
                                <Label>Motif de dépassement</Label>
                                <Label>Actions</Label>
                            </Header>
                            {
                                ( displayedScreen === 0 &&
                                    pendingAccommodations.map(
                                        accommodation => {
                                            return (
                                                <Accommodation displayActionModal={() => displayActionModal(accommodation.id)} key={accommodation.young_id} history={props.history} accommodation={accommodation}/>
                                            )
                                        }
                                    )
                                )
                                ||
                                runningAccommodations.map(
                                    accommodation => {
                                        return (
                                            <Accommodation exceedingCauses={exceedingCauses} displayActionModal={() => displayActionModal(accommodation.id)} key={accommodation.young_id} history={props.history} accommodation={accommodation}/>
                                        )
                                    }
                                )
                            }
                        </>
                    ) || <Loading/>
                }
            </div>
        </>
    )
}

const mapStatetoProps = state => {
    return {
        loggedIn: state.auth.loggedIn,
        pendingAccommodations: state.accommodations.pendingAccommodations,
        runningAccommodations: state.accommodations.runningAccommodations,
        exceedingCauses: state.extraData.exceedingCauses
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPendingAccommodations: () => dispatch( getPendingAccommodations() ),
        getRunningAccommodations: () => dispatch( getRunningAccommodations() ),
        acceptAccommodation: id => dispatch( acceptAccommodation(id) ),
        denyAccommodation: (id, reasonId) => dispatch( denyAccommodation(id, reasonId) ),
        closeAccommodation: (id, destination) => dispatch( closeAccommodation(id, destination) ),
        getExceedingCauses: () => dispatch( getExceedingCauses() ),
        getAccommodationsAlertsNumber: () => dispatch( getAccommodationsAlertsNumber() ),
    }
}


export default connect(mapStatetoProps, mapDispatchToProps)(Accommodations)
