import React, {useState, useEffect} from 'react'
import styled from "styled-components"
import { connect } from 'react-redux'
import { getConsignes } from "../../store/actions/consignes.action"
import { Icon } from 'react-materialize'

const YoungAware = styled.div`
    color: ${({aware}) => aware ? 'green' : 'red'};
`

const Consigne = styled.div`
   
`

const Padding24 = styled.div`
    padding: 24px;
`

const Right = styled.div`
    text-align: right;
`

const Span = styled.span`
    position: relative;
    bottom: 7px;
    margin-left: 4px;
`

const Consignes = ({auth, consignes, getConsignes, ...props}) => {

    useEffect(() => {
        if(!auth.loggedIn) props.history.push('/')
        if(!consignes.length) getConsignes()
    }, [])

    console.log(consignes)

    return (
        <div className="container">
            <h2>Consignes</h2>
            <Padding24 className="row">
                <div className="col s1">Date</div>
                <div className="col s2">Jeune</div>
                <div className="col s1">n° dos.</div>
                <div className="col s2">Consigne</div>
                <div className="col s2">Jeune prévenu</div>
                <div className="col s1">Note</div>
                <Right className='col s3 red-text'><Icon>remove_circle</Icon><Span> pas d'entrée</Span></Right>
            </Padding24>
            {
                consignes.length &&
                consignes.map(consigne =>
                    <Consigne key={consigne.id} className="row card-panel">
                        <div className="col s1">{consigne.date_time.split(' ')[0].split('-').reverse().join('/')}</div>
                        <div className="col s2">{consigne.first_name} {consigne.last_name}</div>
                        <div className="col s1">{consigne.young_id}</div>
                        <div className="col s2">{consigne.specific}</div>
                        <YoungAware aware={consigne.young_is_aware} className="col s2">{consigne.young_is_aware ? 'oui' : 'non'}</YoungAware>
                        <div className="col s3">{consigne.note}</div>
                        {
                            consigne.access_forbidden &&
                            <div className='col s1 red-text'><Icon>remove_circle</Icon></div>
                            ||
                            <div className='col s1 yellow-text text-darken-1'><Icon>warning</Icon></div>
                        }
                    </Consigne>
                )
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        consignes: state.consignes.consignes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getConsignes: () =>  dispatch( getConsignes() )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Consignes)

