import React from 'react'
import {Menu, MenuItem} from "@material-ui/core";
import {Icon} from "react-materialize";
import {NavLink} from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled.a `
    border-right: 1px solid #999;
`

export const SettingsLinks = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <li className='large-width'>
            <StyledLink onClick={handleClick}  href="#!"><Icon left className="material-icons">settings</Icon>Settings<Icon right>arrow_drop_down</Icon></StyledLink>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/*
                <MenuItem onClick={handleClose}>

                     <NavLink className="pink-text text-lighten-1" to='/'><Icon left>format_list_bulleted</Icon>Lookups</NavLink>


                </MenuItem>
                 */}
                <MenuItem onClick={handleClose}>
                    <NavLink className="pink-text text-lighten-1" to='/users'><Icon left>person</Icon>Utilisateurs</NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <NavLink className="pink-text text-lighten-1" to='/stats'><Icon left>bar_chart</Icon>Statistiques</NavLink>
                </MenuItem>
            </Menu>
        </li>
    )
}