const initState = {
    nextStepAvailable: false
}

const formsReducer = (state = initState, action) => {
    switch (action.type) {

        case 'NEXT_STEP_AVAILABLE':
            return { ...state, nextStepAvailable: action.available }

        default:
            return state
    }
}

export default formsReducer