import React, { useState } from "react";
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    width: ${({width}) => `${width}px`};
`

const Tab = styled.p`
    color: ${({currentTab, n}) => currentTab === n ? '#EC407A' : '#424242'};
    text-decoration: ${({currentTab, n}) => currentTab === n ? 'underline' : 'none'};
    font-size: 1.2rem;
    width:  ${({width}) => `${width}%`};
    text-align: center;
    cursor: pointer;
`

const TabChooser = ({tabs, chooseFunction, width}) => {
    const [activeTab, setActiveTab] = useState(0)
    const choose = index => {
        setActiveTab(index)
        chooseFunction(index)
    }

    return(
        <Container width={width}>
            {
                tabs.map(
                    (tab,index) => <Tab
                        width={100/tabs.length}
                        className='no-padding'
                        key={index}
                        currentTab={activeTab}
                        n={index}
                        onClick={() => choose(index)}
                    >
                    {tab}
                    </Tab>
                )
            }
        </Container>
    )
}

export default TabChooser