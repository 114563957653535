import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import LonguetHeader from "./LonguetHeader"
import Entry from './Entry'
import styled from 'styled-components'
import ChooseProfile from "../utilities/ChooseProfile"
import ManualEntry from "../utilities/ManulaEntry"
import {getEventsPerDay} from "../../store/actions/events.actions"
import {useParams} from "react-router-dom"
import moment from 'moment'
import Loading from "../alerts/Loading";
import AccommodationRequest from "../utilities/AccommodationRequest"
import { getAccommodationRequestByVisitId, cleanCurrentAccommodation, getRunningAccommodations } from "../../store/actions/accommodations.actions"
import { getProfileById } from "../../store/actions/profiles.actions";
import { getVisitObjects } from "../../store/actions/extra_data.actions"


const Longuet = (
    {
        loggedIn,
        events,
        addEvent,
        getEventsPerDay,
        pastEvents,
        getAccommodationRequestByVisitId,
        cleanCurrentAccommodation,
        getProfileById,
        visitObjects,
        getVisitObjects,
        getRunningAccommodations,
        ...props
    }
) => {
    const [loading, setLoading] = useState(true)
    const [chooseProfileIsDisplayed, displayChooseProfile] = useState(false)
    const [manualEntryIsDisplayed, displayManualEntry] = useState(false)
    const [accommodationRequest, displayAccommodationRequest] = useState(false)
    const [idForAccommodation, setIdForAccomodation] = useState(0)
    const [currentVisit, setCurrentVisit]= useState(null)
    let { date } = useParams()
    const eventsToDisplay = !date ? events : pastEvents

    const updateLonguet = newDate => {
        const today = moment().format('YYYY[-]MM[-]DD')
        props.history.push( today === newDate ? '/longuet' : `/longuet/${newDate}`)
    }

    useEffect(() => {
        setLoading(true)
        if(!loggedIn) props.history.push('/')
        getVisitObjects()
        getRunningAccommodations()
        if(!date) {
            const dt = new Date()
            date = `${
                dt.getFullYear().toString().padStart(4, '0')}-${
                (dt.getMonth()+1).toString().padStart(2, '0')}-${
                dt.getDate().toString().padStart(2, '0')}`
        }
        getEvents(date)
    }, [date, currentVisit])


    const getEvents = async date => {
        await getEventsPerDay(date)
        setLoading(false)
    }

    const selectProfile = () => displayChooseProfile(true)
    const manualEntry = () => displayManualEntry(true)
    const closeChooser = () => displayChooseProfile(false)
    const closeManualEntry = () => displayManualEntry(false)

    const EntryContainer = styled.div`
        display: flex;
        flex-direction: column
    `

    const newAccommodation = async (id, visit) => {
        setLoading(true)
        if(visit.saved) await getAccommodationRequestByVisitId(visit.id)
        await getProfileById(id)
        setLoading(false)
        setCurrentVisit(visit)
        setIdForAccomodation(id)
        displayAccommodationRequest(true)
    }

    return(
        <>
            {
                accommodationRequest &&
                <AccommodationRequest visit={currentVisit} setCurrentVisit={setCurrentVisit}  close={() => { cleanCurrentAccommodation(); displayAccommodationRequest(false)}} youngId={idForAccommodation}/>
            }
            {
                chooseProfileIsDisplayed &&
                <ChooseProfile history={props.history} close={closeChooser}/>
            }
            {
                manualEntryIsDisplayed &&
                <ManualEntry history={props.history} close={closeManualEntry}/>
            }
            <div className="container">

                <h2>Longuet</h2>
                <LonguetHeader selectProfile={selectProfile} displayButtons={!date} manualEntry={manualEntry} updateLonguet={updateLonguet}/>
                {
                    (loading && <Loading/>)
                    ||
                    <EntryContainer>
                        {
                            eventsToDisplay.length > 0 &&
                            eventsToDisplay.sort((a, b) => {
                                return !a.saved ? -2 : a.date_time > b.date_time ? -1 : a.date_time < b.date_time ? 1 : 0
                            }).map(
                                (entry, index) => {
                                    return (
                                        <Entry newAccommodation={newAccommodation} className={`order: ${index}`} key={index} baseEntry={entry} />
                                    )
                                }
                            )
                        }
                    </EntryContainer>
                }
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        loggedIn: state.auth.loggedIn,
        events: state.events.events,
        pastEvents: state.events.pastEvents,
        visitObjects: state.extraData.visitObjects
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRunningAccommodations: () => dispatch(getRunningAccommodations()),
        getVisitObjects: () => dispatch (getVisitObjects()),
        getProfileById: id => dispatch( getProfileById(id) ),
        getEventsPerDay: day => dispatch( getEventsPerDay(day) ),
        getAccommodationRequestByVisitId: id => dispatch( getAccommodationRequestByVisitId(id) ),
        cleanCurrentAccommodation: () => dispatch(cleanCurrentAccommodation())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Longuet)
