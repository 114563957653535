import React from 'react'
import moment from "moment"
import { connect } from 'react-redux'

const getFrenchDate = date => {
    const days = {
        Monday: 'Lundi',
        Tuesday: 'Mardi',
        Wednesday: 'Mercredi',
        Thursday: 'Jeudi',
        Friday: 'Vendredi',
        Saturday: 'Samedi',
        Sunday: 'Dimanche'
    }

    return `${days[moment(date).format('dddd')]} ${moment(date).format('DD[/]MM[/]YYYY')}`
}

const ProfileEvents = ({events, visitObjects}) => {
    return (
        <>
            <div className="row">
                <div className="col s4">Date</div>
                <div className="col s4">Motif</div>
                <div className='col s4'>VTM</div>
            </div>
            {
                events.map((event, index) =>
                    <div key={index} className="card-panel row">
                        <div className="col s4">{getFrenchDate(event.date_time)}</div>
                        <div className="col s4">{visitObjects.find(e => e.id === event.visit_reason ).type}</div>
                        <div className="col s4">{event.vtm}</div>
                    </div>)
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        visitObjects: state.extraData.visitObjects
    }
}

export default connect(mapStateToProps)(ProfileEvents)