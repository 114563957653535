import React, {useState} from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Lookup from "../forms_components/Lookup";
import { connect } from 'react-redux'
import { updateAccommodation } from "../../store/actions/accommodations.actions";
import Loading from "../alerts/Loading";
import { getAccommodationsAlertsNumber } from "../../store/actions/accommodations.actions";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: ${({alert}) => alert ? '2px solid orange' : 'none'};
`

const InfoContainer = styled.div`
    font-weight: bold;
    width: 14%;
    text-align: center;
    max-width: 200px;
    overflow: hidden;
`

const ActionContainer = styled.div`
    font-weight: bold;
    width: 14%;
    text-align: center;
    display: flex;
    justify-content: flex-end;
`

const ResponsibleAware = styled(InfoContainer)`
    color: ${({aware}) => aware ? 'green' : 'red'};
    cursor: pointer;
`

const Action = styled.div`
    margin-right: 16px;
    cursor: pointer;
`

const AccommodationLength = styled(InfoContainer)`
    color: ${({diff, cause}) => diff < 3 || cause ? 'black' : 'red'};
`

const Accommodation = ({accommodation, displayActionModal, exceedingCauses, updateAccommodation, getAccommodationsAlertsNumber}) => {
    const [exceedingCause, setExceedingCause] = useState(accommodation.exceeding_cause)
    const [loading, setLoading] = useState(false)

    const updateExceedingCause = async e => {
        setLoading(true)
        await updateAccommodation({id: accommodation.id, exceeding_cause: e.target.value})
        await getAccommodationsAlertsNumber()
        setExceedingCause(e.target.value)
        setLoading(false)
    }


    const updateResponsibleIsAware = async (value) => {
        setLoading(true)
        await updateAccommodation({id: accommodation.id, responsible_is_aware: value})
        await getAccommodationsAlertsNumber()
        accommodation.responsible_is_aware = value
        setLoading(false)
    }

    return (
        <>
            {
                (loading && <Loading/> ) ||
                <Container alert={!accommodation.exceeding_cause && accommodation.diff > 2} className='card-panel'>
                    <InfoContainer>{accommodation.young_id}</InfoContainer>
                    <InfoContainer>{accommodation.first_name} {accommodation.last_name}</InfoContainer>
                    <AccommodationLength diff={accommodation.diff} cause={accommodation.exceeding_cause} >{accommodation.request_status === 2 ? accommodation.diff : ''}</AccommodationLength>
                    <InfoContainer>{accommodation.type}</InfoContainer>
                    <ResponsibleAware
                        aware={accommodation.responsible_is_aware}
                        onClick={() => updateResponsibleIsAware(accommodation.responsible_is_aware ? false : true)}
                    >
                        {accommodation.responsible_is_aware ? 'oui' : 'non'}
                    </ResponsibleAware>
                    {
                        (
                            accommodation.diff > 2 &&
                            <InfoContainer>
                                <Lookup
                                    data={exceedingCauses}
                                    dataName="type"
                                    dataValue="id"
                                    label="Motif de dépassement"
                                    updateFunction={updateExceedingCause}
                                    value={exceedingCause}
                                />
                            </InfoContainer>
                        )
                        ||
                        <InfoContainer/>
                    }
                    <ActionContainer>
                        <Action>
                            <Link to={`/profiles/details/${accommodation.young_id}`}><i className="material-icons green-text">person</i></Link>
                        </Action>
                        <Action>
                            <i className="material-icons orange-text" onClick={displayActionModal}>{accommodation.request_status === 2 ? 'arrow_forward' : 'edit'}</i>
                        </Action>
                    </ActionContainer>
                </Container>
            }
        </>

    )
}

const mapDispatchToProps = dispatch => {
    return {
        updateAccommodation: formData => dispatch( updateAccommodation(formData) ),
        getAccommodationsAlertsNumber: () => dispatch( getAccommodationsAlertsNumber() )
    }
}


export default connect(null, mapDispatchToProps)(Accommodation)
