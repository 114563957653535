import React from 'react'
import CardInfo from "./CardInfo";
import styled from 'styled-components'
import PanelInfos from "./form_steps/PanelInfos";
import { Collapsible, CollapsibleItem, Icon } from 'react-materialize'
import { Link } from 'react-router-dom'
import {
    STOP_SCHOOL_SINCE
} from "../../constants/lookups.constants"

const Flex = styled.div `
    display: flex;
    justify-content: space-between;
`



const StaticSheet = ({profile}) => {
    const nationalitiesInfos = [
        {
            label: 'Origine',
            value: profile.origin_name
        },
        {
            label: 'Nationalité',
            value: profile.nationality_name
        },
        {
            label: 'Arrivé sur le territoire depuis',
            value: profile.arrival_since_name
        },
        {
            label: 'Légalité dur le territoire',
            value: profile.legal ? 'oui' : 'non'
        },
        {
            label: 'Procédure d\'obtention de stalut légal',
            value: profile.legal_status_obtaining ? 'oui' : 'non'
        },
        {
            label: 'Demandeur d\'asile',
            value: profile.asylum_seeker ? 'oui' : 'non'
        }
    ]

    const statusInfos =   [
        {
            label: 'Résidence',
            value: profile.residence_name
        },
        {
            label: 'Milieu d\'habitation',
            value: profile.living_in_name
        },
        {
            label: 'Intermédiaire',
            value: profile.intermediate_name
        },
        {
            label: 'Contact préalable avec l\'intermédaire',
            value: profile.intermediate_contact ? 'oui' : 'non'
        },
        {
            label: 'Dossier ouvert au SAJ-CBJ',
            value: profile.saj_cbj ? 'oui' : 'non'
        },
        {
            label: 'Dossier ouvert au tribunal',
            value: profile.tribunal ? 'oui' : 'non'
        },
        {
            label: 'Dossier ouvert au SPJ Social',
            value: profile.spj_social ? 'oui' : 'non'
        },
        {
            label: 'Dossier ouvert au CPAS',
            value: profile.cpas ? 'oui' : 'non'
        }
    ]

    const familyPatternFirstPart = [
        {
            label: 'Les parents vivents ils ensemble',
            value: profile.parents_live_together ? 'oui' : 'non'
        },
        {
            label: 'Responsable légal',
            value:  profile.legal_responsible_name
        },
        {
            label: 'Père adoptif',
            value:profile.adoptive_father ? 'oui' : 'non'
        },
        {
            label: 'Mère adoptive',
            value:  profile.adoptive_mother ? 'oui' : 'non'
        }
    ]


    const familyPatternSecondPart = [
        {
            label: 'Le jeune a-t-il déjà vécu en famille d\'accueil',
            value: profile.has_lived_in_host_family ? 'oui' : 'non'
        },
        {
            label: 'Le jeune a-t-il déjà fugué de la famille',
            value: profile.has_ran_away ? `oui ${profile.ran_away_two_times ? ', plus de deux fois': ''} ` : 'non'
        },
        {
            label: 'Le jeune a-t-il déjà été exclu de la famille',
            value: profile.has_been_excluded_from_family ? `oui ${profile.excluded_from_family_two_times ? 'plus de deux fois': ''}` : 'non'
        }
    ]

    const mother = [
        {
            label: 'Mère décédée',
            value: profile.deceased_mother ? 'oui' : 'non'
        },
        {
            label: 'Revenu',
            value: profile.mothers_income ? 'Travail' : 'Aucun revenu du travail'
        },
        {
            label: 'Vit',
            value: profile.mother_living_s_name
        },
        {
            label: 'Contact',
            value: profile.mother_contact
        }
    ]

    const father = [
        {
            label: 'Père décédée',
            value: profile.deceased_father ? 'oui' : 'non'
        },
        {
            label: 'Revenu',
            value: profile.fathers_income ? 'Travail' : 'Aucun revenu du travail'
        },
        {
            label: 'Vit',
            value: profile.father_living_s_name
        },
        {
            label: 'Contact',
            value: profile.father_contact
        }
    ]

    const siblings = [
        {
            label: 'La fraterie est une ressource pour le jeune',
            value: profile.siblings_are_resources ? 'oui' : 'non'
        },
        {
            label: 'Contact',
            value: profile.siblings_contact
        }
    ]

    const auntsAndUncles = [
        {
            label: 'Les oncle(s) et tante(s) sont ressource pour le jeune',
            value: profile.aunt_uncle_are_resources ? 'oui' : 'non'
        },
        {
            label: 'Contact',
            value: profile.aunt_uncle_contact
        }
    ]

    const grandParents = [
        {
            label: 'Les grand parents sont ressource pour le jeune',
            value: profile.grand_parents_are_resources ? 'oui' : 'non'
        },
        {
            label: 'Contact',
            value: profile.grand_parents_contact
        }
    ]

    const hostFamily = [
        {
            label: 'La famille d\'accueil est une ressource pour le jeune',
            value: profile.host_family_is_resource ? 'oui' : 'non'
        },
        {
            label: 'Contact',
            value: profile.host_family_contact
        }
    ]

    const schoolingPartOne = [
        {
            label: "Le jeune est il inscrit dans une école",
            value: profile.is_at_school ? "oui" : "non",
            hidden: profile.education_type === 10
        },
        {
            label: "N'est plus scolarisé depuis",
            value:  STOP_SCHOOL_SINCE.find(e => e.id === profile.school_dropout_since?.toString())?.type ,
            hidden: profile.is_at_school || profile.education_type === 10
        },
        {
            label: "Type d'enseignement",
            value: profile.education_type_name,
            hidden:  !profile.is_at_school && profile.education_type !== 10
        },
        {
            label: "Années d'études",
            value: profile.studies_level_name,
            hidden:  !profile.is_at_school || profile.education_type === 10
        },
        {
            label: "Absentéisme",
            value: profile.absenteeism ? `oui (${profile.justified_absenteeism ? 'justifié' : 'non justifié'})` : 'non',
            hidden:  !profile.is_at_school || profile.education_type === 10
        },
        {
            label: 'Décrochage scolaire',
            value: profile.school_dropout
                ? `oui (${profile.school_dropout_reason_name})`
                : 'non',
            hidden: !profile.is_at_school || profile.education_type === 10
        }
    ]
    const schoolingPartTwo= [
        {
            label: "Choix de l'option contraint",
            value: profile.has_choose_option ? 'non' : 'oui',
            hidden: !profile.is_at_school || profile.education_type === 10
        },
        {
            label: "Le jeune veut il changer d'option",
            value: profile.want_to_change_option ? 'oui' : 'non',
            hidden: !profile.is_at_school || profile.education_type === 10
        },
        {
            label: "Le jeune veut il changer d'école",
            value: profile.want_to_change_school ? 'oui' : 'non',
            hidden: !profile.is_at_school || profile.education_type === 10
        },
        {
            label: "Raison du choix de l'école",
            value: profile.school_choice_reasons_name,
            hidden: !profile.is_at_school || profile.education_type === 10
        }
    ]

    const services = [
        {
            label: "Le jeune as-t-il connu un placement",
            value: profile.has_been_placed ? 'oui' : 'non'
        }
    ]

    return (
        <>
            <Collapsible accordion>
                <CollapsibleItem
                    className="white"
                    expanded={false}
                    header="Nationalité & statut"
                    icon={<Icon>flag</Icon>}
                    node="div"
                >
                    <Flex>
                        <CardInfo
                            title='Nationalité'
                            infos={nationalitiesInfos}
                        />
                        <CardInfo
                            title='Statut'
                            infos={statusInfos}
                        />
                    </Flex>
                </CollapsibleItem>
                <CollapsibleItem
                    className="white"
                    expanded={false}
                    header="Schéma familial"
                    icon={<Icon>people</Icon>}
                    node="div"
                >
                    <PanelInfos leftInfos={familyPatternFirstPart} rightInfos={familyPatternSecondPart} title='Schéma général' />
                    <hr />
                    <Flex>
                        <CardInfo
                            title='Mère'
                            infos={mother}
                        />
                        <CardInfo
                            title='Père'
                            infos={father}
                        />
                    </Flex>
                    <hr/>
                    <Flex>
                        <CardInfo
                            title='Fraterie'
                            infos={siblings}
                        />
                        <CardInfo
                            title='Oncle(s) et Tante(s)'
                            infos={auntsAndUncles}
                        />
                    </Flex>
                    <hr/>
                    <Flex>
                        <CardInfo
                            title='Grand parents'
                            infos={grandParents}
                        />
                        <CardInfo
                            title="Famille d'accueil"
                            infos={hostFamily}
                        />
                    </Flex>
                </CollapsibleItem>
                <CollapsibleItem
                    className="white"
                    expanded={false}
                    header="Scolarité"
                    icon={<Icon>school</Icon>}
                    node="div"
                >
                    <PanelInfos leftInfos={schoolingPartOne} rightInfos={schoolingPartTwo} title='Scolarité' />
                </CollapsibleItem>
                <CollapsibleItem
                    className="white"
                    expanded={false}
                    header="Services"
                    icon={<Icon>store</Icon>}
                    node="div"
                >
                    <PanelInfos leftInfos={services} rightInfos={[]} title='Services' />
                </CollapsibleItem>
            </Collapsible>
            <Link to={`/profiles/edit/${profile.id}`} className='btn'>Editer la fiche statique</Link>
        </>
    )
}

export default StaticSheet

