import React from "react";
import {usePagination, useTable} from "react-table"
import { NavLink } from "react-router-dom";
import styled from 'styled-components'


const Span = styled.span`
  cursor: pointer;
`

const ProfilesTable = ({columns,  data, archive}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    )

    return (
        <>
            {/*
              <pre>
                <code>
                  {JSON.stringify(
                      {
                          pageIndex,
                          pageSize,
                          pageCount,
                          canNextPage,
                          canPreviousPage,
                      },
                      null,
                      1
                  )}
                </code>
              </pre>
            */}
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{
                                cell.column.Header === "Actions"
                                ?<>
                                    <NavLink to={`/profiles/details/${cell.row.original.id}`}>
                                        <i className="material-icons">account_box</i>
                                    </NavLink>
                                    <Span className="tab-action orange-text">
                                        <i onClick={() => archive(cell.row.original.id)} className="material-icons">archive</i>
                                    </Span>
                                </>
                                : cell.render('Cell')
                                }</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <div className="pagination">
                <i onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="pagination-controls material-icons">fast_rewind</i>
                <i onClick={() => previousPage()} disabled={!canPreviousPage} className="pagination-controls material-icons">chevron_left</i>
                <i onClick={() => nextPage()} disabled={!canNextPage} className="pagination-controls material-icons">chevron_right</i>
                <i onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="pagination-controls material-icons">fast_forward</i>
                <span>Page{' '}<strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}</span>
            </div>
        </>
    )
}

export default ProfilesTable
