import axios from "axios"
import moment from 'moment'
import {
    GET_PROFILES_URL,
    GET_PROFILES_BY_FILTER,
    SAVE_NEW_PROFILE,
    ADVANCED_SEARCH,
    GET_PROFILE_RAW_DATA,
    UPDATE_PROFILE,
    GET_DYNAMIC_SHEET,
    UPDATE_DYNAMIC_SHEET,
    GET_OLD_PROFILES_URL,
    ARCHIVE_PROFILE_URL, ADD_REFERENT, REMOVE_REFERENT
} from '../../constants/urls.constants'
import { DYNAMIC_SHEET_STRUCTURE } from "../../constants/structures"


export const getProfiles = () => {
    return async dispatch => {
        const response = await axios.get(GET_PROFILES_URL,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_ALL_PROFILES_ERROR', error }))
        const data = response.data
        dispatch({type: 'GET_ALL_PROFILES', data})
    }
}

export const getOldProfiles = () => {
    const date = moment().add(-15, 'years').format('YYYY[-]MM[-]DD')
    return async dispatch => {
        const response = await axios.get(`${GET_OLD_PROFILES_URL}/${date}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_OLD_PROFILES_ERROR', error }))
        const data = response.data
        dispatch({type: 'GET_OLD_PROFILES', data})
    }
}


export const updateProfile = formData => {
    return async dispatch => {
        const response = await axios.put(
            `${UPDATE_PROFILE}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({type: 'UPDATE_PROFILE_ERROR', error}))
        if (response.data) dispatch({type: 'UPDATE_PROFILE'})
    }
}

export const getProfileById = id => {
    return async dispatch => {
        const response = await axios.get(`${GET_PROFILES_URL}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_PROFILE_ERROR', error }))
        const data = response.data
        dispatch({type: 'GET_PROFILE', data})
    }
}

export const archiveProfile = id => {
    return async dispatch => {
        const response = await axios.get(`${ARCHIVE_PROFILE_URL}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'ARCHIVE_PROFILE_ERROR', error }))
        const data = response.data
        if(data.success) dispatch({type: 'ARCHIVE_PROFILE', id})
    }
}


const parseDynamicSheetData = data => {
    const dynamicSheet = []

    Object.entries(DYNAMIC_SHEET_STRUCTURE).map( ([sectionName, section]) => {
        const s = {}
        s.sectionName = sectionName
        s.sectionData = []
        Object.entries(section).map(([subSectionName, subSection]) => {
            const ss = {}
            ss.subSectionName = subSectionName
            ss.subSectionData = []
            subSection.map(entry => {
                ss.subSectionData.push({
                    itemName: Object.entries(entry)[0][0],
                    itemData:  data[Object.entries(entry)[0][1]],
                    itemField: Object.entries(entry)[0][1]
                })
            })
            s.sectionData.push(ss)
        })
        dynamicSheet.push(s)
    })

    return dynamicSheet
}


export const getDynamicSheet = id => {
    return async dispatch => {
        const response = await axios.get(`${GET_DYNAMIC_SHEET}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_DYNAMIC_SHEET_ERROR', error }))
        if(response.data) {
            const result = response.data[0]
            const data = {
                id: result.id,
                data: parseDynamicSheetData(result)
            }
            dispatch({type: 'GET_DYNAMIC_SHEET', data})
        }
    }
}

export const updateDynamicSheet = formData => {
    return async dispatch => {
        const response = await axios.put(
            `${UPDATE_DYNAMIC_SHEET}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({type: 'UPDATE_DYNAMIC_SHEET_ERROR', error}))

        console.log(response.data)
        if (response.data) dispatch({type: 'UPDATE_DYNAMIC_SHEET'})
    }
}

export const getProfileRawData = id => {
    return async dispatch => {
        const response = await axios.get(`${GET_PROFILE_RAW_DATA}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_PROFILE_ERROR', error }))
        const data = response.data[0]
        console.log(data)
        dispatch({type: 'GET_PROFILE_RAW_DATA', data})
    }
}

export const cleanCurrentProfile = () => {
    return async dispatch => {
        dispatch({type: 'CURRENT_PROFILE_CLEANING'})
    }
}

export const resetNewProfile = () => {
    return async dispatch => {
        dispatch({type: 'RESET_NEW_PROFILE'})
    }
}

export const updateNewProfile = formData => {
    return async dispatch => {
        dispatch({type: 'UPDATE_NEW_PROFILE', formData})
    }
}

export const saveNewProfile = formData => {
    return async dispatch => {
        const response = await axios.post(
            `${SAVE_NEW_PROFILE}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'SAVE_NEW_PROFILE_ERROR', error }))
        const data = response.data
        dispatch({type: 'SAVE_NEW_PROFILE', data})
    }
}

export const getProfilesByFilter = filter => {
        return async dispatch => {
            const response = await axios.get(`${GET_PROFILES_BY_FILTER}/${filter}`,
                {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
                .catch(error => dispatch({ type: 'GET_PROFILES_BY_FILTER_ERROR', error }))

            console.log(response)
            const data = response.data
            dispatch({type: 'GET_PROFILES_BY_FILTER', data})
        }
}

export const resetSearchedProfiles = () => {
    return async dispatch => {
        dispatch({type: 'RESET_SEARCHED_PROFILES'})
    }
}

export const advancedSearch = formData => {

    console.log(formData)
    return async dispatch => {
        const response = await axios.post(
            `${ADVANCED_SEARCH}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'ADVANCED_SEARCH_ERROR', error }))
        const data = response.data
        console.log(data)
        dispatch({type: 'ADVANCED_SEARCH', data})
    }
}


export const addReferent = formData => {
    return async dispatch => {
        const response = await axios.post(
            ADD_REFERENT,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'ADD_REFERENT_ERROR', error }))
        if(response.data.success) dispatch({type: 'ADD_REFERENT', formData})
    }
}

export const removeReferent = formData => {
    console.log(formData)
    return async dispatch => {
        const response = await axios.patch(
            REMOVE_REFERENT,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => {
                console.log(error)
                return dispatch({ type: 'REMOVE_REFERENT_ERROR', error })
            } )

        console.log(response)

        if(response.data.success) dispatch({type: 'REMOVE_REFERENT', formData})
    }
}
