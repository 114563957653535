import React, {useEffect, useState} from 'react'
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import {
    cleanCurrentProfile,
    getProfileById,
    addReferent,
    removeReferent
} from "../../store/actions/profiles.actions"
import { getAccommodationsPerProfile } from "../../store/actions/accommodations.actions"
import Loading from "../alerts/Loading"
import StaticSheet from "./StaticSheet"
import styled from "styled-components"
import TabChooser from "../utilities/TabChooser";
import DynamicSheet from "./DynSheet";
import {getEventsPerProfile} from "../../store/actions/events.actions"
import ProfileEvents from "./ProfileEvents"
import ProfilesAccomomodations from "./ProfilesAccommodations"
import {deleteConsigne, getConsigneById, cleanConsigne} from "../../store/actions/consignes.action";
import ProfileConsigne from "./ProfileConsigne";
import Modal from "../utilities/Modal";
import LoadingScreen from "../utilities/LoadingScreen";
import { getUsers, getVisitObjects } from "../../store/actions/extra_data.actions";


const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`

const Hr = styled.hr`
    margin: 16px 0;
`

const ReferentsContainer = styled.div`
  margin-top: 16px;
  padding-top: 8px !important;
`
const Referent = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
`

const ReferentTitle =styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Pointer = styled.p`
  cursor: pointer;
`


const ProfileDetails = (
    {
        auth,
        profile,
        getProfileById,
        cleanProfile,
        accommodations,
        getAccommodations,
        getEvents,
        events,
        currentProfileConsigne,
        getConsigneById,
        deleteConsigne,
        cleanConsigne,
        addReferent,
        removeReferent,
        users,
        getUsers,
        getVisitObjects,
        ...props
    }
) => {
    const { id } = useParams()
    const [displayedScreen, setDisplayedScreen] = useState(0)
    const [addReferentScreen, displayAddReferent] = useState(false)
    const [referentLoading, setReferentLoading] = useState(false)
    const [removeReferentScreen, displayRemoveReferent] = useState(false)
    const [currentSlot, setCurrentSlot] = useState(0)
    const [confirm, needConfirm] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect( () => {
        if(!auth.loggedIn || !id)props.history.push('/')
        else {
            getVisitObjects()
            getUsers()
            getEvents(id)
            getProfileById(id)
            getAccommodations(id)
            getConsigneById(id)
            setDisplayedScreen(0)
        }
        return () => cleanProfile()
    }, [id])

    const addReferentMessage = {
        title: "Ajouter un référents",
        text:''
    }

    const addReferentChoices = []

    if(users && profile) users.filter(u =>
        u.id !== profile.referent_1 &&
        u.id !== profile.referent_2 &&
        u.id !== profile.referent_3
        ).map( u => addReferentChoices.push({
        text: u.pseudo,
        f: async () => {
            setReferentLoading(true)
            displayAddReferent(false)
            await addReferent({
                young_id: profile.id,
                pseudo: u.pseudo,
                referent_id: u.id,
                slot: !profile.referent_1 ? 1 : !profile.referent_2 ? 2 : 3
            })
            setReferentLoading(false)
        }
    }))

    addReferentChoices.push({
        text: 'Annuler',
        warning: true,
        f: () => displayAddReferent(false)
    })


    const removeReferentMessage = {
        title: 'Retirer le référent ?',
        text: ''
    }

    const removeReferentChoices = [
        {
            text: 'Oui',
            f: async () => {
                displayRemoveReferent(false)
                setReferentLoading(true)
                await removeReferent({
                    slot: currentSlot,
                    young_id: profile.id
                })
                setReferentLoading(false)
            }
        },
        {
            text: 'Annuler',
            warning: true,
            f: () => displayRemoveReferent(false)
        }
    ]


    const cancelConsigneMessage = {
        title: 'Lever la consigne ?',
        text: ''
    }

    const cancelConsigneChoices = [
        {
            text: 'Oui',
            f: async () => {
                needConfirm(false)
                setLoading(true)
                await deleteConsigne(currentProfileConsigne.id)
                await cleanConsigne()
                setLoading(false)
            }
        },
        {
            text: 'Annuler',
            warning: true,
            f: () => needConfirm(false)
        }
    ]


    const showRemoveReferent = slot => {
        setCurrentSlot(slot)
        displayRemoveReferent(true)
    }


    return(
        <>
            { removeReferentScreen && <Modal message={removeReferentMessage} choices={removeReferentChoices} /> }
            { addReferentScreen && <Modal message={addReferentMessage} choices={addReferentChoices} /> }
            { loading && <LoadingScreen/> }
            { confirm && <Modal message={cancelConsigneMessage} choices={cancelConsigneChoices} /> }
            <div className="container">
                {
                    (
                        profile && accommodations &&
                        <>
                            <HeaderContainer>
                                <div>
                                    <h2>{profile.first_name} {profile.last_name}</h2>
                                    {
                                        profile.gender && profile.birth_date &&
                                        <h6>Sexe: {profile.gender} | {profile.gender === 'F' ? "Née" : "Né"} le {profile.birth_date.split('-').reverse().join('/')} | N° dossier: {profile.id}</h6>
                                    }
                                    {
                                        (
                                            !referentLoading &&
                                            <ReferentsContainer className="card-panel">
                                                <ReferentTitle>
                                                    <h5>Référents</h5>
                                                    {
                                                        (
                                                            profile.referent1 && profile.referent2 && profile.referent3 &&
                                                            <></>
                                                        )
                                                        || <Pointer onClick={() => displayAddReferent(true)}><i className="material-icons green-text">add</i></Pointer>
                                                    }
                                                </ReferentTitle>
                                                {
                                                    profile.referent_1 &&
                                                    <Referent>
                                                        <p>{profile.referent1}</p>
                                                        <Pointer onClick={() => showRemoveReferent(1)}><i className="material-icons tiny red-text">close</i></Pointer>
                                                    </Referent>
                                                }
                                                {
                                                    profile.referent_2 &&
                                                    <Referent>
                                                        <p>{profile.referent2}</p>
                                                        <Pointer onClick={() => showRemoveReferent(2)}><i className="material-icons tiny red-text">close</i></Pointer>
                                                    </Referent>
                                                }
                                                {
                                                    profile.referent_3 &&
                                                    <Referent>
                                                        <p>{profile.referent3}</p>
                                                        <Pointer onClick={() => showRemoveReferent(3)}><i className="material-icons tiny red-text">close</i></Pointer>
                                                    </Referent>
                                                }
                                            </ReferentsContainer>
                                        )
                                        || <Loading/>
                                    }
                                </div>
                                <div>
                                    <TabChooser width={780} tabs={['Carnet de bord','Fiche statique', 'Fiche dynamique', 'Hébergements', 'Consigne']} chooseFunction={setDisplayedScreen}/>
                                </div>
                            </HeaderContainer>
                            <Hr />
                            {
                                (displayedScreen  === 4 && <ProfileConsigne profile={profile} needConfirm={needConfirm} consigne={currentProfileConsigne}/>)
                                ||
                                ( displayedScreen === 2 && <DynamicSheet profile={profile}/> )
                                ||
                                ( displayedScreen === 1 && <StaticSheet profile={profile} /> )
                                ||
                                ( displayedScreen === 0 && events && <ProfileEvents events={events} /> )
                                ||
                                <ProfilesAccomomodations accommodations={accommodations} />
                            }

                        </>
                    ) || <Loading/>
                }
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        profile: state.profiles.currentProfile,
        accommodations: state.accommodations.currentProfileAccommodations,
        events: state.events.currentProfileEvents,
        currentProfileConsigne: state.consignes.currentProfileConsigne,
        users: state.extraData.users,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProfileById: id => dispatch( getProfileById(id) ),
        cleanProfile: () => dispatch( cleanCurrentProfile() ),
        getAccommodations: id => dispatch( getAccommodationsPerProfile(id) ),
        getEvents: id => dispatch( getEventsPerProfile(id) ),
        getConsigneById: id => dispatch( getConsigneById(id) ),
        deleteConsigne: id => dispatch( deleteConsigne(id) ),
        addReferent: formData => dispatch( addReferent(formData) ),
        removeReferent: formData => dispatch( removeReferent(formData) ),
        cleanConsigne: () => dispatch(cleanConsigne()),
        getUsers: () => dispatch(getUsers()),
        getVisitObjects: () => dispatch( getVisitObjects() )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails)
