import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import LoadingScreen from "../../utilities/LoadingScreen";
import axios from "axios"
import {
    SAVE_NEW_PROFILE,
} from '../../../constants/urls.constants'
import {useParams} from "react-router";
import { updateProfile } from "../../../store/actions/profiles.actions";


const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 306px;
`


const Confirmation = ({newProfile, saveNewProfile, auth, newProfileId, updateProfile, ...props}) => {
    const {update} = useParams()
    const [loading, setLoading] = useState(false)
    console.log('update', update)

    useEffect(() => {
        if(!auth.loggedIn) props.history.push('/')
    }, [])

    const save = async () => {
        console.log(newProfile)
        if (newProfile.legal_responsible === 3){
            newProfile.deceased_mother  = 0
            newProfile.deceased_father = 0
            newProfile.mother_living_status = 2
            newProfile.father_living_status = 2
        }
        console.log(newProfile)

        let id
        setLoading(true)

        if(!update) {
            const response = await axios.post(
                `${SAVE_NEW_PROFILE}`,
                newProfile,
                {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
                .catch(error => console.log(error))
            id = response.data
        } else {
            await updateProfile(newProfile)
            id = newProfile.id
        }

        setLoading(false)
        props.history.push(`/profiles/details/${id}`)
    }

    const redirect = () => {
        const url = update ? `/profiles/edit/${newProfile.id}` : '/profiles/new'
        props.history.push(url)
    }

    return(
        <>
            {
                loading && <LoadingScreen/>
            }
            <div className="container">
                <h2>{update ? `${newProfile.first_name} ${newProfile.last_name}: Mise à jour du profil` : 'Nouveau profil: Sauvegarder'}</h2>
                <Flex>
                    <p>
                        <button className="btn pink lighten-1" onClick={redirect}>Retour au formulaire</button>
                    </p>
                    <p>
                        <button onClick={save}  disabled={!newProfile.last_name || !newProfile.first_name} className="btn green">Sauver</button>
                    </p>
                </Flex>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return{
        auth: state.auth,
        newProfile: state.profiles.newProfile,
        newProfileId: state.profiles.newProfileId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfile: data => dispatch( updateProfile(data) )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation)
