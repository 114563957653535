import React, {useState, useEffect} from 'react'
import {connect} from "react-redux"
import {getOldProfiles, archiveProfile} from "../../store/actions/profiles.actions"
import Loading from "../alerts/Loading"
import ProfilesTable from "../utilities/ProfilesTable";
import Modal from "../utilities/Modal";
import {login} from "../../store/actions/auth.actions";



const Profiles = ({getOldProfiles, profiles, auth, archiveProfile,...props}) => {

    const [confirm, needConfirm] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!auth.loggedIn) props.history.push('/')
        else getOldProfiles()
    }, [])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Jeune',
                columns: [
                    {
                        Header: 'First Name',
                        accessor: 'first_name',
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'last_name',
                    },
                    {
                        Header: 'Actions'
                    },
                ],
            },
        ],
        []
    )

    const message = {
        title: 'Archivage',
        text: 'Voulez vous archiver ce profil?'
    }

    const choices = [
        {
            text: 'Oui',
            f: async () => {
                needConfirm(false)
                setLoading(true)
                await archiveProfile(currentId)
                setLoading(false)
            }
        },
        {
            text: 'Annuler',
            warning: true,
            f: () => {
                setCurrentId(null)
                needConfirm(false)
            }
        }
    ]

    const askForArchiving = id => {
        needConfirm(true)
        setCurrentId(id)
    }

    return (
        <>
            { confirm && <Modal message={message} choices={choices} /> }
            <div className="container">
                <h2>Profils à archiver</h2>
                <h5>{profiles.length} profil{profiles.length > 1 ? 's' : ''} restant{profiles.length > 1 ? 's' : ''}</h5>
                {
                    (   profiles && !loading &&
                        <div className="card-panel">
                            <ProfilesTable archive={askForArchiving} columns={columns} data={profiles} />
                        </div>
                    ) || <Loading/>
                }
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getOldProfiles: () => dispatch(getOldProfiles()),
        archiveProfile: id => dispatch(archiveProfile(id)),
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        profiles: state.profiles.profiles
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Profiles)

