import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateNewProfile } from "../../../../store/actions/profiles.actions"
import Lookup from "../../../forms_components/Lookup"
import {
    STOP_SCHOOL_SINCE
} from "../../../../constants/lookups.constants"

const SixthStep = (
    {
        newProfile,
        updateNewProfile,
        educationTypes,
        schoolChoiceReasons,
        leavingSchoolReasons,
        studiesLevels
    }
) => {
    const [formData, setFormData] = useState({
        education_type: newProfile.education_type ? newProfile.education_type : '',
        education_length: newProfile.education_length ? newProfile.education_length : '',
        is_at_school: newProfile.is_at_school ? newProfile.is_at_school : '',
        absenteeism: newProfile.absenteeism ? newProfile.absenteeism : '',
        justified_absenteeism: newProfile.justified_absenteeism ? newProfile.justified_absenteeism : '',
        school_dropout: newProfile.school_dropout ? newProfile.school_dropout : '',
        school_dropout_reason : newProfile.school_dropout_reason ? newProfile.school_dropout_reason : '',
        has_choose_option: newProfile.has_choose_option ? newProfile.has_choose_option : '',
        want_to_change_option: newProfile.want_to_change_option ? newProfile.want_to_change_option : '',
        want_to_change_school: newProfile.want_to_change_school ? newProfile.want_to_change_school : '',
        school_dropout_since: newProfile.school_dropout_since ? newProfile.school_dropout_since : '',
        school_choice_reasons: newProfile.school_choice_reasons ? newProfile.school_choice_reasons : '',
    })

    const [menaSchool, setMenaShool] = useState(formData.education_type === 10)
    const [schoolDetailsAreDisplayed, displaySchoolDetails] = useState(formData.is_at_school == '1')
    const [absenteeismDetailsAreDisplayed, displayAbsenteeismDetails] = useState(formData.absenteeism == '1')
    const [schoolDropoutDetailsAreDisplayed, displaySchoolDropoutDetails] = useState(formData.school_dropout == '1')
    const [hasLeaveSchoolIsDisplayed, displayHasLeaveSchool] = useState(formData.is_at_school == '0')

    const [lookupValue, setLookupValue] = useState({
        education_type: formData.education_type,
        education_length: formData.education_length,
        school_dropout_reason: formData.school_dropout_reason,
        school_choice_reasons: formData.school_choice_reasons,
        school_dropout_since: formData.school_dropout_since
    })

    const updateSchoolDropoutSince = e => {
        setLookupValue( {...lookupValue, school_dropout_since: e.target.value})
        handleChange(e, 'school_dropout_since')
    }

    const updateEducationType = e => {
        setMenaShool(e.target.value == 10)
        setLookupValue( {...lookupValue, education_type: e.target.value})
        handleChange(e, 'education_type')
    }

    const updateEducationLength = e => {
        setLookupValue( {...lookupValue, education_length: e.target.value})
        handleChange(e, 'education_length')
    }


    const updateSchoolDropoutReason = e => {
        setLookupValue( {...lookupValue, school_dropout_reason: e.target.value})
        handleChange(e, 'school_dropout_reason')
    }

    const updateSchoolChoiceReason = e => {
        setLookupValue( {...lookupValue, school_choice_reasons: e.target.value})
        handleChange(e, 'school_choice_reasons')
    }

    useEffect( () => {
        return () => {
            updateNewProfile(formData)
        }
    }, [formData])

    const handleChange = (e, id = null) => {
        const property = id ? id : e.target.id
        setFormData({ ...formData, [property]: e.target.value })
    }

    const handleChangeIsAtSchool = e => {
        displaySchoolDetails(e.target.value === '1')
        displayHasLeaveSchool(e.target.value === '0')
        handleChange(e)
    }

    const handleChangeSchoolDropout = e => {
        displaySchoolDropoutDetails(e.target.value === '1')
        handleChange(e)
    }

    const handleChangeAbsenteeism = e => {
        displayAbsenteeismDetails(e.target.value === '1')
        handleChange(e)
    }

    return (
        <>
            <h4>Scolarité</h4>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <Lookup
                        data={educationTypes}
                        dataName="type"
                        dataValue="id"
                        label="Type d'enseignement"
                        updateFunction={updateEducationType}
                        value={lookupValue.education_type}
                    />
                </div>
                {
                    (
                        !menaSchool &&
                        <div className="col s6 input-field">
                            <Lookup
                                data={studiesLevels}
                                dataName="type"
                                dataValue="id"
                                label="Années d'études"
                                updateFunction={updateEducationLength}
                                value={lookupValue.education_length}
                            />
                        </div>
                    )
                }
            </div>
            {
                (
                    !menaSchool &&
                        <>
                            <div className="row card-panel">
                                <div className="col s6 input-field">
                                    <label className='active grey-text text-darken-4'>Le jeune est-il inscrit dans une école ?</label>
                                    <div className="col s4 input-field">
                                        <label>
                                            <input name="is_at_school" id="is_at_school" type="radio" onChange={handleChangeIsAtSchool} value='1' checked={ formData.is_at_school == '1' }/>
                                            <span>Oui</span>
                                        </label>
                                    </div>
                                    <div className="col s4 input-field">
                                        <label>
                                            <input name="is_at_school" id="is_at_school" type="radio" onChange={handleChangeIsAtSchool} value='0' checked={ formData.is_at_school == '0' }/>
                                            <span>Non</span>
                                        </label>
                                    </div>
                                    <div className="col s4 input-field">
                                        <label>
                                            <input name="is_at_school" id="is_at_school" type="radio" onChange={handleChangeIsAtSchool} value='' checked={ formData.is_at_school == '' }/>
                                            <span>Non renseigné</span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    hasLeaveSchoolIsDisplayed &&
                                    <div className="col s6 input-field">
                                        <label className={`${formData.school_dropout_since ? "active" : ""} grey-text text-darken-4`} htmlFor="school_dropout_since">N’est plus scolarisé depuis</label>
                                        <div className="col s6 input-field">
                                            <Lookup
                                                data={STOP_SCHOOL_SINCE}
                                                dataName="type"
                                                dataValue="id"
                                                label="Années d'études"
                                                updateFunction={updateSchoolDropoutSince}
                                                value={lookupValue.education_length}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                schoolDetailsAreDisplayed &&
                                <div>
                                    <div className="row card-panel">
                                        <div className="col s6 input-field">
                                            <label className='active grey-text text-darken-4'>Absentéisme ? </label>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="absenteeism" id="absenteeism" type="radio" onChange={handleChangeAbsenteeism} value='1' checked={ formData.absenteeism == '1' }/>
                                                    <span>Oui</span>
                                                </label>
                                            </div>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="absenteeism" id="absenteeism" type="radio" onChange={handleChangeAbsenteeism} value='0' checked={ formData.absenteeism == '0' }/>
                                                    <span>Non</span>
                                                </label>
                                            </div>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="absenteeism" id="absenteeism" type="radio" onChange={handleChangeAbsenteeism} value='' checked={ formData.absenteeism == '' }/>
                                                    <span>Non renseigné</span>
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            absenteeismDetailsAreDisplayed &&
                                            <div className="col s6 input-field">
                                                <label className='active grey-text text-darken-4'>Absentéisme justifié ? </label>
                                                <div className="col s4 input-field">
                                                    <label>
                                                        <input name="justified_absenteeism" id="justified_absenteeism" type="radio" onChange={handleChange} value='1' checked={ formData.justified_absenteeism == '1' }/>
                                                        <span>Oui</span>
                                                    </label>
                                                </div>
                                                <div className="col s4 input-field">
                                                    <label>
                                                        <input name="justified_absenteeism" id="justified_absenteeism" type="radio" onChange={handleChange} value='0' checked={ formData.justified_absenteeism == '0' }/>
                                                        <span>Non</span>
                                                    </label>
                                                </div>
                                                <div className="col s4 input-field">
                                                    <label>
                                                        <input name="justified_absenteeism" id="justified_absenteeism" type="radio" onChange={handleChange} value='' checked={ formData.justified_absenteeism == '' }/>
                                                        <span>Non renseigné</span>
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="row card-panel">
                                        <div className="col s6 input-field">
                                            <label className='active grey-text text-darken-4'>Décrochage scolaire ? </label>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="school_dropout" id="school_dropout" type="radio" onChange={handleChangeSchoolDropout} value='1' checked={ formData.school_dropout == '1' }/>
                                                    <span>Oui</span>
                                                </label>
                                            </div>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="school_dropout" id="school_dropout" type="radio" onChange={handleChangeSchoolDropout} value='0' checked={ formData.school_dropout == '0' }/>
                                                    <span>Non</span>
                                                </label>
                                            </div>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="school_dropout" id="school_dropout" type="radio" onChange={handleChangeSchoolDropout} value='' checked={ formData.school_dropout == '' }/>
                                                    <span>Non renseigné</span>
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            schoolDropoutDetailsAreDisplayed &&
                                            <div className="col s6 input-field">
                                                <Lookup
                                                    data={leavingSchoolReasons}
                                                    dataName="type"
                                                    dataValue="id"
                                                    label="Raison du décrochage"
                                                    updateFunction={updateSchoolDropoutReason()}
                                                    value={lookupValue.school_dropout_since}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="row card-panel">
                                        <div className="col s6 input-field">
                                            <label className='active grey-text text-darken-4'>Choix de l'option contraint ?</label>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="has_choose_option" id="has_choose_option" type="radio" onChange={handleChange} value='0' checked={ formData.has_choose_option == '0' }/>
                                                    <span>Oui</span>
                                                </label>
                                            </div>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="has_choose_option" id="has_choose_option" type="radio" onChange={handleChange} value='1' checked={ formData.has_choose_option == '1' }/>
                                                    <span>Non</span>
                                                </label>
                                            </div>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="has_choose_option" id="has_choose_option" type="radio" onChange={handleChange} value='' checked={ formData.has_choose_option == '' }/>
                                                    <span>Non renseigné</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col s6 input-field">
                                            <label className='active grey-text text-darken-4'>Le jeune veut il changer d'option ?</label>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="want_to_change_option" id="want_to_change_option" type="radio" onChange={handleChange} value='1' checked={ formData.want_to_change_option == '1' }/>
                                                    <span>Oui</span>
                                                </label>
                                            </div>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="want_to_change_option" id="want_to_change_option" type="radio" onChange={handleChange} value='0' checked={ formData.want_to_change_option == '0' }/>
                                                    <span>Non</span>
                                                </label>
                                            </div>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="want_to_change_option" id="want_to_change_option" type="radio" onChange={handleChange} value='' checked={ formData.want_to_change_option == '' }/>
                                                    <span>Non renseigné</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row card-panel">
                                        <div className="col s6 input-field">
                                            <label className='active grey-text text-darken-4'>Le jeune veut-il changer d’école ?</label>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="want_to_change_school" id="want_to_change_school" type="radio" onChange={handleChange} value='1' checked={ formData.want_to_change_school == '1' }/>
                                                    <span>Oui</span>
                                                </label>
                                            </div>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="want_to_change_school" id="want_to_change_school" type="radio" onChange={handleChange} value='0' checked={ formData.want_to_change_school == '0' }/>
                                                    <span>Non</span>
                                                </label>
                                            </div>
                                            <div className="col s4 input-field">
                                                <label>
                                                    <input name="want_to_change_school" id="want_to_change_school" type="radio" onChange={handleChange} value='' checked={ formData.want_to_change_school == '' }/>
                                                    <span>Non renseigné</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col s6 input-field">
                                            <Lookup
                                                data={schoolChoiceReasons}
                                                dataName="type"
                                                dataValue="id"
                                                label="Raison du choix de l'école"
                                                updateFunction={updateSchoolChoiceReason}
                                                value={lookupValue.school_choice_reasons}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                ) || <></>
            }

        </>
    )
}

const mapStateToProps = state => {
    return{
        newProfile: state.profiles.newProfile,
        educationTypes: state.extraData.educationTypes,
        schoolChoiceReasons: state.extraData.schoolChoiceReasons,
        leavingSchoolReasons: state.extraData.leavingSchoolReasons,
        studiesLevels: state.extraData.studiesLevels,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewProfile: credentials => dispatch( updateNewProfile(credentials) ),
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(SixthStep)
