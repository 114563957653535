import axios from "axios"
import {
    LOGIN_URL,
    SIGNUP_URL,
    UPDATE_USER,
    ADD_NEW_USER,
    UPDATE_PASSWORD,
    ACTIVATE_USER_URL,
    DEACTIVATE_USER_URL
} from '../../constants/urls.constants'

export const login = credentials => {
    return async dispatch => {
        const response = await axios.post(LOGIN_URL, credentials)
            .catch(error => {
                console.log(error)
                const errorStatus = error.response ? error.response.status : 999
                return dispatch({ type: 'LOGIN_ERROR', errorStatus })
            })

        if(response.data) {
            const data = response.data
            dispatch({type: 'LOGIN', data})
        }
    }
}

export const signup = credentials => {
    return async dispatch => {
        const data = await axios.post(SIGNUP_URL, credentials)
            .catch(error => dispatch({type: 'SIGNUP_ERROR', error}))
        dispatch({type: 'SIGNUP', data})
    }
}

export const logout = () => {
    return async dispatch => {
        dispatch({type: 'LOGOUT'})
    }
}

export const activateUser = (id) => {
    return async dispatch => {

    }
}

export const updateUser = formData => {
    const data = {...formData}
    delete data.passwordConfirmation
    return async dispatch => {
        const response = await axios.put(
            `${UPDATE_USER}`,
            data,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => {
                dispatch({type: 'UPDATE_USER_ERROR', error})
                console.log(error)
            })
        dispatch({type: 'UPDATE_USER', formData})
    }
}

export const updatePassword = credentials => {
    const data = {...credentials}
    delete data.passwordConfirmation
    return async dispatch => {
        const response = await axios.put(
            `${UPDATE_PASSWORD}`,
            data,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => {
                dispatch({type: 'UPDATE_PASSWORD_ERROR', error})
                console.log(error)
            })
        console.log(response.data)
        if(response.data.error) {
            const error = response.data.error
            dispatch({type: 'ADD_NEW_USER_ERROR', error})
        } else {
            dispatch({type: 'PASSWORD_UPDATED'})
        }
    }
}

export const saveNewUser = formData => {
    return async dispatch => {
        const response = await axios.post(
            `${ADD_NEW_USER}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => {
                console.log(error)
                dispatch({type: 'ADD_NEW_USER_ERROR', error})
            })

        console.log(response)
        if(response.data && response.data.error) {
            const error = response.data.error
            dispatch({type: 'ADD_NEW_USER_ERROR', error})
        } else {
            const id = response.data.id
            const data = {...formData, id: id}
            dispatch({type: 'ADD_NEW_USER', data})
        }

    }
}
