export const DYNAMIC_SHEET_STRUCTURE = {
    "Demandes": {
        "Liées au contexte familial": [
            {
                "soutien dans le cadre d'un conflit familial": "request_familyContext_conflict"
            },
            {
                "soutien par rapport à une volonté de quitter la maison": "request_familyContext_leavingWill"
            },
            {
                "soutien dans le cadre d'une fugue": "request_familyContext_ranAway"
            },
            {
                "soutien dans le cadre d'une exclusion": "request_familyContext_exclusion"
            },
            {
                "soutien à la parentalité": "request_familyContext_parenthood"
            },
            {
                "autre": "request_familyContext_other"
            }
        ],
        "Liées à un contexte institutionnel": [
            {
                "soutien dans le cadre d'un conflit": "request_institutionalContext_conflict"
            },
            {
                "soutien par rapport à une volonté de quitter l'institution": "request_institutionalContext_leavingWill"
            },
            {
                "soutien dans le cadre d'une fugue": "request_institutionalContext_ranAway"
            },
            {
                "soutien dans le cadre d'un renvoi": "request_institutionalContext_fired"
            },
            {
                "contact/démarche/accomp. vis-à-vis d'une institution": "request_institutionalContext_approach"
            },
            {
                "autre": "request_institutionalContext_other"
            }
        ],
        "Liées à l’hébergement/logement": [
            {
                "trouver un hébergement d'urgence": "request_accommodation_urgency"
            },
            {
                "trouver un logement en institution": "request_accommodation_institution"
            },
            {
                "trouver un logement autonome": "request_accommodation_autonomous"
            },
            {
                "mise en place d'une mise en autonomie": "request_accommodation_autonomySetting"
            },
            {
                "accompagnement dans le cadre d'un logement autonome": "request_accommodation_accompaniment"
            },
            {
                "autre": "request_accommodation_other"
            }
        ],
        "Liées à des besoins primaires": [
            {
                "manger": "request_primaryNeeds_eat"
            },
            {
                "douche": "request_primaryNeeds_shower"
            },
            {
                "se reposer": "request_primaryNeeds_rest"
            },
            {
                "lessive": "request_primaryNeeds_laundry"
            }
        ],
        "Liées à un contexte psycho-médico-affectif ": [
            {
                "demande de pouvoir parler à un adulte": "request_psycho_adultTalking"
            },
            {
                "aide par rapport à un contexte de violence physique/psy": "request_psycho_violence"
            },
            {
                "soutien par rapport à une souffrance psychologique": "request_psycho_suffering"
            },
            {
                "soutien par rapport à une consommation": "request_psycho_consumption"
            },
            {
                "soutien dans le cadre d'une grossesse": "request_psycho_pregnancy"
            },
            {
                "soins médicaux/médicaments": "request_psycho_healthCare"
            },
            {
                "autre": "request_psycho_other"
            }
        ],
        "Demande liées au droit des étrangers": [
            {
                "infos par rapport aux procédures": "request_foreignersRights_process"
            },
            {
                "demande de faire un signalement": "request_foreignersRights_signal"
            },
            {
                "demande d'aide pour l'obtention d'un statut légal en Belgique": "request_foreignersRights_legalStatus"
            },
            {
                "demande d'aide pour une retour volontaire": "request_foreignersRights_return"
            },
            {
                "autre": "request_foreignersRights_other"
            }
        ],
        "Demandes adressées par la famille ou les proches": [
            {
                "demande d'informations": "request_family_infos"
            },
            {
                "demande de soutien dans le cadre d'une difficulté relationnelle avec le jeune": "request_family_relationalDifficulties"
            },
            {
                "demande de soutien par rapport à une difficulté vécue par le jeune": "request_family_lifeDifficulties"
            },
            {
                "demande d'hébergement pour le jeune": "request_family_accommodation"
            },
            {
                "demande de pouvoir parler à un intervenant": "request_family_speakerTalking"
            },
            {
                "autre": "request_family_other"
            }
        ],
        "Autres demandes": [
            {
                "demandes liées à des difficultés scolaires": "request_other_schoolDifficulties"
            },
            {
                "soutien dans le passage à la majorité": "request_other_adultBecomming"
            },
            {
                "demande d'aide par rapport à des questions admin et/ou juridiques": "request_other_admin"
            },
            {
                "recherche de job": "request_other_job"
            },
            {
                "recherche de loisir": "request_other_hobby"
            },
            {
                "autre": "request_other_other"
            }
        ]
    },
    "Contextes": {
        "Contexte familial de vie": [
            {
                "conflit sans qu'il y ait rupture": "context_family_conflict"
            },
            {
                "fugue": "context_family_ranAway"
            },
            {
                "exclusion": "context_family_exclusion"
            }
        ],
        "Contexte institutionnel de vie": [
            {
                "conflit sans qu'il y ait rupture": "context_institutional_rupture"
            },
            {
                "fugue": "context_institutional_ranAway"
            },
            {
                "time-out": "context_institutional_timeOut"
            },
            {
                "renvoi": "context_institutional_fired"
            }
        ],
        "Autres contextes de vie": [
            {
                "rue/errance": "context_other_homeless"
            },
            {
                "isolé/kot/appartement avec suivit": "context_other_monitored"
            },
            {
                "isolé/kot/appartement sans suivit": "context_other_unmonitored"
            }
        ],
        "Contexte psycho-médico-affectif": [
            {
                "violence psy et/ou physique intra familial": "context_psycho_intoFamily"
            },
            {
                "violence psy et/ou physique extra familial": "context_psycho_nonFamily"
            },
            {
                "consommation": "context_psycho_consumption"
            },
            {
                "abus sexuels/attouchements": "context_psycho_sexualAbuse"
            },
            {
                "parentalité (jeune avec enfant)": "context_psycho_parenthood"
            },
            {
                "grossesse": "context_psycho_pregnancy"
            },
            {
                "souffrance psychologique": "context_psycho_suffering"
            },
            {
                "comportements sexuels à risque": "context_psycho_sexualBehaviour"
            },
            {
                "automutilations/idées suicidaires": "context_psycho_selfDestruction"
            }
        ],
        "Scolarité": [
            {
                "Difficultés scolaire": "context_schooling_difficulties"
            }
        ],
        "Contexte administratif et économique": [
            {
                "sans papiers": "context_admin_paperless"
            },
            {
                "pas en ordre de C.I./ mutuelle/ domicile...": "context_admin_mutuelle"
            },
            {
                "Majeur sans aucun revenu": "context_admin_noIncome"
            }
        ],
        "Contexte lié à la prise en charge institutionnelle": [
            {
                "Démobilisation des intervenants saj/cbj": "context_institutionalCare_saj"
            },
            {
                "Démobilisation des intervenants spj/tribunal/sociale dienst": "context_institutionalCare_spj"
            },
            {
                "Démobilisation des intervenants service des tutelles/fédasil": "context_institutionalCare_tutelles"
            },
            {
                "Démobilisation des intervenants cpas/ocmw": "context_institutionalCare_cpas"
            },
            {
                "manque de place en institution réseau fedasil": "context_institutionalCare_fedasil"
            },
            {
                "manque de place en institution aaj": "context_institutionalCare_aaj"
            },
            {
                "manque de place en institution santé mentale": "context_institutionalCare_mentalHealth"
            },
            {
                "manque de place en institution pour adultes (maisons d'accueil)": "context_institutionalCare_adult"
            },
            {
                "placements successifs de centre en centre": "context_institutionalCare_manyCenters"
            },
            {
                "manque d'adhésion du jeune": "context_institutionalCare_commitmentLack"
            }
        ]
    },
    "Travail effectué": {
        "Travail en lien avec le contexte de vie": [
            {
                "Entretien avec le jeune seul": "works_life_alone"
            },
            {
                "Entretien avec un/des membres de la famille": "works_life_family"
            },
            {
                "Médiations/entretiens jeune + famille/réseau": "works_life_network"
            },
            {
                "Entretien avec l’institution de vie": "works_life_institution"
            }
        ],
        "Contacts/démarches/accompagnement vis-à-vis de nouvelles institutions": [
            {
                "école/pms/internat scolaire": "works_approach_school"
            },
            {
                "abaka": "works_approach_abaka"
            },
            {
                "point jaune": "works_approach_yellow_point"
            },
            {
                "service droit des jeunes": "works_approach_youngsRights"
            },
            {
                "autre amo": "works_approach_amo"
            },
            {
                "saj/cbj": "works_approach_saj"
            },
            {
                "spj/tribunal/sociale dienst": "works_approach_spj"
            },
            {
                "serv résidentiels de l'aaj/imp-srj": "works_approach_aaj"
            },
            {
                "ser tutelle/réseau fédasil/tuteurs/O.E.": "works_approach_tutelle"
            },
            {
                "service de santé mentale": "works_approach_mentalHealth"
            },
            {
                "hôpitaux psychiatriques": "works_approach_asylum"
            },
            {
                "centres de jours": "works_approach_dayCenter"
            },
            {
                "sos enfants": "works_approach_sosChildren"
            },
            {
                "cpas/ocmw": "works_approach_cpas"
            },
            {
                "service social pour adultes": "works_approach_adults"
            },
            {
                "avocats": "works_approach_lawyer"
            },
            {
                "services de mise en autonomie (kap/@home/H&R)": "works_approach_autonomy"
            }
        ],
        "Lié à l’hébergement/au logement mineur": [
            {
                "rech d'un hébergement d'urgence": "works_accommodationMinor_urgency"
            },
            {
                "recherche d'un logement en institution": "works_accommodationMinor_institutional"
            },
            {
                "recherche d'un logement autonome": "works_accommodationMinor_autonomous"
            },
            {
                "accompagnement dans le cadre d'un logement autonome": "works_accommodationMinor_support"
            },
            {
                "hébergement à sos jeunes": "works_accommodationMinor_sosJeunes"
            },
            {
                "autre": "works_accommodationMinor_other"
            }
        ],
        "Lié à l’hébergement/au logement majeur": [
            {
                "rech d'un hébergement d'urgence": "works_accommodationMajor_urgency"
            },
            {
                "recherche d'un logement en institution": "works_accommodationMajor_institutional"
            },
            {
                "recherche d'un logement autonome": "works_accommodationMajor_autonomous"
            },
            {
                "accompagnement dans le cadre d'un logement autonome": "works_accommodationMajor_support"
            },
            {
                "hébergement à sos jeunes": "works_accommodationMajorr_sosJeunes"
            },
            {
                "autre": "works_accommodationMajor_other"
            }
        ],
        "Lié à la scolarité": [
            {
                "recherche école- inscription- nouvelles options/formations": "works_schooling_signup"
            },
            {
                "recours par rapport à des résultats": "works_schooling_results"
            },
            {
                "recours par rapport à une exclusion": "works_schooling_exclusion"
            },
            {
                "soutin par rapport au travail scolaire aide aux devoir sur place": "works_schooling_homework"
            },
            {
                "soutien par rapport au travail scolaire: mise en lien avec le réseau d'aide aux devoirs": "works_schooling_network"
            },
            {
                "soutien par rapport au travail scolaire:  recherche de cours d'alphabétisation": "works_schooling_alphabet"
            },
            {
                "soutien par rapport au travail scolaire: autre": "works_schooling_otherSchool"
            },
            {
                "autre": "works_schooling_other"
            }
        ],
        "Réponse aux besoins primaires": [
            {
                "médecin": "works_primaryNeeds_doctor"
            },
            {
                "achat de médicaments": "works_primaryNeeds_medicinesBuying"
            },
            {
                "recherche de lieux qui répondent aux besoins primaires": "works_primaryNeeds_places"
            },
            {
                "autre": "works_primaryNeeds_other"
            }
        ],
        "Hors hébergement": [
            {
                "manger sur place": "works_nonAccommodation_eating"
            },
            {
                "colis alimentaire": "works_nonAccommodation_package"
            },
            {
                "douche": "works_nonAccommodation_shower"
            },
            {
                "lessive": "works_nonAccommodation_laundry"
            }
        ],
        "Lié au droit des étrangers": [
            {
                "aide par rapport à  la prise en charge par les services compétents (serv des tut/fedasil/oe)": "works_foreignersRights_services"
            },
            {
                "aide par rapport à des difficultés avec le tuteur": "works_foreignersRights_difficulties"
            },
            {
                "aide pour l'obtention d'un statut légal": "works_foreignersRights_legal"
            },
            {
                "autre": "works_foreignersRights_other"
            }
        ],
        "Lié au demandes psycho-médico-affectives": [
            {
                "accompagnement vers services en rapport avec les questions de grossesses": "works_psycho_pregnancy"
            },
            {
                "soutien par rapport aux dépendances-assuétudes": "works_psycho_dependency"
            },
            {
                "autre": "works_psycho_other"
            }
        ],
        "Lié à la recherche d’un job": [
            {
                "aide à la recherche (rech, cv,..) d'un contrat d'apprentissage": "works_job_learningContract"
            },
            {
                "aide à la recherche (rech, cv,..) d'un job d'étudiant": "works_job_studentJob"
            },
            {
                "aide à la recherche (rech, cv,..) d'un travail": "works_job_job"
            },
            {
                "orientation vers des serv. spécialisés": "works_job_services"
            },
            {
                "aide admin par rapport à l'onem/actiris/etc": "works_job_onem"
            },
            {
                "autre": "works_job_other"
            }
        ],
        "Lié au travail d’interpellation": [
            {
                "saj/cbj": "works_outreach_saj"
            },
            {
                "spj/tribunal/sociale dienst": "works_outreach_spj"
            },
            {
                "service des tutelles/ fedasil/tuteurs": "works_outreach_tutelle"
            },
            {
                "cpas/ocmw": "works_outreach_cpas"
            },
            {
                "autre": "works_outreach_other"
            }
        ],
        "Lié à l’aide admin et/ou juridique": [
            {
                "aide par rapport à l'obtention du cpas": "works_admin_cpas"
            },
            {
                "aide par rapport à a mise en ordre de doc administratifs (ci - mutuelle - alloc fam - etc)": "works_admin_admin"
            }
        ],
        "Loisirs et activités": [
            {
                "Recherche de loisirs-activités": "works_hobby_search"
            }
        ]
    }
};
