const initState = {
    consignes: [],
    currentProfileConsigne: null
}

const consignesReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_CONSIGNES' :
            if(action.data) return {...state, consignes: action.data}
            return state
        case 'SAVE_NEW_CONSIGNE':
            return {...state, currentProfileConsigne: action.data}
        case 'UPDATE_CONSIGNE':
            return {...state, currentProfileConsigne: action.data}
        case 'GET_CONSIGNES_ERROR' :
            return state
        case 'GET_CONSIGNE_BY_ID':
              return {...state, currentProfileConsigne: action.data}
        case 'DELETE_CONSIGNE':
            return {...state, currentProfileConsigne: null}
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export default consignesReducer