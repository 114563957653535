import React from 'react'
import styled from "styled-components"

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    margin: 40px 0;
`

const Loading = () => {
    return (
        <StyledDiv>
            <div className="preloader-wrapper active">
                <div className="spinner-layer">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>
            </div>
        </StyledDiv>
    )
}

export default Loading