import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import {  cleanCurrentProfile } from "../../store/actions/profiles.actions"
import styled from 'styled-components'
import Loading from "../alerts/Loading"
import moment from "moment"
import {
    ACCOMMODATION_DENIED_REASONS
} from '../../constants/lookups.constants'
import Lookup from "../forms_components/Lookup";
import {getIntermediatesLookup, getSpecificStatusesLookup, getUsers} from "../../store/actions/extra_data.actions";
import { saveNewAccommodation, updateAccommodation } from '../../store/actions/accommodations.actions'

const Background = styled.div`
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
`

const Container = styled.div`
    position: relative;
    width: 680px;
    height: 696px;
    margin-top: 80px;
    padding: 0;
`

const ClosingIcon = styled.div`
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
    background: white;
    color: #424242;
    border-radius: 50px;
    &:hover {
        color: red;
    }
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalHeader = styled.div`
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    background: #424242;
    color: white;
    padding: 0 16px;
`

const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
`

const FormContainer = styled.div`
    padding: 16px;
`

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`

const LongInput = styled.div `
    width: 50%
`

const RequestStatus = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const RadioLabel = styled.span`
    position: relative;
    padding-left: 24px !important;
    padding-right: 24px !important;
`
const Label = styled.p`
    margin: 10px 0;
`

const SaveButton = styled.button`
    position: absolute;
    bottom: 16px;
    left: 16px;
`

const CheckBoxContainer = styled.div`
    position: relative;
    top: 32px;
    color: black !important;
`

const AccommodationRequest = (
    {
        close,
        profile,
        cleanProfile,
        intermediates,
        getIntermediatesLookup,
        specificStatuses,
        getSpecificStatusesLookup,
        users,
        getUsers,
        visit,
        setCurrentVisit,
        saveNewAccommodation,
        currentAccommodation,
        updateAccommodation
    }
    ) => {
    useEffect(() => {
        if(!users.length) getUsers()
        if(!intermediates.length) getIntermediatesLookup()
        if(!specificStatuses.length) getSpecificStatusesLookup()
        return () => cleanProfile()
    }, [])
    const [accommodationDenied, setDenied] = useState(currentAccommodation ? currentAccommodation.request_status === 3 : false)

    const[formData, setFormData]= useState({
        young_id: visit.young_id,
        visit_id: visit.id,
        intermediate:currentAccommodation ? currentAccommodation.intermediate : profile && profile.intermediate ? profile.intermediate : '',
        intermediary_contact: currentAccommodation ? currentAccommodation.intermediary_contact :  '',
        request_status : currentAccommodation ? currentAccommodation.request_status :   '1',
        accommodation_denied_reason: currentAccommodation ? currentAccommodation.accommodation_denied_reason :   '',
        responsible_is_aware: currentAccommodation && currentAccommodation.responsible_is_aware === 1,
        user_id: currentAccommodation ? currentAccommodation.user_id : ''
    })

    const [lookupValues, setLookupValues] = useState({
        intermediate: formData.intermediate,
        accommodation_denied_reason: formData.accommodation_denied_reason,
        user_id: formData.user_id
    })

    const updateUserId = e =>  {
        const value = e.target.value
        setLookupValues({...lookupValues, user_id:  value})
        setFormData({...formData, user_id: value})
    }

    const updateIntermediate = e => {
        const value = e.target.value
        setLookupValues({...lookupValues, intermediate:  value})
        setFormData({...formData, intermediate: value})
    }

    const updateAccommodationDeniedReason = e => {
        const value = e.target.value
        setLookupValues({...lookupValues, accommodation_denied_reason:  value})
        setFormData({...formData, accommodation_denied_reason: value})
    }

    const handleChange = e => {
        if(e.target.id === 'request_status') setDenied(e.target.value === '3')
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const save = async() => {
        if(!currentAccommodation) {
            visit.visit_reason = 2
            visit.accommodation_request = 1
            await saveNewAccommodation(visit, formData)
        } else {
            formData.id = currentAccommodation.id
            await  updateAccommodation(formData)
        }
        close()
        setCurrentVisit(null)
    }




    const getAge = () => {
        const today = moment()
        const birthDate = moment(profile.birth_date)
        return today.diff(birthDate, 'year')
    }

    const handleResponsibleIsAware = e => {
        formData.responsible_is_aware = e.target.checked
    }

    return (
        <Background>
            <Container accomodationDenied={accommodationDenied} className="card-panel">
                <ClosingIcon onClick={close}><i className="material-icons">close</i></ClosingIcon>
                {
                    (
                        profile && intermediates.length && specificStatuses.length &&
                        <>
                            <ModalHeader>
                                <InfoContainer>
                                    <p>{profile.first_name} {profile.last_name}</p>
                                    <p>Sexe: {profile.gender}</p>
                                    <p>Age: {getAge()} ans</p>
                                    <p>MENA: {profile.specific_status === 2 ? 'oui'  : 'non'}</p>
                                </InfoContainer>
                            </ModalHeader>
                            <FormContainer>
                                <Flex className='card-panel'>
                                    <Lookup
                                        data={intermediates}
                                        dataName="type"
                                        dataValue="id"
                                        label="Intermédiaire"
                                        updateFunction={updateIntermediate}
                                        value={lookupValues.intermediate}
                                    />
                                </Flex>
                                <Flex className='card-panel'>
                                    <LongInput className='input-field'>
                                        <label className={`${formData.intermediary_contact ? "active" : ""} grey-text text-darken-4`} htmlFor="intermediary_contact">Coordonnées de l’intermédiaire</label>
                                        <input value={formData.intermediary_contact} type="text" id="intermediary_contact"  onChange={handleChange} />
                                    </LongInput>
                                    <CheckBoxContainer>
                                        <label>
                                            <input type="checkbox" defaultChecked={formData.responsible_is_aware} onChange={handleResponsibleIsAware} />
                                            <span>Responsable prévenu ?</span>
                                        </label>
                                    </CheckBoxContainer>
                                </Flex>
                                <div className='card-panel'>
                                    <Label> Statut de l'hébergement</Label>
                                    <Flex>
                                        <RequestStatus>
                                            <div>
                                                <label>
                                                    <input name='request_status' id='request_status' type="radio" onChange={handleChange} value='1' checked={formData.request_status == '1'}/>
                                                    <RadioLabel>En attente</RadioLabel>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input name='request_status' id='request_status' type="radio" onChange={handleChange} value='2' checked={formData.request_status == '2'}/>
                                                    <RadioLabel>Acceptée</RadioLabel>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input name='request_status' id='request_status' type="radio" onChange={handleChange} value='3' checked={formData.request_status == '3'}/>
                                                    <RadioLabel>Refusée</RadioLabel>
                                                </label>
                                            </div>
                                        </RequestStatus>
                                        <Lookup
                                            data={users}
                                            dataName="pseudo"
                                            dataValue="id"
                                            label="Permanent"
                                            updateFunction={updateUserId}
                                            value={lookupValues.user_id}
                                        />
                                    </Flex>
                                </div>
                                {
                                    accommodationDenied &&
                                    <Flex className="card-panel">
                                        <Lookup
                                            data={ACCOMMODATION_DENIED_REASONS}
                                            dataName="type"
                                            dataValue="id"
                                            label="Raison du refus"
                                            updateFunction={updateAccommodationDeniedReason}
                                            value={lookupValues.accommodation_denied_reason}
                                        />
                                    </Flex>
                                }

                            </FormContainer>
                            {
                                formData.user_id && formData.intermediate
                                && <SaveButton  className='btn green darken-1' onClick={save} >Enregistrer</SaveButton>
                            }

                        </>
                    )
                    ||
                    <>
                        <ModalHeader>
                            <InfoContainer>
                                <p>Chargement...</p>
                            </InfoContainer>
                        </ModalHeader>
                        <Loading/>
                    </>
                }
            </Container>
        </Background>
    )
}

const mapStateToProps = state => {
    return {
        newVisitsId: state.events.newVisitsId,
        profile: state.profiles.currentProfile,
        intermediates: state.extraData.intermediates,
        specificStatuses: state.extraData.specificStatuses,
        users: state.extraData.users,
        currentAccommodation: state.accommodations.currentAccommodation
    }
}

const mapDispatchToProps = dispatch => {
    return {
        cleanProfile: () => dispatch( cleanCurrentProfile() ),
        getIntermediatesLookup : () => dispatch( getIntermediatesLookup() ),
        getSpecificStatusesLookup: () => dispatch( getSpecificStatusesLookup() ),
        getUsers: () => dispatch( getUsers() ),
        saveNewAccommodation: (visit, formData) => dispatch( saveNewAccommodation(visit, formData) ),
        updateAccommodation: formData => dispatch (updateAccommodation(formData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationRequest)
