import React from 'react'
import styled from 'styled-components'

const Background = styled.div`
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
`

const Message = styled.div`
    width: 480px;
    height: 200px;
    margin-top: 120px;
`


const Button = styled.button `
    background-color: ${({warning}) => warning ? 'crimson' : 'orange'};
    margin-right: 16px;
    margin-bottom: ${({vertical}) => vertical ? '8px' : '0'}
`


const Modal = ({message, choices}) => {
    return (
        <Background>
            <Message className="card">
                <div className="card-content">
                    <span className="card-title">{message.title}</span>
                    <p>{message.text}</p>
                </div>
                <div className="card-action">
                    {
                        choices.map((choice, index) => {
                                if(choices.length < 4)
                                    return (
                                        <Button  vertical={choices.length >= 4} warning={choice.warning} key={index} className="btn" onClick={choice.f}>{choice.text}</Button>
                                    )
                                else
                                    return (
                                        <div>
                                            <Button vertical={choices.length >= 4} warning={choice.warning} key={index} className="btn" onClick={choice.f}>{choice.text}</Button>
                                        </div>
                                )
                            }
                        )
                    }
                </div>
            </Message>
        </Background>
    )
}

export default Modal
