import axios from "axios"
import moment from 'moment'
import {
    GET_COUNTRIES_URL,
    GET_RESIDENCES_URL,
    GET_ARRIVALS_URL,
    GET_INTERMEDIATES_URL,
    GET_LIVING_PLACES_URL,
    GET_SPECIFIC_STATUSES_URL,
    GET_LEGAL_RESPONSIBLES_URL,
    GET_PARENT_LIVING_STATUS_URL,
    GET_EDUCATION_TYPES_URL,
    GET_SCHOOL_CHOICE_REASONS_URL,
    GET_LEAVING_SCHOOL_REASONS_URL,
    GET_STUDIES_LEVEL_URL,
    GET_USERS,
    GET_EXCEEDING_CAUSES_URL,
    GET_VISIT_OBJECTS,
    GET_STATS
} from '../../constants/urls.constants'

const request = (url, successAction, errorAction) => {
    return async dispatch => {
        const response = await axios.get(url,{headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => {
                console.log(error)
                dispatch({type: errorAction, error})
            })
        const data = response.data
        dispatch({type: successAction, data})
    }
}

export const getStats = () => {
    const year = moment().format('YYYY')
    return request(`${GET_STATS}/${year}-01-01`, 'GET_STATS', 'GET_STATS_ERROR')
}

export const getVisitObjects = () => {
    return request(GET_VISIT_OBJECTS, 'GET_VISIT_OBJECTS', 'GET_VISIT_OBJECTS_ERROR')
}

export const getExceedingCauses = () => {
    return request(GET_EXCEEDING_CAUSES_URL,'GET_EXCEEDING_CAUSES',  'GET_EXCEEDING_CAUSES_ERROR')
}

export const getCountriesLookup = () => {
    return request(GET_COUNTRIES_URL,'GET_COUNTRIES',  'GET_COUNTRIES_ERROR')
}

export const getResidencesLookup = () => {
    return request(GET_RESIDENCES_URL,'GET_RESIDENCES',  'GET_RESIDENCES_ERROR')
}

export const getArrivalsLookup = () => {
    return request(GET_ARRIVALS_URL,'GET_ARRIVALS',  'GET_ARRIVALS_ERROR')
}

export const getIntermediatesLookup = () => {
    return request(GET_INTERMEDIATES_URL,'GET_INTERMEDIATES',  'GET_INTERMEDIATES_ERROR')
}

export const getLivingPlacesLookup = () => {
    return request(GET_LIVING_PLACES_URL,'GET_LIVING_PLACES',  'GET_LIVING_PLACES_ERROR')
}

export const getSpecificStatusesLookup = () => {
    return request(GET_SPECIFIC_STATUSES_URL,'GET_SPECIFIC_STATUSES',  'GET_SPECIFIC_STATUSES_ERROR')
}

export const getLegalResponsiblesLookup = () => {
    return request(GET_LEGAL_RESPONSIBLES_URL,'GET_LEGAL_RESPONSIBLES',  'GET_LEGAL_RESPONSIBLES_ERROR')
}

export const getParentLivingStatusesLookup = () => {
    return request(GET_PARENT_LIVING_STATUS_URL,'GET_PARENT_LIVING_STATUS',  'GET_PARENT_LIVING_STATUS_ERROR')
}

export const getEducationTypesLookup = () => {
    return request(GET_EDUCATION_TYPES_URL,'GET_EDUCATION_TYPES',  'GET_EDUCATION_TYPES_ERROR')
}

export const getSchoolChoiceReasonsLookup = () => {
    return request(GET_SCHOOL_CHOICE_REASONS_URL,'GET_SCHOOL_CHOICE_REASONS',  'GET_SCHOOL_CHOICE_REASONS_ERROR')
}

export const getLeavingSchoolReasonsLookup = () => {
    return request(GET_LEAVING_SCHOOL_REASONS_URL,'GET_LEAVING_SCHOOL_REASONS',  'GET_LEAVING_SCHOOL_REASONS_ERROR')
}

export const getStudiesLevelLookup = () => {
    return request(GET_STUDIES_LEVEL_URL,'GET_STUDIES_LEVEL',  'GET_STUDIES_LEVEL_ERROR')
}

export const getUsers = () => {
    return request(GET_USERS,'GET_USERS',  'GET_USERS_ERROR')
}
