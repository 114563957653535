import React from "react"
import styled from "styled-components"

const CardContainer = styled.div`
    width: 49%;
    margin: 0;
    padding: 0 16px;
`

const PinkTitle = styled.h5`
    color: #EC407A !important;
`

const CardInfo = ({title, infos, links}) => {

    return(
        <CardContainer>
            <PinkTitle>{title}</PinkTitle>
            {
                infos &&
                infos.map(info => !info.hidden ? <p key={info.label}>{info.label}: <span className="info-value">{info.value}</span></p> : <></>)
            }
        </CardContainer>
    )
}

export default CardInfo
