import React from "react";
import styled from 'styled-components'


const StyledDiv = styled.div`
    display: flex;
`

const Error = ({error, cleaningFunction}) => {
    return(
        <StyledDiv onClick={cleaningFunction} className="card-panel red white-text">{error}</StyledDiv>
    )
}

export default Error