import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { addEvent } from "../../store/actions/events.actions"


const Background = styled.div`
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
`

const Container = styled.div`
    position: relative;
    width: 480px;
    height: 240px;
    margin-top: 120px;
`

const ListContainer = styled.div`
    height: 440px;
    overflow: auto;
`

const ClosingIcon = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
`

const ManualEntry = ({close, addEvent, history}) => {

    const [formData, setFormData]= useState({first_name: '', last_name: ''})

    const handleChange = e => setFormData({ ...formData, [e.target.id]: e.target.value })

    const selectProfile = () => {
        if(formData.first_name || formData.last_name) {
            addEvent(formData)
            close()
        }
    }

    return (
        <Background>
            <Container className="card-panel">
                <ClosingIcon onClick={close}><i className="material-icons">close</i></ClosingIcon>
                <form autoComplete="off" >
                    <div className="col s2 input-field">
                        <label className="grey-text text-darken-4" htmlFor="last_name">Prénom</label>
                        <input  type="text" required className="validate" name="last_name" id="last_name" onChange={handleChange} />
                    </div>
                    <div className="col s2 input-field">
                        <label className="grey-text text-darken-4" htmlFor="first_name">Nom</label>
                        <input type="text" required className="validate" name="last_name" id="first_name" onChange={handleChange} />
                    </div>

                    <button onClick={selectProfile} className="btn pink lighten-1" >Ok</button>
                </form>
            </Container>
        </Background>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        addEvent: profile => dispatch(addEvent(profile))
    }
}

export default connect(null, mapDispatchToProps)(ManualEntry)