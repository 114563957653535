import React, { useState, useEffect } from 'react'
import SignInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import { connect } from 'react-redux'
import { Navbar, Icon } from 'react-materialize'
import styled from 'styled-components'
import logo from '../../assets/logo.png'
import QuickSearch from "../utilities/QuickSearch";
import {getProfilesByFilter} from "../../store/actions/profiles.actions"
import ProfileChooser from "../profiles/ProfileChooser"


const NavContainer = styled.div`
    position: fixed;
    width: 100%;
    z-index: 200;
    top: 0;
    min-height: 56px;
    height: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
`

const MyNavBar = styled(Navbar)`
    border: none !important;
    box-shadow: 0 0 0 0;
`
const Logo = styled.img`
    height: 100%;
`

const NavBar = ({auth, getProfilesByFilter, profiles}) => {
    const [searchDisplayed, displaySearch] = useState(false)
    const [chooserDisplayed, displayChooser] = useState(false)

    if(!auth.loggedIn) return (<></>)

    const search = async filter => {
        if(filter.length > 0) {
            await getProfilesByFilter(filter)
            displayChooser(true)
        }
    }

    const close = () => {
        displayChooser(false)
        displaySearch(false)
    }

    const links = auth.loggedIn ? <SignInLinks toggleSearch={displaySearch} /> : <SignedOutLinks />

    return (
        <>
            {
                chooserDisplayed && <ProfileChooser profiles={profiles} close={close} search={search} />
            }
            {
                (
                    !searchDisplayed &&
                    <NavContainer className="fixed grey darken-3">
                        <MyNavBar
                            className="container grey darken-3"
                            alignLinks="right"
                            brand={<Logo src={logo}  href="#" alt="logo"/>}
                            menuIcon={<Icon>menu</Icon>}
                            options={{
                                draggable: true,
                                edge: 'left',
                                fixed: true,
                                inDuration: 250,
                                outDuration: 200,
                                preventScrolling: true
                            }}
                        >
                            { links }
                        </MyNavBar>
                    </NavContainer>
                )
                ||
                <NavContainer className="fixed grey darken-3">
                    <div className="container grey darken-3">
                        <QuickSearch search={search} close={close}/>
                    </div>
                </NavContainer>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return{
        auth: state.auth,
        profiles: state.profiles.profilesByFilter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProfilesByFilter: filter => dispatch( getProfilesByFilter(filter) ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)