import axios from "axios"
import {
    GET_CONSIGNES,
    GET_CONSIGNE_BY_YOUNG_ID,
    GET_CONSIGNE_BY_ID,
    DELETE_CONSIGNE,
    SAVE_NEW_CONSIGNE,
    UPDATE_CONSIGNE
} from '../../constants/urls.constants'

export const getConsignes = () => {
    return async dispatch => {
        const response = await axios.get(GET_CONSIGNES,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_CONSIGNES_ERROR', error }))
        const data = response.data
        dispatch({type: 'GET_CONSIGNES', data})
    }
}

export const getConsigneById = id => {
    return async dispatch => {
        const response = await axios.get(`${GET_CONSIGNE_BY_YOUNG_ID}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_CONSIGNES_BY_ID_ERROR', error }))
        const data = response.data[0]
        dispatch({type: 'GET_CONSIGNE_BY_ID', data})
    }
}

export const deleteConsigne = id => {
    return async dispatch => {
        const response = await axios.get(`${DELETE_CONSIGNE}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'DELETE_CONSIGNE_ERROR', error }))
        if(response.data.success) dispatch({type: 'DELETE_CONSIGNE'})
    }
}

export const updateConsigne = formData => {
    delete formData.pseudo

    return async dispatch => {
        const response = await axios.put(
            `${UPDATE_CONSIGNE}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({type: 'UPDATE_CONSIGNE_ERROR', error}))

        console.log(formData.id)


        const response2 = await axios.get(`${GET_CONSIGNE_BY_ID}/${formData.id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_CONSIGNES_BY_ID_ERROR', error }))

        console.log(response2.data)


        if(response2.data){
            const data = response2.data[0]
            dispatch({type: 'UPDATE_CONSIGNE', data})
        }
    }
}

export const cleanConsigne = () => {
    return async dispatch => {
        dispatch({type: 'DELETE_CONSIGNE'})
    }
}

export const saveNewConsigne = formData => {
    return async dispatch => {
        delete formData.pseudo
        const response = await axios.post(
            `${SAVE_NEW_CONSIGNE}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({type: 'SAVE_NEW_CONSIGNE_ERROR', error}))

        const id = response.data.id
        const response2 = await axios.get(`${GET_CONSIGNE_BY_ID}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_CONSIGNES_BY_ID_ERROR', error }))

        if (response2.data){
            const data = response2.data[0]
            dispatch({type: 'SAVE_NEW_CONSIGNE', data})
        }
    }
}