import React, {useState} from 'react'
import styled from 'styled-components'

const Background = styled.div`
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
`

const Message = styled.div`
    width: 480px;
    height: 200px;
    margin-top: 120px;
`


const Button = styled.button `
    background-color: ${({warning}) => warning ? 'crimson' : 'orange'};
    margin-right: 16px;
`

const CloseAccommodationModal = ({closeModal, closeAccommodation}) => {
    const [destination, setDestination] = useState('')
    const handleChange = e => setDestination(e.target.value)

    return (
        <Background>
            <Message className="card">
                <div className="card-content">
                    <span className="card-title">Hébergement en cours</span>
                    <p>Clôturer l'hébergement ?</p>
                    <div className="input-field">
                        <input id='destination' value={destination} onChange={handleChange} type="text"/>
                        <label htmlFor="destination">Destination</label>
                    </div>
                </div>
                <div className="card-action">
                    <Button warning={false} disabled={destination.length < 3} className="btn" onClick={() => closeAccommodation(destination)}>Confirmer</Button>
                    <Button warning={true}  className="btn" onClick={closeModal} >Annuler</Button>
                </div>
            </Message>
        </Background>
    )
}

export default CloseAccommodationModal