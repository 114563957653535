import React, {useState} from 'react'
import styled from "styled-components"
import { connect } from "react-redux"

const ButtonContainer = styled.div`
    width: 320px;
    display: flex;
    justify-content: space-between;
`

const ButtonText = styled.span`
    font-size: 0.8rem;
    position: relative;
    bottom: 6px;
    margin: 0, 5px;
`

const Container = styled.div`
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
`

const Sphere = styled.div`
    height: 30px;
    width: 30px;
    background: ${({currentStep, n}) => currentStep === n ? '#EC407A' : '#424242'};
    border-radius: 50px;
    z-index: 15;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: background .3s;
`

const IndexContainer = styled.span`
    color: white;
    position: relative;
    top: 3px;
`


const Line = styled.div`
    position: absolute;
    top: 13px;
    height: 3px;
    width: 99%;
    background: #424242;
    z-index: 1;
`

const StepInfos = styled.div`
    position: absolute
    top: 40px;
    color: white;
    background: #424242;
`

const MultiStep = ({elements}) => {
    const [step, setStep] = useState(0)
    const [currentElement, setCurrentElement] = useState(elements[0].element)
    const [infosDisplayed, setInfosDisplayed] = useState(-1)

    const nextStep = () => {
        if(step >= elements.length - 1) return
        changeStep(step + 1)
    }

    const previousStep = () => {
        if(step <= 0) return
        changeStep(step - 1)
    }

    const changeStep = currentStep => {
        setStep(currentStep)
        setCurrentElement(elements[currentStep].element)
    }

    return(
        <>
            <Container>
                <Line />
                {
                    elements.map((element, index) => {
                        return (
                            <Sphere
                                onClick={() => changeStep(index)}
                                currentStep={step}  n={index} key={index}
                                onMouseEnter={() => setInfosDisplayed(index)}
                                onMouseLeave={() => setInfosDisplayed(-1)}
                            >
                                <IndexContainer><i className="material-icons">{element.icon}</i></IndexContainer>
                                <StepInfos hidden={infosDisplayed !== index} n={index} key={index} className="card-panel">
                                    {element.name}
                                </StepInfos>
                            </Sphere>
                        )
                    })
                }
            </Container>
            {currentElement}
            <ButtonContainer>
                <button className='btn pink lighten-1' disabled={step === 0} onClick={previousStep} >
                    <i className="large material-icons">arrow_back</i>
                    <ButtonText >Étape précédente</ButtonText>
                </button>
                {/*
                    TODO re enable next step available checking</p>
                <button className='btn pink lighten-1 ' disabled={step === elements.length - 1 || !nextStepAvailable}  onClick={nextStep} >
                    <ButtonText >Étape suivante</ButtonText>
                    <i className="large material-icons">arrow_forward</i>
                </button>
                */}
                <button className='btn pink lighten-1' disabled={step === elements.length - 1} onClick={nextStep} >
                    <ButtonText >Étape suivante</ButtonText>
                    <i className="large material-icons">arrow_forward</i>
                </button>
            </ButtonContainer>
        </>
    )
}

const mapStateToProps = state => {
    return {
        nextStepAvailable: state.forms.nextStepAvailable
    }
}

export default connect(mapStateToProps)(MultiStep)