const initState = {
    loggedIn: false,
    user: {}
}

const authReducer= (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN':
            if(action.data){
                localStorage.setItem('access_token', action.data.api_token)
                const loggedUser = {
                    ...action.data
                }
                return { ...state, loggedIn: true , user: loggedUser, errors: {...state.errors, login: false}}
            }
            return state
        case 'LOGOUT':
            localStorage.access_token = null
            return { ...state, loggedIn: false , user: {}}
        case 'SIGNUP':
            if(action.data){
                localStorage.setItem('access_token', action.data.token)
                return { ...state, loggedIn: true , user: action.data.user}
            }
            return state
        case 'SIGNUP_ERROR':
            console.log('signup has failed', action.data.response.status)
            return state
        default:
            return state
    }
}

export default authReducer