const initState = {
    loading: false,
    passwordUpdated: false
}

const alertReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, loading: true }
        case 'LOGOUT': return initState
        case 'PASSWORD_UPDATED': return {...state, passwordUpdated: true}
        default:
            return state
    }
}

export default alertReducer
