import React , {useState, useEffect} from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { DatePicker } from 'react-materialize'
import { Link } from "react-router-dom";

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
`

const DateInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const StyledButton = styled.button`
    margin: 0 20px;
`

const DateInput = styled.input`
    text-align: center !important;
    font-size: 1.5rem !important;
    border: none !important;
`

const DateLabel = styled.p`
    Font-size: 1.6rem;
    color: #EC407A;
`
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 340px;
`

const MyDatePicker = styled(DatePicker)`
    text-align: center !important;
    border: none !important;
    cursor: pointer;
    color: #EC407A !important;
    position: relative;
    top: 3px;
`

const LonguetHeader = ({updateLonguet, selectProfile, manualEntry, displayButtons}) => {
    const today = moment().format('YYYY[-]MM[-]DD')
    const [currentDate, setCurrentDate] = useState(today)
    const [nextDayBtnIsDisabled, disableNextDayBtn] = useState(true)

    const updateDate = (datePicker) => {
        const newDate = moment(datePicker.date).format('YYYY[-]MM[-]DD')
        disableNextDayBtn(newDate === today)
        if(!moment(newDate).isAfter(today)){
            setCurrentDate(newDate)
            updateLonguet(newDate)
        }
        document.querySelector('#date-picker').value = 'Choisir une date'

    }

    const goToday = () => {
        updateLonguet(today)
        setCurrentDate(today)
        disableNextDayBtn(true)
    }

    const changeDay = diff => {
        const newDate = moment(currentDate).add(diff, 'days').format('YYYY[-]MM[-]DD')
        disableNextDayBtn(newDate === today)
        setCurrentDate(newDate)
        updateLonguet(newDate)
    }

    const getFrenchDate = date => {
        const days = {
            Monday: 'Lundi',
            Tuesday: 'Mardi',
            Wednesday: 'Mercredi',
            Thursday: 'Jeudi',
            Friday: 'Vendredi',
            Saturday: 'Samedi',
            Sunday: 'Dimanche'
        }

        return `${days[moment(date).format('dddd')]} ${moment(date).format('DD[/]MM[/]YYYY')}`
    }

    return (
            <HeaderContainer>
                <ButtonContainer>
                {
                    (
                        displayButtons &&
                       <>
                            <button onClick={manualEntry} className="btn pink lighten-1"><i className=" left small material-icons">add</i>Sans profil</button>
                            <button onClick={selectProfile} className="btn pink lighten-1"><i className=" left small material-icons">add</i>Avec profil</button>
                        </>
                    )
                    || <button onClick={goToday} className="btn pink lighten-1">today</button>
                }
                </ButtonContainer>

                <DateInputContainer>
                    <MyDatePicker
                        value='Choisir une date'
                        onChange={() => {}}
                        id='date-picker'
                        options={{
                            format: 'dd mm, yyyy',
                            i18n: {
                                months: [
                                    'Janvier',
                                    'Février',
                                    'Mars',
                                    'Avril',
                                    'Mai',
                                    'Juin',
                                    'Juillet',
                                    'Août',
                                    'Septembre',
                                    'Octobre',
                                    'Novembre',
                                    'Décembre'
                                ],
                                monthsShort: [
                                    'Jan',
                                    'Fév',
                                    'Mar',
                                    'Avr',
                                    'Mai',
                                    'Jui',
                                    'Jul',
                                    'Aou',
                                    'Sep',
                                    'Oct',
                                    'Nov',
                                    'Dec'
                                ],
                                nextMonth: '›',
                                previousMonth: '‹',
                                weekdays: [
                                    'Dimanche',
                                    'Lundi',
                                    'Mardi',
                                    'Mercredi',
                                    'Jeudi',
                                    'Vendredi',
                                    'Samedi'
                                ],
                                weekdaysAbbrev: [
                                    'S',
                                    'M',
                                    'T',
                                    'W',
                                    'T',
                                    'F',
                                    'S'
                                ],
                                weekdaysShort: [
                                    'Dim',
                                    'Lun',
                                    'Mar',
                                    'Mer',
                                    'Jeu',
                                    'Ven',
                                    'Sam'
                                ],
                            },
                            onClose: function() {
                                updateDate(this)
                            }
                        }}
                    />
                    <StyledButton className="btn pink lighten-1" onClick={() => changeDay(-1)}><i className="large material-icons">arrow_back</i></StyledButton>
                    <DateLabel>{getFrenchDate(currentDate)}</DateLabel>
                    <StyledButton className="btn pink lighten-1" disabled={nextDayBtnIsDisabled} onClick={() => changeDay(1)}><i className="large material-icons">arrow_forward</i></StyledButton>
                </DateInputContainer>

            </HeaderContainer>
    )
}

export default LonguetHeader
