import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import styled from "styled-components";
import {getStats} from "../../store/actions/extra_data.actions";
import Loading from "../alerts/Loading";
import moment from "moment"
import {indexOf} from "ramda";

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px;
  align-items: center;
`

const Data = styled.h4`
  color: #ec407a;
`

const Stats = (
    {
        stats,
        getStats,
        loggedIn,
        ...props
    }
) => {
    useEffect(() => {
        if(!loggedIn) props.history.push('/')
        getStats()
    }, [])



    return (
        <div className="container">
            <h2>Statistiques</h2>
            <div className="row">
                {
                    stats &&
                    Object.keys(stats).map(e =>
                        <div className="col s6">
                            <InfoContainer className="card-panel">
                                <h6>{e}: </h6>
                                { (stats && <Data>{stats[e]}</Data>) ||   <Loading/> }
                            </InfoContainer>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        stats: state.extraData.stats,
        loggedIn: state.auth.loggedIn
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getStats: () => dispatch( getStats() )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats)