import React, {useEffect, useState} from "react"
import { connect } from 'react-redux'
import { login, logout } from '../../store/actions/auth.actions'
import { cleanLoginError } from "../../store/actions/errors.actions"
import Error from "../alerts/Error"
import Loading from "../alerts/Loading"


const Login = (
    {
        auth,
        login,
        hasError,
        errorMessage,
        cleanLoginError,
        ...props
    }
) => {

    const [loading, setLoading] = useState(false)

    const [credentials, setCredentials] = useState({
        // TODO remove credentials
        email: 'baatezus@hotmail.fr',
        password: 'test'
    })

    useEffect( () => {
        if(auth.loggedIn)
            props.history.push('/longuet')
            cleanLoginError()
        }
        , [auth])

    const submit = async (e) => {
        e.preventDefault()
        setLoading(true)
        cleanLoginError()
        await login(credentials)
    }

    const handleChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.id]: e.target.value
        })
    }

    return(
        <div className="container login-container">
            <div className="form-container">
                <form onSubmit={submit} className="white form" className="card-panel">
                    <h4 className="grey-text text-darken-3">SOS Jeunes | Connexion</h4>
                    <div className="input-field">
                        <label htmlFor="email">Email</label>
                        <input value={credentials.email} type="email" id="email" onChange={handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="password">Mot de passe</label>
                        <input value={credentials.password} type="password" id="password" onChange={handleChange} />
                    </div>
                    <div className="input-field">
                        <button  disabled={credentials.email.length < 2 || credentials.password.length < 2} className="btn pink lighten-1 ">connexion</button>
                    </div>
                    {
                        hasError &&
                        <Error error={errorMessage} cleaningFunction={cleanLoginError} />
                    }
                    {
                        loading && !hasError  && <Loading />
                    }
                </form>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch( logout() ),
        login: credentials => dispatch( login(credentials) ),
        cleanLoginError: () => dispatch( cleanLoginError() ),
    }
}

const mapStateToProps = state => {
    return{
        auth: state.auth,
        hasError: state.errors.loginError,
        errorMessage: state.errors.loginErrorMessage
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
