import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import styled from "styled-components"
import { advancedSearch, resetSearchedProfiles } from "../../store/actions/profiles.actions"
import {
    getArrivalsLookup, getEducationTypesLookup,
    getIntermediatesLookup, getLeavingSchoolReasonsLookup, getLegalResponsiblesLookup,
    getLivingPlacesLookup, getParentLivingStatusesLookup,
    getResidencesLookup, getSchoolChoiceReasonsLookup, getSpecificStatusesLookup, getStudiesLevelLookup,
} from "../../store/actions/extra_data.actions"
import Lookup from "../forms_components/Lookup"
import ProfileChooser from "./ProfileChooser"
import LoadingScreen from "../utilities/LoadingScreen"



const GenderContainer = styled.div`
    position: relative;
    bottom: 24px;
`

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ButtonContainer = styled(Flex)`
  width: 400px;
`

const SearchProfile = (
    {
        auth,
        residences,
        getResidencesLookup,
        arrivals,
        getArrivalsLookup,
        intermediates,
        getIntermediatesLookup,
        livingPlaces,
        getLivingPlacesLookup,
        specificStatuses,
        getSpecificStatusesLookup,
        legalResponsibles,
        getLegalResponsiblesLookup,
        parentLivingStatuses,
        getParentLivingStatusesLookup,
        educationTypes,
        getEducationTypesLookup,
        schoolChoiceReasons,
        getSchoolChoiceReasonsLookup,
        leavingSchoolReasons,
        getLeavingSchoolReasonsLookup,
        studiesLevels,
        getStudiesLevelLookup,
        search,
        profiles,
        ...props
    }
) => {

    const initState = {
        saj_cbj: '',
        tribunal: '',
        spj_social: '',
        cpas: '',
        living_in: '',
        living_in_detail: '',
        intermediate: '',
        intermediate_detail: '',
        accommodation_intermediate: '',
        residence: '',
        intermediate_contact: '',
        first_name: '',
        last_name: '',
        age: '',
        gender: '',
        minor: '',
        mena: '',
        has_ran_way: '',
        has_ran_away_from_placement: '',
        has_been_excluded_from_placement: '',
        has_been_excluded_from_family: '',
        judge_name: '',
        accommodation_request: '',
        accommodation_request_nb: null,
        long_accommodation: '',
        is_at_school: '',
        school_dropout: '',
        education_type: '',
        school_dropout_since: ''
    }

    const [chooserDisplayed, displayChooser] = useState(false)
    const [loadingDisplayed, displayLoading] = useState(false)
    const [formData, setFormData] = useState(initState)
    useEffect(() => {
        console.log(formData)
        if(!auth.loggedIn) props.history.push('/')
        if(residences.length === 0) getResidencesLookup()
        if(arrivals.length === 0) getArrivalsLookup()
        if(intermediates.length === 0) getIntermediatesLookup()
        if(livingPlaces.length === 0) getLivingPlacesLookup()
        if(specificStatuses.length === 0) getSpecificStatusesLookup()
        if(legalResponsibles.length === 0) getLegalResponsiblesLookup()
        if(parentLivingStatuses.length === 0) getParentLivingStatusesLookup()
        if(educationTypes.length === 0) getEducationTypesLookup()
        if(schoolChoiceReasons.length === 0) getSchoolChoiceReasonsLookup()
        if(leavingSchoolReasons.length === 0) getLeavingSchoolReasonsLookup()
        if(studiesLevels.length === 0) getStudiesLevelLookup()
    }, [])


    const handleChange = (e, id = null) => {
        const property = id ? id : e.target.id
        setFormData({ ...formData, [property]: e.target.value })
    }

    const [lookupValue, setLookupValue] = useState({
        residence: formData.residence,
        intermediate: formData.intermediate,
        accommodation_intermediate: formData.accommodation_intermediate,
        intermediate_detail: formData.intermediate_detail,
        living_in: formData.living_in,
        living_in_detail: formData.living_in_detail,
        education_type: formData.education_type
    })

    const cb1 = useRef(null);
    const cb2 = useRef(null);
    const cb3 = useRef(null);
    const cb4 = useRef(null);
    const cb5 = useRef(null);
    const cb6 = useRef(null);
    const cb7 = useRef(null);
    const cb8 = useRef(null);
    const cb9 = useRef(null);
    const cb10 = useRef(null);
    const cb11 = useRef(null);
    const cb12 = useRef(null);
    const cb13 = useRef(null);
    const cb14 = useRef(null);


    const checkboxes = [ cb1, cb2, cb3, cb4, cb5, cb6, cb7, cb8, cb9, cb10, cb11, cb12, cb13, cb14 ]

    const updateResidence = e => {
        setLookupValue( {...lookupValue, residence: e.target.value})
        handleChange(e, 'residence')
    }

    const updateIntermediate = e => {
        const value = e.target.value
        setLookupValue( {...lookupValue, intermediate: value})
        handleChange(e, 'intermediate')
    }

    const updateEducationType = e => {
        const value = e.target.value
        setLookupValue( {...lookupValue, education_type: value})
        handleChange(e, 'education_type')
    }

    const updateAccommodationIntermediate = e => {
        const value = e.target.value
        setLookupValue( {...lookupValue, accommodation_intermediate: value})
        handleChange(e, 'accommodation_intermediate')
    }

    const updateLivingPlace = e => {
        const value = e.target.value
        setLookupValue( {...lookupValue, living_in: value})
        handleChange(e, 'living_in')
    }

    const performSearch = async () => {
        displayLoading(true)
        Object.entries(formData).forEach(entry => {
            const [key, value] = entry;
            if(!value) formData[key] = ''
        });
        await search(formData)
        displayLoading(false)
        displayChooser(true)
    }

    const handleCheckBox = e => {
        if(e.target.id === 'accommodation_request') displayAccommodationInfo(e.target.checked)
        if(e.target.id === 'is_at_school') displaySchooling(e.target.checked)
        setFormData({ ...formData, [e.target.id]: e.target.checked})
    }

    const reset = async () => {
        setFormData(initState)
        displayAccommodationInfo(false)
        displaySchooling(false)
        checkboxes.forEach(c => {if(c.current) c.current.checked = false} )
        displayChooser(false)
        displayLoading(false)
        resetSearchedProfiles()

    }

    const [accommodationRequest, displayAccommodationInfo] = useState(false)
    const [schooling, displaySchooling] = useState(false)

    return (
        <>
            {
                loadingDisplayed && <LoadingScreen />
            }
            {
                chooserDisplayed && <ProfileChooser history={props.history} profiles={profiles} close={reset} search={search} />
            }
            <div className="container">
                <Flex>
                    <h2>Recherche d'un profil</h2>
                    <ButtonContainer>
                        <div>
                            <button className="btn pink lighten-1 " onClick={performSearch}>lancer la recherche</button>
                        </div>
                        <div>
                            <button className="btn orange lighten-1 " onClick={reset}>reset</button>
                        </div>
                    </ButtonContainer>
                </Flex>


                <h4>Données de base</h4>
                <div className="row card-panel">
                    <div className="col s4 input-field">
                        <label htmlFor="last_name">Nom</label>
                        <input value={formData.last_name} type="text" id="last_name"  onChange={handleChange} />
                    </div>
                    <div className="col s4 input-field">
                        <label  htmlFor="first_name">Prénom</label>
                        <input value={formData.first_name} type="text" id="first_name" onChange={handleChange} />
                    </div>
                    <div className="col s4 input-field">
                        <label  htmlFor="age">Age</label>
                        <input value={formData.age} type="number" id="age" onChange={handleChange} />
                    </div>
                </div>
                <div className="row card-panel">
                    <div className="col s3">
                        <label>
                            <input ref={cb1} type="checkbox" defaultChecked={formData.minor} id='minor' onChange={handleCheckBox}/>
                            <span>Mineur ?</span>
                        </label>
                    </div>
                    <GenderContainer className="col s6">
                        <div className="col s4 input-field">
                            <label>
                                <input name="gender" id="gender" type="radio" onChange={handleChange} value='M' checked={formData.gender === 'M'}/>
                                <span>Homme</span>
                            </label>
                        </div>
                        <div className="col s4 input-field">
                            <label>
                                <input name="gender" id="gender" type="radio" onChange={handleChange} value='F' checked={formData.gender === 'F'}/>
                                <span>Femme</span>
                            </label>
                        </div>
                        <div className="col s4 input-field">
                            <label>
                                <input name="gender"  id="gender" type="radio" onChange={handleChange} value='X' checked={formData.gender === 'X'}/>
                                <span>Autre</span>
                            </label>
                        </div>
                    </GenderContainer>
                </div>
                <hr/>
                <h4>Schéma familial et statut</h4>
                <div className="row card-panel">
                    <div className="col s4">
                        <Lookup
                            data={residences}
                            dataName="type"
                            dataValue="id"
                            label="Domicile"
                            updateFunction={updateResidence}
                            value={lookupValue.residence}
                        />
                    </div>
                    <div className="col s4">
                        <Lookup
                            data={livingPlaces}
                            dataName="type"
                            dataValue="id"
                            label="Lieu d'habitation"
                            updateFunction={updateLivingPlace}
                            value={lookupValue.living_in}
                        />
                    </div>
                    <div className="col s4">
                        <Lookup
                            data={intermediates}
                            dataName="type"
                            dataValue="id"
                            label="Intermédiaire"
                            updateFunction={updateIntermediate}
                            value={lookupValue.intermediate}
                        />
                    </div>
                </div>


                <div className="row card-panel">
                    <div className="col s3">
                        <label>
                            <input type="checkbox" ref={cb2} defaultChecked={formData.has_ran_way !== ''} id='has_ran_away' onChange={handleCheckBox}/>
                            <span>A déjà fugué la famille ?</span>
                        </label>
                    </div>
                    <div className="col s3">
                        <label>
                            <input type="checkbox" ref={cb3} defaultChecked={formData.has_ran_away_from_placement} id='has_ran_away_from_placement' onChange={handleCheckBox}/>
                            <span>A déjà fugué une institution ?</span>
                        </label>
                    </div>
                    <div className="col s3">
                        <label>
                            <input type="checkbox" ref={cb4}  defaultChecked={formData.has_been_excluded_from_family} id='has_been_excluded_from_family' onChange={handleCheckBox}/>
                            <span>A déjà été exclu de la famille ?</span>
                        </label>
                    </div>
                    <div className="col s3">
                        <label>
                            <input type="checkbox" ref={cb5} defaultChecked={formData.has_been_excluded_from_placement} id='has_been_excluded_from_placement' onChange={handleCheckBox}/>
                            <span>A déjà été exclu d'une institution ?</span>
                        </label>
                    </div>
                </div>

                <div className="row card-panel">
                    <div className="col s3">
                        <label>
                            <input type="checkbox" ref={cb6} defaultChecked={formData.tribunal !== ''} id='tribunal' onChange={handleCheckBox}/>
                            <span>dossier ouvert au tribunal ?</span>
                        </label>
                    </div>
                    <div className="col s3">
                        <label>
                            <input type="checkbox" ref={cb7} defaultChecked={formData.cpas} id='cpas' onChange={handleCheckBox}/>
                            <span>Cpas ?</span>
                        </label>
                    </div>
                    <div className="col s3">
                        <label>
                            <input type="checkbox" ref={cb8}  defaultChecked={formData.saj_cbj} id='saj_cbj' onChange={handleCheckBox}/>
                            <span>Dossier ouvert au Saj/Cbj ?</span>
                        </label>
                    </div>
                    <div className="col s3">
                        <label>
                            <input type="checkbox" ref={cb9} defaultChecked={formData.spj_social} id='spj_social' onChange={handleCheckBox}/>
                            <span>Dossier ouvert au Spj social ?</span>
                        </label>
                    </div>
                </div>
                <div className="row card-panel">
                    <div className="col s4 input-field">
                        <label htmlFor="judge_name">Nom Del/juge</label>
                        <input value={formData.judge_name} type="text" id="judge_name"  onChange={handleChange} />
                    </div>
                    <div className="col s4"><></></div>
                    <div className="col s4 input-field">
                        <label>
                            <input type="checkbox" ref={cb10} defaultChecked={formData.mena} id='mena' onChange={handleCheckBox}/>
                            <span>MENA ?</span>
                        </label>
                    </div>
                </div>

                <hr/>
                <h4>Hébergements</h4>
                <div className="row card-panel">
                    <div className="col s6">
                        <label>
                            <input type="checkbox" ref={cb11} defaultChecked={formData.accommodation_request} id='accommodation_request' onChange={handleCheckBox}/>
                            <span>Demande d'hébergement existante ?</span>
                        </label>
                    </div>
                </div>
                {
                    accommodationRequest &&
                    <div className="row card-panel">
                        <div className="col s4 input-field">
                            <label htmlFor="accommodation_request_nb">Nombre d'hébergement</label>
                            <input value={formData.accommodation_request_nb} type="number"  min='0' id="accommodation_request_nb"  onChange={handleChange} />
                        </div>
                        <div className="col s4 input-field">
                            <label>
                                <input type="checkbox" ref={cb12} defaultChecked={formData.long_accommodation} id='long_accommodation' onChange={handleCheckBox}/>
                                <span>3 nuits & + </span>
                            </label>
                        </div>
                        <div className="col s4">
                            <Lookup
                                data={intermediates}
                                dataName="type"
                                dataValue="id"
                                label="Intermédiaire de la demande"
                                updateFunction={updateAccommodationIntermediate}
                                value={lookupValue.accommodation_intermediate}
                            />
                        </div>
                    </div>
                }

                <hr/>
                <h4>Scolarité</h4>
                <div className="row card-panel">
                    <div className="col s4">
                        <label>
                            <input type="checkbox" ref={cb13} defaultChecked={formData.is_at_school} id='is_at_school' onChange={handleCheckBox}/>
                            <span>Scolarisé ?</span>
                        </label>
                    </div>
                </div>
                {
                    schooling &&
                    <div className="row card-panel">
                        <div className="col s4">
                            <label>
                                <input type="checkbox" ref={cb14} defaultChecked={formData.school_dropout} id='school_dropout' onChange={handleCheckBox}/>
                                <span>Décrochage ?</span>
                            </label>
                        </div>
                        <div className="col s4">
                            <Lookup
                                data={educationTypes}
                                dataName="type"
                                dataValue="id"
                                label="Type d'enseignement"
                                updateFunction={updateEducationType}
                                value={lookupValue.education_type}
                            />
                        </div>
                    </div>
                }
                {
                    !schooling &&
                    <div className="row card-panel">
                        <div className="col s6 input-field">
                            <label htmlFor="school_dropout_since">N’est plus scolarisé depuis</label>
                            <input min="0" value={formData.school_dropout_since} type="number" id="school_dropout_since" onChange={handleChange} />
                        </div>
                    </div>
                }

            </div>
        </>
    )
}

const mapStateToProps = state => {
    return{
        auth: state.auth,
        countries: state.extraData.countries,
        residences: state.extraData.residences,
        arrivals: state.extraData.arrivals,
        intermediates: state.extraData.intermediates,
        livingPlaces: state.extraData.livingPlaces,
        specificStatuses: state.extraData.specificStatuses,
        legalResponsibles: state.extraData.legalResponsibles,
        parentLivingStatuses: state.extraData.parentLivingStatuses,
        schoolChoiceReasons: state.extraData.schoolChoiceReasons,
        studiesLevels: state.extraData.studiesLevels,
        leavingSchoolReasons: state.extraData.leavingSchoolReasons,
        educationTypes: state.extraData.educationTypes,
        profiles: state.profiles.searchedProfiles
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getResidencesLookup: () => dispatch( getResidencesLookup() ),
        getArrivalsLookup: () => dispatch( getArrivalsLookup() ),
        getIntermediatesLookup: () => dispatch( getIntermediatesLookup() ),
        getLivingPlacesLookup: () => dispatch( getLivingPlacesLookup() ),
        getSpecificStatusesLookup: () => dispatch( getSpecificStatusesLookup() ),
        getLegalResponsiblesLookup: () => dispatch( getLegalResponsiblesLookup() ),
        getParentLivingStatusesLookup: () => dispatch( getParentLivingStatusesLookup() ),
        getEducationTypesLookup: () => dispatch( getEducationTypesLookup() ),
        getSchoolChoiceReasonsLookup: () => dispatch( getSchoolChoiceReasonsLookup() ),
        getLeavingSchoolReasonsLookup: () => dispatch( getLeavingSchoolReasonsLookup() ),
        getStudiesLevelLookup: () => dispatch( getStudiesLevelLookup() ),
        search: formData => dispatch( advancedSearch(formData) ),
        resetSearchedProfiles: dispatch( resetSearchedProfiles() )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchProfile)




