import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Icon } from 'react-materialize'
import { getUsers } from "../../store/actions/extra_data.actions";
import Lookup from "../forms_components/Lookup";
import { saveNewConsigne, updateConsigne} from "../../store/actions/consignes.action";
import Loading from "../alerts/Loading";

const CheckBoxContainer = styled.div`
    position: relative;
    top: 32px;
    left: 64px;
    color: black !important;
`

const CheckBoxContainer1 = styled.div`
    position: relative;
    top: 10px;
    left: 64px;
    color: black !important;
`

const Container = styled.div`
    position: relative;
`

const Warning = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 16px;
    right: 24px;
    width: 128px
`

const YoungAware = styled.span`
    color: ${({aware}) => aware ? 'green' : 'red'};
`

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const FlexSmall = styled(Flex)`
    width: 200px;
`
const Button = styled.button`
    margin-right: 16px;
`


const ProfileConsigne = (
    {
        profile,
        consigne,
        needConfirm,
        users,
        getUsers,
        saveNewConsigne,
        updateConsigne
    }
) => {
    useEffect(() => {
        if(!users.length) getUsers()
    }, [])

    const [formIdDisplayed, displayForm] = useState(false)
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        pseudo: consigne ? consigne.pseudo : '',
        young_id: profile.id,
        date_time: consigne ? consigne.date_time : new Date().toISOString().split('T')[0],
        specific:  consigne ? consigne.specific : '',
        young_is_aware: consigne ? consigne.young_is_aware : '0',
        note: consigne ? consigne.note : '',
        access_forbidden: consigne ? consigne.access_forbidden : '0 ',
        user_id: consigne ? consigne.user_id : ''
    })
    const [lookupValues, setLookupValues] = useState({
        user_id: formData.user_id
    })

    const updateUserId = e =>  {
        const value = e.target.value
        setLookupValues({...lookupValues, user_id:  value})
        setFormData({...formData, user_id: value})
    }

    const save = async () => {
        displayForm(false)
        setLoading(true)
        if(consigne) {
            formData.id = consigne.id
            await updateConsigne(formData)
        }
        else await saveNewConsigne(formData)

        setLoading(false)
    }

    const handleChange = e => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const youngIsAware = e => {
        setFormData({...formData, young_is_aware: e.target.checked})
    }

    const accessForbidden = e => {
        setFormData({...formData, access_forbidden: e.target.checked})
    }

    const displayConsigneForm = () => {
        displayForm(true)
        if(consigne === null) {
            setFormData({
                pseudo: '',
                young_id: profile.id,
                date_time: new Date().toISOString().split('T')[0],
                specific:  '',
                young_is_aware: '0',
                note: '',
                access_forbidden:  '0',
                user_id: ''
            })
        }
    }

    return (
        <>
            {
                (loading && <Loading/>)
                ||
                (
                    !formIdDisplayed && consigne &&
                    <Container className="card">
                        {
                            consigne.access_forbidden != 0 &&
                            <Warning>
                                <p><Icon className='red-text'>remove_circle</Icon></p>
                                <p>Entrée interdite</p>
                            </Warning>
                        }
                        <div className="card-content">
                            <span className="card-title">Permanent: {consigne.pseudo}</span>
                            <ul className='collection'>
                                <li className='collection-item'>
                                    Consigne spécifique: {consigne.specific}
                                </li>
                                <li className='collection-item'>
                                    Le jeune est-il au courant: <YoungAware aware={consigne.young_is_aware}>{consigne.young_is_aware ? 'oui' : 'non'}</YoungAware>
                                </li>
                            </ul>
                            <p>Note: </p>
                            <p>{consigne.note}</p>
                        </div>
                        <div className="card-action">
                            <Button className='btn' onClick={() => needConfirm(true)}>Lever la consigne</Button>
                            <button className='btn pink lighten-1' onClick={displayConsigneForm}>Editer la consigne</button>
                        </div>
                    </Container>
                )
                ||
                (
                    formIdDisplayed &&
                    <div>
                        <div className="row card-panel">
                            <div className="col s4">
                                <Lookup
                                    data={users}
                                    dataName="pseudo"
                                    dataValue="id"
                                    label="Permanent"
                                    updateFunction={updateUserId}
                                    value={lookupValues.user_id}
                                />
                            </div>
                            <div className="col s4 input-field">
                                <input type="text" value={formData.specific} id="specific" onChange={handleChange}/>
                                <label className={`${formData.specific ? "active" : ""} grey-text text-darken-4`} htmlFor="specific">Consigne spécique</label>
                            </div>
                            <div className="col s4">
                                <CheckBoxContainer1>
                                    <label>
                                        <input type="checkbox" defaultChecked={formData.young_is_aware === true || formData.young_is_aware === 1} onChange={youngIsAware} />
                                        <span>Le jeune est  prévenu ?</span>
                                    </label>
                                </CheckBoxContainer1>
                            </div>
                            <div className="col s4">
                                <CheckBoxContainer>
                                    <label>
                                        <input type="checkbox" defaultChecked={formData.access_forbidden === true || formData.access_forbidden === 1} onChange={accessForbidden} />
                                        <span>Entrée interdite à SOS jeunes</span>
                                    </label>
                                </CheckBoxContainer>
                            </div>
                        </div>
                        <div className="card-panel">
                            <label htmlFor="note">Note: </label>
                            <textarea  id="note" className='materialize-textarea' value={formData.note} onChange={handleChange}></textarea>
                        </div>
                        <hr />
                        <FlexSmall>
                            <button className='btn green' disabled={
                                !formData.note
                                || !formData.user_id
                                || !formData.specific
                            } onClick={save}>Sauver</button>
                            <button className='btn red' onClick={displayConsigneForm}>Annuler</button>
                        </FlexSmall>
                    </div>
                )
                ||
                <Flex>
                    <h5>Aucune consigne</h5>
                    <div>
                        <button onClick={displayConsigneForm} className='btn pink'>Ajouter une consigne</button>
                    </div>
                </Flex>
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        users: state.extraData.users
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(getUsers()),
        saveNewConsigne: formData => dispatch( saveNewConsigne(formData) ),
        updateConsigne: formData => dispatch( updateConsigne(formData) )
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(ProfileConsigne)