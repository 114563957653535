import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getUsers } from "../../store/actions/extra_data.actions"
import Loading from "../alerts/Loading";
import styled from 'styled-components'
import User from "./User";
import { saveNewUser } from "../../store/actions/auth.actions";
import { cleanNewUserError } from "../../store/actions/errors.actions";

const CheckBoxContainer = styled.div`
  margin-top: 40px;
`

const Button = styled.button`
  margin-right: 16px;
`

const Hr = styled.hr`
  margin: 40px 0 !important;
`

const UsersAdministration = (
    {
        loggedIn,
        admin,
        users,
        getUsers,
        saveNewUser,
        error,
        cleanNewUserError,
        ...props
    }
 ) => {
    const [formData, setFormData] = useState({
        pseudo: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        admin: false
    })
    const [loading, setLoading] =  useState(false)
    const [formIsDisplayed, displayForm] = useState(false)
    const updateAdmin = e => setFormData({...formData, admin: e.target.checked})
    const save = async () => {
        cleanNewUserError()
        setLoading(true)
        await saveNewUser(formData)
        displayForm(false)
        await getUsers()
        setLoading(false)
    }


    useEffect( () => {
        if(!loggedIn || !admin) props.history.push('/')
        getUsers()
    }, [users])

    const handleChange = e => setFormData({...formData, [e.target.id]: e.target.value})

    const cancel = () => {
        displayForm(false)
        cleanNewUserError()
        setFormData({
            pseudo: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            admin: false
        })
    }

    return (
        <>
            <div className="container">
                <h2>Utilisateurs</h2>
                {
                    (
                        !loading && users && users.length &&
                        <>
                            {
                                (
                                    !formIsDisplayed && !error &&
                                    <button className="pink lighten-1 btn" onClick={() => displayForm(true)}>
                                        Ajouter un utilisateur
                                    </button>
                                )
                                ||
                                (
                                    <>
                                        <div className="card-panel">
                                            <div>
                                                <label className={`${formData.pseudo ? "active" : ""} grey-text text-darken-4`} htmlFor="pseudo">Pseudo</label>
                                                <input value={formData.pseudo} type="text" id="pseudo" onChange={handleChange} />
                                            </div>
                                            <div>
                                                <label
                                                    className={`${formData.email ? "active" : ""} grey-text text-darken-4`}
                                                    htmlFor="email">Email</label>
                                                <input value={formData.email} type="email" className="validate"
                                                       id="email" onChange={handleChange}/>
                                                <span className="helper-text" data-error="L'email n'est pas au bon format"></span>
                                            </div>
                                            <div>
                                                <label className={`${formData.password ? "active" : ""} grey-text text-darken-4`} htmlFor="email">Mot de passe</label>
                                                <input value={formData.password} type="password" id="password" onChange={handleChange} />
                                            </div>
                                            <div>
                                                <label className={`${formData.passwordConfirmation ? "active" : ""} grey-text text-darken-4`} htmlFor="passwordConfirmation">Confirmer le mot de passe</label>
                                                <input value={formData.passwordConfirmation} type="password"  id="passwordConfirmation" onChange={handleChange} />
                                            </div>
                                            {
                                                formData.password.length > 5
                                                && formData.passwordConfirmation.length > 5
                                                && formData.password !== formData.passwordConfirmation
                                                &&
                                                <span className='red-text'>Les champs mot de passe et confirmation ne correspondent pas</span>
                                            }
                                            <CheckBoxContainer>
                                                <label>
                                                    <input type="checkbox" onChange={updateAdmin} />
                                                    <span>Administrateur</span>
                                                </label>
                                            </CheckBoxContainer>

                                        </div>
                                        {
                                            error && <div className="card-panel orange darken-2 white-text">{error}</div>
                                        }
                                        <Button  disabled={
                                            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
                                            || formData.pseudo.length < 3
                                            || formData.password.length < 6
                                            || formData.passwordConfirmation.length < 6
                                            || formData.password !== formData.passwordConfirmation
                                        } className="green btn" onClick={save}>
                                            Sauvegarder
                                        </Button>
                                        <button className="orange btn" id="admin" onClick={cancel}>
                                            Annuler
                                        </button>
                                    </>
                                )
                            }
                            <Hr/>
                            <h5>Utilisateurs actifs</h5>
                            {
                                users.filter(user => user.confirmed).map(user => {
                                    return (
                                        <User key={user.id} user={user}/>
                                    )
                                })
                            }
                            <Hr/>
                            <h5>Utilisateurs désactivés</h5>
                            {
                                users.filter(user => !user.confirmed).map(user => {
                                    return (
                                        <User key={user.id}  user={user}/>
                                    )
                                })
                            }
                        </>
                    )
                    ||
                    <Loading/>
                }
            </div>
        </>
    )

}


const mapStateToProps = state => {
    return {
        loggedIn: state.auth.loggedIn,
        admin: state.auth.user.admin,
        users: state.extraData.users,
        error: state.errors.newUserError
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch( getUsers() ),
        saveNewUser: formData => dispatch(saveNewUser(formData) ),
        cleanNewUserError: () => dispatch(cleanNewUserError() )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersAdministration)
