import {
    GET_ALL_ACCOMMODATIONS,
    GET_ACCOMMODATIONS_PER_DAY,
    GET_ACCOMMODATIONS_PER_PROFILE,
    SAVE_NEW_ACCOMMODATION, SAVE_NEW_EVENT,
    GET_ACCOMMODATION_BY_VISIT_ID, UPDATE_ACCOMMODATION,
    GET_RUNNING_ACCOMMODATIONS,
    GET_PENDING_ACCOMMODATIONS,
    ACCEPT_ACCOMMODATION,
    DENY_ACCOMMODATION,
    CLOSE_ACCOMMODATION,
    GET_ALL_ACCOMMODATIONS_ALERTS_NUMBER
} from '../../constants/urls.constants'
import axios from "axios";
import moment from "moment";

export const cleanCurrentAccommodation = () => {
    return async dispatch => dispatch({type: 'CLEAN_CURRENT_ACCOMMODATION'})
}

export const getAccommodationRequestByVisitId = id => {
    return async dispatch => {
        const response = await axios.get(`${GET_ACCOMMODATION_BY_VISIT_ID}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_ACCOMMODATION_BY_VISIT_ID_ERROR', error }))
        const data = response.data[0]
        dispatch({type: 'GET_ACCOMMODATION_BY_VISIT_ID', data})
    }
}

export const getRunningAccommodations = () => {
    return async dispatch => {
        const response = await axios.get(GET_RUNNING_ACCOMMODATIONS,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_RUNNING_ACCOMMODATIONS_ERROR', error }))
        const data = response.data
        dispatch({type: 'GET_RUNNING_ACCOMMODATIONS', data})
    }
}

export const getAccommodationsAlertsNumber = () => {
    return async dispatch => {
        const response = await axios.get(GET_ALL_ACCOMMODATIONS_ALERTS_NUMBER,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_ALL_ACCOMMODATIONS_ALERTS_NUMBER_ERROR', error }))
        const data = response.data[0].alerts
        dispatch({type: 'GET_ALL_ACCOMMODATIONS_ALERTS_NUMBER', data})
    }
}

export const getPendingAccommodations = () => {
    return async dispatch => {
        const response = await axios.get(GET_PENDING_ACCOMMODATIONS,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_PENDING_ACCOMMODATIONS_ERROR', error }))
        const data = response.data
        dispatch({type: 'GET_PENDING_ACCOMMODATIONS', data})
    }
}

export const acceptAccommodation = id => {
    return async function mapDispatchToProps(dispatch) {
        const response = await axios.get(`${ACCEPT_ACCOMMODATION}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'ACCEPT_ACCOMMODATION_ERROR', error }))
        const data = response.data
        dispatch({type: 'ACCEPT_ACCOMMODATION', data})
    }
}

export const denyAccommodation = (id, reasonId) => {
    const formData = {
        id: id,
        accommodation_denied_reason: reasonId,
        request_status: 3
    }

    return async dispatch => {
        const response = await axios.put(
            `${UPDATE_ACCOMMODATION}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => {
                dispatch({type: 'UPDATE_ACCOMMODATION_ERROR', error})
                console.log(error)
            })
        dispatch({type: 'DENY_ACCOMMODATION', id})
    }
}

export const closeAccommodation = (id, destination) => {
    return async function mapDispatchToProps(dispatch) {
        const response = await axios.post(`${CLOSE_ACCOMMODATION}`,
            {id, destination},
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'CLOSE_ACCOMMODATION_ERROR', error }))
        const data = response.data
        dispatch({type: 'CLOSE_ACCOMMODATION', data})
    }
}


export const getAccommodations = () => {
    return async dispatch => {
        const response = await axios.get(GET_ALL_ACCOMMODATIONS,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_ALL_ACCOMMODATIONS_ERROR', error }))
        const data = response.data
        dispatch({type: 'GET_ALL_ACCOMMODATIONS', data})
    }
}

export const getAccommodationsPerDay = day => {
    return async function mapDispatchToProps(dispatch) {
        const response = await axios.get(`${GET_ACCOMMODATIONS_PER_DAY}/${day}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_ACCOMMODATIONS_PER_DAY_ERROR', error }))
        const data = response.data
        dispatch({type: 'GET_ACCOMMODATIONS_PER_DAY', data})
    }
}

export const getAccommodationsPerProfile = id => {
    return async function mapDispatchToProps(dispatch) {
        const response = await axios.get(`${GET_ACCOMMODATIONS_PER_PROFILE}/${id}`,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => dispatch({ type: 'GET_ACCOMMODATIONS_PER_PROFILE_ERROR', error }))
        const data = response.data
        dispatch({type: 'GET_ACCOMMODATIONS_PER_PROFILE', data})
    }
}

export const updateAccommodation = formData => {
    return async dispatch => {
        const response = await axios.put(
            `${UPDATE_ACCOMMODATION}`,
            formData,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error => {
                dispatch({type: 'UPDATE_ACCOMMODATION_ERROR', error})
                console.log(error)
            })
        dispatch({type: 'UPDATE_ACCOMMODATION', formData})
    }
}

export const saveNewAccommodation = (visit, formData) => {
    return async dispatch => {
        const returnData = {...visit}
        delete visit.id
        delete visit.consigne
        delete visit.access_forbidden
        const response = await axios.post(
            `${SAVE_NEW_EVENT}`,
            visit,
            {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
            .catch(error =>{
                console.log(error)
                dispatch({ type: 'SAVE_NEW_EVENT_ERROR', error })
            })

        if(response.data) {
            console.log("response", response.data)
            returnData.newId = response.data.id
            dispatch({ type: 'SAVE_NEW_EVENT',  returnData} )
            console.log(returnData)
            formData.visit_id = returnData.newId
            formData.start_date = moment().format('YYYY[-]MM[-]DD')
            const response2 = await axios.post(
                `${SAVE_NEW_ACCOMMODATION}`,
                formData,
                {headers: {'Authorization': "Bearer " + localStorage.getItem('access_token')}})
                .catch(error =>{
                    console.log(error)
                    dispatch({ type: 'SAVE_NEW_EVENT_ERROR', error })
                })

            console.log(response2.data)
            const data = response2.data
            dispatch({type: 'SAVE_NEW_ACCOMMODATION', data})
        }
    }
}
