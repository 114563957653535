import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Loading from "../alerts/Loading";
import styled from 'styled-components'
import { updateUser } from "../../store/actions/auth.actions";

const UserContainer = styled.div`
  display: flex;
`

const UserInfo = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`

const CheckBoxContainer = styled.div`
    /*
    position: relative;
    top: 32px;
    left: 64px;
    color: black !important;
    */
`

const Icon = styled.i`
    margin: 0 4px;
    cursor: pointer;
    &:hover {
        color: #ec407a;
    }
`


const User = ({user, updateUser}) => {
    const update = async e => {
        setEditMode(false)
        setLoading(true)
        await updateUser({
            confirmed: active,
            admin: admin,
            id: user.id
        })
        setLoading(false)
    }

    const updateAdmin = e => {
        setAdmin(e.target.checked)
    }

    const updateActive = e => {
        setActive(e.target.checked)
    }

    const [admin, setAdmin] = useState(user.admin)
    const [active, setActive] = useState(user.confirmed)
    const [editMode, setEditMode] =  useState(false)
    const [loading, setLoading] =  useState(false)

    return (
        <>
            {
                (loading && <Loading/>)
                ||
                <UserContainer className='card-panel'>
                    <UserInfo>{user.pseudo}</UserInfo>
                    <UserInfo>{user.email}</UserInfo>
                    <UserInfo>
                        <CheckBoxContainer>
                            <label>
                                <input type="checkbox" disabled={!editMode} defaultChecked={user.admin} onChange={updateAdmin} />
                                <span>Administrateur</span>
                            </label>
                        </CheckBoxContainer>
                    </UserInfo>
                    <UserInfo>
                        <CheckBoxContainer>
                            <label>
                                <input type="checkbox" disabled={!editMode} defaultChecked={user.confirmed} onChange={updateActive} />
                                <span>Actif</span>
                            </label>
                        </CheckBoxContainer>
                    </UserInfo>
                    <UserInfo>
                        {
                            (
                                editMode &&
                                <Icon className="orange-text small material-icons" onClick={update}>lock_open</Icon>
                            )
                            ||
                            <Icon className="green-text small material-icons" onClick={() => {setEditMode(true)}}>lock</Icon>
                        }

                    </UserInfo>
                </UserContainer>
            }
        </>

    )
}

const mapDispatchToProps = dispatch => {
    return {
        updateUser: formData => dispatch( updateUser(formData) )
    }
}

export default connect(null, mapDispatchToProps)(User)
