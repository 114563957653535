import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateNewProfile } from "../../../../store/actions/profiles.actions"

const SeventhStep = (
    {
        newProfile,
        updateNewProfile,
    }
) => {
    const [formData, setFormData] = useState({
        has_been_placed: newProfile.has_been_placed ? newProfile.has_been_placed : '',
        has_ran_away_from_placement: newProfile.has_ran_away_from_placement ? newProfile.has_ran_away_from_placement : '',
        has_ran_away_from_placement_two_times: newProfile.has_ran_away_from_placement_two_times ? newProfile.has_ran_away_from_placement_two_times : '',
        has_been_excluded_from_placement: newProfile.has_been_excluded_from_placement ? newProfile.has_been_excluded_from_placement : '',
        has_been_excluded_from_placement_two_times: newProfile.has_been_excluded_from_placement_two_times ? newProfile.has_been_excluded_from_placement_two_times : '',
    })

    const [lookupValue, setLookupValue] = useState({

    })

    const [hasBeenPlacedDetailsIsDisplayed, displayHasBeenPlacedDetails] = useState(formData.has_been_placed === '1')
    const [ranAwayTwoTimesIsDisplayed, displayRanAwayTwoTimes] = useState(formData.has_ran_away_from_placement === '1')
    const [excludedTwoTimesIsDisplayed, displayExcludedTwoTimes] = useState(formData.has_been_excluded_from_placement === '1')

    useEffect( () => {
        return () => {
            updateNewProfile(formData)
        }
    }, [formData])

    const handleChange = (e, id = null) => {
        const property = id ? id : e.target.id
        setFormData({ ...formData, [property]: e.target.value })
    }

    const handleChangeHasBeenPlaced = e => {
        displayHasBeenPlacedDetails(e.target.value === '1')
        handleChange(e)
    }

    const handleChangeHasRanAway = e => {
        displayRanAwayTwoTimes(e.target.value === '1')
        handleChange(e)
    }

    const handleChangeHasBeenExcluded = e => {
        displayExcludedTwoTimes(e.target.value === '1')
        handleChange(e)
    }

    return (
        <>
            <h4>Services</h4>
            <div className="row card-panel">
                <div className="col s6 input-field">
                    <label className='active grey-text text-darken-4'>Le jeune a-t-il déjà connu un placement ?</label>
                    <div className="col s4 input-field">
                        <label>
                            <input name="has_been_placed" id="has_been_placed" type="radio" onChange={handleChangeHasBeenPlaced} value='1' checked={ formData.has_been_placed === '1' }/>
                            <span>Oui</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="has_been_placed" id="has_been_placed" type="radio" onChange={handleChangeHasBeenPlaced} value='0' checked={ formData.has_been_placed === '0' }/>
                            <span>Non</span>
                        </label>
                    </div>
                    <div className="col s4 input-field">
                        <label>
                            <input name="has_been_placed" id="has_been_placed" type="radio" onChange={handleChangeHasBeenPlaced} value='' checked={ formData.has_been_placed === '' }/>
                            <span>Non renseigné</span>
                        </label>
                    </div>
                </div>
            </div>
            {
                hasBeenPlacedDetailsIsDisplayed &&
                <div>
                    <div className="row card-panel">
                        <div className="col s6 input-field">
                            <label className='active grey-text text-darken-4'>Le jeune a-t-il fugué d’une institution de placement ?</label>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="has_ran_away_from_placement" id="has_ran_away_from_placement" type="radio" onChange={handleChangeHasRanAway} value='1' checked={ formData.has_ran_away_from_placement === '1' }/>
                                    <span>Oui</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="has_ran_away_from_placement" id="has_ran_away_from_placement" type="radio" onChange={handleChangeHasRanAway} value='0' checked={ formData.has_ran_away_from_placement === '0' }/>
                                    <span>Non</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="has_ran_away_from_placement" id="has_ran_away_from_placement" type="radio" onChange={handleChangeHasRanAway} value='' checked={ formData.has_ran_away_from_placement === '' }/>
                                    <span>Non renseigné</span>
                                </label>
                            </div>
                        </div>
                        {
                            ranAwayTwoTimesIsDisplayed &&
                            <div className="col s6 input-field">
                                <label className='active grey-text text-darken-4'>A-t-il fugué plus de deux fois?</label>
                                <div className="col s4 input-field">
                                    <label>
                                        <input name="has_ran_away_from_placement_two_times" id="has_ran_away_from_placement_two_times" type="radio" onChange={handleChange} value='1' checked={ formData.has_ran_away_from_placement_two_times === '1' }/>
                                        <span>Oui</span>
                                    </label>
                                </div>
                                <div className="col s4 input-field">
                                    <label>
                                        <input name="has_ran_away_from_placement_two_times" id="has_ran_away_from_placement_two_times" type="radio" onChange={handleChange} value='0' checked={ formData.has_ran_away_from_placement_two_times === '0' }/>
                                        <span>Non</span>
                                    </label>
                                </div>
                                <div className="col s4 input-field">
                                    <label>
                                        <input name="has_ran_away_from_placement_two_times" id="has_ran_away_from_placement_two_times" type="radio" onChange={handleChange} value='' checked={ formData.has_ran_away_from_placement_two_times === '' }/>
                                        <span>Non renseigné</span>
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row card-panel">
                        <div className="col s6 input-field">
                            <label className='active grey-text text-darken-4'>Le jeune a-t-il été exclu d’une institution de placement ?</label>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="has_been_excluded_from_placement" id="has_been_excluded_from_placement" type="radio" onChange={handleChangeHasBeenExcluded} value='1' checked={ formData.has_been_excluded_from_placement === '1' }/>
                                    <span>Oui</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="has_been_excluded_from_placement" id="has_been_excluded_from_placement" type="radio" onChange={handleChangeHasBeenExcluded} value='0' checked={ formData.has_been_excluded_from_placement === '0' }/>
                                    <span>Non</span>
                                </label>
                            </div>
                            <div className="col s4 input-field">
                                <label>
                                    <input name="has_been_excluded_from_placement" id="has_been_excluded_from_placement" type="radio" onChange={handleChangeHasBeenExcluded} value='' checked={ formData.has_been_excluded_from_placement === '' }/>
                                    <span>Non renseigné</span>
                                </label>
                            </div>
                        </div>
                        {
                            excludedTwoTimesIsDisplayed &&
                            <div className="col s6 input-field">
                                <label className='active grey-text text-darken-4'>A-t-il été exclu plus de deux fois?</label>
                                <div className="col s4 input-field">
                                    <label>
                                        <input name="has_been_excluded_from_placement_two_times" id="has_been_excluded_from_placement_two_times" type="radio" onChange={handleChange} value='1' checked={ formData.has_been_excluded_from_placement_two_times === '1' }/>
                                        <span>Oui</span>
                                    </label>
                                </div>
                                <div className="col s4 input-field">
                                    <label>
                                        <input name="has_been_excluded_from_placement_two_times" id="has_been_excluded_from_placement_two_times" type="radio" onChange={handleChange} value='0' checked={ formData.has_been_excluded_from_placement_two_times === '0' }/>
                                        <span>Non</span>
                                    </label>
                                </div>
                                <div className="col s4 input-field">
                                    <label>
                                        <input name="has_been_excluded_from_placement_two_times" id="has_been_excluded_from_placement_two_times" type="radio" onChange={handleChange} value='' checked={ formData.has_been_excluded_from_placement_two_times === '' }/>
                                        <span>Non renseigné</span>
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = state => {
    return{
        newProfile: state.profiles.newProfile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateNewProfile: credentials => dispatch( updateNewProfile(credentials) ),
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(SeventhStep)