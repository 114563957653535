export const cleanLoginError = () => {
    return async dispatch => {
        dispatch({type: 'LOGIN_ERROR_CLEANING'})
    }
}


export const cleanNewUserError = () => {
    return async dispatch => {
        dispatch({type: 'NEW_USER_ERROR_CLEANING'})
    }
}

